import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';
import { connect } from 'react-redux';
import styled from 'styled-components';

import TimeslotCard from 'event-shared/components/TimeslotCard';
import * as eventConstants from 'event-shared/constants';
import { componentDecorator, getUserEnrollmentStatus } from 'event-shared/services';
import colors from 'services/colors';
import DurationAndTags from 'shared-cards/components/DurationAndTags/DurationAndTags';
import Icon, { OldIcon } from 'shared/components/Icon';
import { Tooltip } from 'shared/components/Tooltip';
import { useCurrentUser, useEllipsisCheck, useTooltipUID } from 'shared/hooks';
import { get, isEmpty, capitalize, map, reduce } from 'vendor/lodash';

import CardAttendanceActionElement from './CardAttendanceActionElement';
import CompactAttendanceActionElement from './CompactAttendanceActionElement';
import DetailsAttendanceActionElement from './DetailsAttendanceActionElement';
import DetailsTimeslotsList from './DetailsTimeslotsList';

const EventAttendanceBox = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  ${({ viewMode }) =>
    viewMode === 'cozy' &&
    `
    height: 194px;
    padding-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
  `};
  ${({ viewMode }) =>
    viewMode === 'compact' &&
    `
    height: 100%;
    justify-content: center;
  `};
`;

const EventAttendanceDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 4px;
  ${({ viewMode }) =>
    viewMode === 'cozy' &&
    `
    height: 150px;
    max-height: 150px;
  `};
  ${({ viewMode }) =>
    viewMode === 'compact' &&
    `
    height: 235px;
    display: none;
  `};
  ${({ viewMode }) =>
    viewMode === 'details' &&
    `
    height: 100%;
  `};
`;

const RoomsInfo = styled.div`
  color: ${colors.neutral600};
  ${({ mode }) => mode === 'details' && 'margin-top: 15px;'};
  ${({ mode }) =>
    mode === 'cozy' &&
    `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`};
  display: flex;
  align-items: center;
  gap: 8px;
`;

function RoomsInfoIcon(props) {
  const { enrollmentStatus } = props;

  const {
    userIsEnrolledLocal,
    userIsOnLocalWaitlist,
    userIsEnrolledOnline,
    userIsOnOnlineWaitlist,
  } = getUserEnrollmentStatus(enrollmentStatus);

  const maybeGoingLocal = userIsEnrolledLocal || userIsOnLocalWaitlist;
  const maybeGoingOnline = userIsEnrolledOnline || userIsOnOnlineWaitlist;
  const maybeGoing = maybeGoingLocal || maybeGoingOnline;

  if (!maybeGoing) {
    return <Icon name="globe" height={16} width={16} />;
  }

  if (maybeGoingLocal) {
    return <Icon name="location" height={16} width={16} />;
  }

  return <Icon name="online" height={16} width={16} />;
}

RoomsInfoIcon.propTypes = {
  enrollmentStatus: PropTypes.string,
};

const ExtraInfo = styled.div`
  color: ${colors.neutral600};
  ${({ mode }) => mode === 'details' && 'margin-top: 15px;'};
`;

const getTimeslotsDuration = (timeslots) => {
  const durationAsSeconds = reduce(
    map(timeslots, ({ duration }) => moment.duration(duration).asSeconds()),
    (total, seconds) => total + seconds
  );

  return moment.utc(durationAsSeconds * 1000).format('HH:mm:ss');
};

export const AttendanceStatusWidget = (props) => {
  const {
    event,
    viewMode,
    onRateClick,
    className,
    labelExternalEnrollmentCompleted,
    shouldRefreshEvent,
  } = props;
  const currentUser = useCurrentUser();
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  const roomTooltip = useTooltipUID();

  if (isEmpty(event)) return null;

  const enrollmentStatus = get(
    event,
    'enrollment.status',
    eventConstants.ENROLLMENT_STATUS_UNANSWERED
  );
  const { userHasAttended, userIsEnrolled } = getUserEnrollmentStatus(enrollmentStatus);

  const [timeslot] = event.timeslots;
  const { rooms_info: roomsInfo, extra_info: extraInfo } = timeslot;
  const duration = getTimeslotsDuration(event.timeslots);

  const unenrollLabel = event.external_link ? capitalize(labelExternalEnrollmentCompleted) : null;

  return (
    <EventAttendanceBox viewMode={viewMode} className={className}>
      <EventAttendanceDetails viewMode={viewMode}>
        {(!isEmpty(roomsInfo) || event.location_name) &&
          (viewMode !== 'details' || event.timeslots.length === 1) && (
            <React.Fragment>
              <RoomsInfo {...roomTooltip.targetProps} ref={nodeRef} mode={viewMode}>
                <RoomsInfoIcon enrollmentStatus={enrollmentStatus} />{' '}
                {roomsInfo && `${roomsInfo} @ `}
                {event.location_name}
              </RoomsInfo>
              <Tooltip id={roomTooltip.uid} maxWidth={282} hide={!hasEllipsis}>
                {roomsInfo && `${roomsInfo} @ `}
                {event.location_name}
              </Tooltip>
            </React.Fragment>
          )}
        {viewMode === 'cozy' && (
          <TimeslotCard
            timeslot={timeslot}
            locationTimezone={event.timezone}
            userTimezone={currentUser.timezone}
            eventIsOnline={event.is_online}
            wrapDatetime={false}
            marginLeftOutsideLearning={false}
          />
        )}
        {viewMode === 'details' && (
          <DetailsTimeslotsList
            timeslots={event.timeslots}
            locationName={event.location_name}
            locationTimezone={event.timezone}
            eventIsOnline={event.is_online}
            viewMode={viewMode}
            userIsEnrolled={userIsEnrolled || userHasAttended}
          />
        )}
        {extraInfo && viewMode === 'details' && event.timeslots.length === 1 && (
          <ExtraInfo>
            <Linkify componentDecorator={componentDecorator}>
              <OldIcon name="information-outline" size="small" color={colors.neutral600} />{' '}
              {extraInfo}
            </Linkify>
          </ExtraInfo>
        )}
      </EventAttendanceDetails>
      {/* the bottom component - enroll/interested/rating button/checked in/etc */}
      <div>
        {viewMode === 'cozy' && (
          <>
            <DurationAndTags duration={duration} />
            <CardAttendanceActionElement
              event={event}
              enrollmentStatus={enrollmentStatus}
              onRateClick={onRateClick}
              shouldRefreshEvent={shouldRefreshEvent}
            />
          </>
        )}
        {viewMode === 'details' && (
          <DetailsAttendanceActionElement
            event={event}
            enrollmentStatus={enrollmentStatus}
            unenrollLabel={unenrollLabel}
            onRateClick={onRateClick}
          />
        )}
        {viewMode === 'compact' && (
          <CompactAttendanceActionElement
            event={event}
            enrollmentStatus={enrollmentStatus}
            onRateClick={onRateClick}
          />
        )}
      </div>
    </EventAttendanceBox>
  );
};

AttendanceStatusWidget.defaultProps = {
  viewMode: 'details',
};

AttendanceStatusWidget.propTypes = {
  event: PropTypes.object,
  viewMode: PropTypes.string,
  className: PropTypes.string,
  labelTimeslots: PropTypes.string,
  labelExternalEnrollmentCompleted: PropTypes.string,
  onRateClick: PropTypes.func,
  shouldRefreshEvent: PropTypes.bool,
};

AttendanceStatusWidget.EventAttendanceBox = EventAttendanceBox;
AttendanceStatusWidget.EventAttendanceDetails = EventAttendanceDetails;

const mapStateToProps = (state) => ({
  labelExternalEnrollmentCompleted:
    state.user.currentUser.labels.label_events_external_enrollment_completed,
  labelTimeslots: state.user.currentUser.labels.label_events_timeslots,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceStatusWidget);
