import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import Button from 'shared/components/Button';
import Modal, { ModalBody, ModalFooter } from 'shared/components/Modal';
import Text from 'shared/components/Text';
import { isEmpty, isObject, map, toString } from 'vendor/lodash';

const TableHeader = styled.thead`
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid ${colors.neutral200};
`;

const TableBody = styled.tbody`
  display: block;
  overflow: auto;
  width: 100%;
  table-layout: fixed;
  max-height: 310px;
`;

const TableHeaderData = styled.th`
  padding: 12px 16px 12px 16px;
`;

const TableData = styled.td`
  padding: 4px 16px 4px 16px;
`;

const TableRow = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;

  th:first-child,
  td:first-child {
    width: 50%;
  }

  th:last-child {
    padding-left: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.neutral200};
  border-radius: 4px;
  background-color: ${colors.neutral50};
  margin-top: 20px;
  overflow-wrap: break-word;
`;

const Head = ({ columns }) => (
  <TableHeader>
    <TableRow>
      {map(columns, (column) => (
        <TableHeaderData>
          <Text size="h5" bold>
            {column}
          </Text>
        </TableHeaderData>
      ))}
    </TableRow>
  </TableHeader>
);

const parseItem = (item) => {
  if (isObject(item)) {
    return JSON.stringify(item);
  }
  return toString(item);
};

const Body = ({ rows }) => (
  <TableBody>
    {map(rows, (row) => (
      <TableRow>
        {map(row, (item) => (
          <TableData>
            {/* toString is needed to properly handle all types of values, such as boolean. */}
            <Text size="h5">{parseItem(item)}</Text>
          </TableData>
        ))}
      </TableRow>
    ))}
  </TableBody>
);

const TableDisplayModal = ({ handleClose, data, title, emptyDataMessage, tableInfoText }) => {
  return (
    <Modal title={title} handleClose={handleClose} width={600}>
      <ModalBody>
        {isEmpty(data) && <Text size="h4">{emptyDataMessage}</Text>}
        {!isEmpty(data) && (
          <>
            <Text size="h4">{tableInfoText}</Text>
            <Table>
              <Head columns={['Attribute', 'Value']} />
              <Body rows={data} />
            </Table>
          </>
        )}
      </ModalBody>
      <ModalFooter variant="buttons">
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

Head.propTypes = {
  columns: PropTypes.array,
};

Body.propTypes = {
  rows: PropTypes.array,
};

TableDisplayModal.propTypes = {
  title: PropTypes.string,
  emptyDataMessage: PropTypes.string,
  tableInfoText: PropTypes.string,
  handleClose: PropTypes.func,
  data: PropTypes.array,
};

export default TableDisplayModal;
