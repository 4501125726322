import styled from 'styled-components';

import { LEARNING_TYPES } from 'catalog/constants';
import { useContentPeople } from 'catalog/hooks';
import { useLatestContentItemAssignment } from 'common/hooks/useLatestContentItemAssignment';
import ContentItemContainer from 'content-items/components/ContentItemContainer';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import BreadCrumbs from 'shared-content-item/components/BreadCrumbs';
import ContentItemAdditional from 'shared-content-item/components/ContentItemAdditional';
import ContentItemBackgroundPanel from 'shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemHeader from 'shared-content-item/components/ContentItemHeader';
import ResourceContainer from 'shared-content-item/components/ResourceContainer';
import { ContentItem } from 'shared-content-item/interfaces';
import { PageContainer, PageBody } from 'shared/components/DefaultPage';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import Text from 'shared/components/Text';
import { useResource } from 'shared/hooks';
import { Track } from 'tracks/interfaces';
import { get } from 'vendor/lodash';

import EventTypeContent from '../EventTypeContent';
import EventTypeContextMenu from '../EventTypeContextMenu';
import PrimaryActionButton from '../PrimaryActionButton';

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

interface EventTypeDetailPageProps {
  content: ContentItem;
  track?: Track;
  isRenderedWithinTrack?: boolean;
  isRequired?: boolean;
}

const EventTypeDetailPage = ({
  content: baseContent,
  track,
  isRenderedWithinTrack,
  isRequired = true,
}: EventTypeDetailPageProps) => {
  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssigment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssigment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const { showResource } = useResource(content, LEARNING_TYPES.event_types);
  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const pageTitle = content.name ? `${content.name} events` : '';

  const { is_inline: isInline } = content;
  const clickableName = isInline ? false : isRenderedWithinTrack;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={pageTitle} />}
      <ContentItemBackgroundPanel height="120px" $isTrackItem={isRenderedWithinTrack} />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} track={track} />}
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          maintainers={maintainers}
          contextMenu={
            <EventTypeContextMenu
              content={content}
              contextMenuExtraProps={{ isTrackItem: isRenderedWithinTrack }}
            />
          }
          primaryActionButton={PrimaryActionButton}
          clickableName={clickableName}
          isRequired={isRequired}
        />
        <PageBody>
          {/* note: when work in the event type body revamp task, try to use the ContentItemBody */}
          <BodyContent>
            <EventTypeContent content={content} />
            <ContentItemAdditional>
              {showResource && <ResourceContainer content={content} />}
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.event_types}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={engagedPeopleCount}
                  engagedPeopleHeading={
                    <Text>
                      {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
            </ContentItemAdditional>
          </BodyContent>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
};

export default EventTypeDetailPage;
