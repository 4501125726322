import { useState } from 'react';

import SplitButton from 'app/shared/components/SplitButton';
import { Tooltip } from 'vendor/mui';
import { OpenInNewOutlinedIcon } from 'vendor/mui-icons';

interface OpenLinkButtonProps {
  url: string;
  onClick?: () => void;
}

const OpenLinkButton = ({ url, onClick }: OpenLinkButtonProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => handleTooltipClose(), 2000);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={openTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Copied!"
    >
      <div>
        <SplitButton
          variant="contained"
          label="Open"
          startIcon={<OpenInNewOutlinedIcon />}
          onClick={onClick}
          secondaryActionOptionList={[
            {
              label: 'Copy to clipboard',
              onClick: () => {
                navigator.clipboard.writeText(url);
                handleTooltipOpen();
              },
            },
          ]}
        />
      </div>
    </Tooltip>
  );
};

export default OpenLinkButton;
