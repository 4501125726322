import { QueryFunction } from '@tanstack/react-query';

import { ApiURLs, fetchURL } from 'services/requests-base';

interface FactoryProps {
  endpointName: string;
}

export interface APIResponse<TData = any> {
  count: number;
  next: string | null;
  previous: string | null;
  results: TData[];
}

export interface MutationOptions {
  onMutate?: (variables) => any;
  onError?: (error, variables, context) => any;
  onSuccess?: (data, variables, context) => any;
  onSettled?: (data, error, variables, context) => any;
}

export const fetchDetailFactory = ({ endpointName }: FactoryProps) => {
  return async <TData>({ publicId, viewMode }): Promise<TData | null> => {
    if (!(endpointName in ApiURLs)) {
      // Instead of crashing, this will show the right place of the problem.
      console.error(`Endpoint '${endpointName}' does not exist`);
      return null;
    }
    const base = ApiURLs[endpointName](publicId);
    const url = `${base}?view_mode=${viewMode}`;
    const { data: response } = await fetchURL(url);
    return response;
  };
};

export const fetchListFactory = ({ endpointName }: FactoryProps) => {
  return async <TData>({ search, nextPage }): Promise<APIResponse<TData>> => {
    if (!(endpointName in ApiURLs)) {
      // Instead of crashing, this will show the right place of the problem.
      console.error(`Endpoint '${endpointName}' does not exist`);
      return { count: 0, next: null, previous: null, results: [] };
    }
    const base = ApiURLs[endpointName]();
    const url = nextPage ? nextPage : `${base}?${search}`;
    const { data: response } = await fetchURL(url);
    return response;
  };
};

type Tuple = [ValidValue, ...Array<ValidValue | undefined>];
type KeyTuple = Tuple | Readonly<Tuple>;
type ValidValue = string | number | boolean | object;
type QueryKeyRecord = Record<'queryKey', KeyTuple>;
type DynamicQueryFactorySchema = QueryKeyRecord & { queryFn: QueryFunction };
type DetailQueryDynamicKey = (publicId: string, viewMode?: string) => DynamicQueryFactorySchema;
type ListQueryDynamicKey = (search?: string) => DynamicQueryFactorySchema;

export const detailQueryKeyFactory = ({ endpointName }: FactoryProps): DetailQueryDynamicKey => {
  const fetchDetail = fetchDetailFactory({ endpointName });
  return (publicId, viewMode = 'default') => ({
    queryKey: [publicId, viewMode],
    queryFn: () => fetchDetail({ publicId, viewMode }),
  });
};

export const listQueryKeyFactory = ({ endpointName }: FactoryProps): ListQueryDynamicKey => {
  const fetchList = fetchListFactory({ endpointName });
  return (search = '') => ({
    queryKey: [search],
    queryFn: ({ pageParam: nextPage }) => fetchList({ search, nextPage }),
  });
};
