import { useMutation } from '@tanstack/react-query';

import { apiPatch, apiPost, apiDelete } from 'services/requests';

export function useCreateChannel() {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPost('channels_api:list_create', {}, data);
      return response;
    },
  });
}

export function useEditChannel(public_id: string) {
  return useMutation({
    mutationFn: async (data: any) => {
      const { data: response } = await apiPatch(
        'channels_api:retrieve_update_destroy',
        public_id,
        data
      );
      return response;
    },
  });
}

export function useDeleteChannel(public_id: string) {
  return useMutation({
    mutationFn: async () => {
      const { data: response } = await apiDelete('channels_api:retrieve_update_destroy', public_id);
      return response;
    },
  });
}
