import { useState } from 'react';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import { ContentItem } from 'shared-content-item/interfaces';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import ContentRating from 'stand-alone-shared/components/ContentRating';
import { get, isNil } from 'vendor/lodash';
import { CheckIcon } from 'vendor/mui-icons';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

interface PrimaryActionButtonProps {
  content: ContentItem;
  contentType?: string;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content, contentType: learningType } = props;

  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;

  const { create, complete, rate, isLoading } = useAssignmentsActionsContext();
  const [isRatingContent, setRatingContent] = useState(false);

  const assignmentState = get(assignment, 'state');
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);

  if (!isCompletable) return null;

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    return (
      <>
        <Container>
          {currentRating > 0 && (
            <>
              <RatingContainer>
                <Rating value={currentRating} readOnly />
              </RatingContainer>
              <ButtonLink
                onClick={() => setRatingContent(true)}
                variant="primary"
                disabled={isLoading}
              >
                Edit
              </ButtonLink>
            </>
          )}
          {currentRating <= 0 && (
            <RateButton
              size="medium"
              onClick={() => setRatingContent(true)}
              fullWidth
              disabled={isLoading}
            >
              Rate
            </RateButton>
          )}
        </Container>
        {isRatingContent && (
          <ContentRating
            content={content}
            contentType={learningType}
            onSubmitFunc={rate}
            handleClose={() => setRatingContent(false)}
          />
        )}
      </>
    );
  }

  const onMarkCompleteClick =
    isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped
      ? () => create({ is_completed: true })
      : complete;

  return (
    <Container>
      <Button
        variant="contained"
        startIcon={<CheckIcon />}
        fullWidth
        onClick={() => onMarkCompleteClick()}
        disabled={isLoading}
      >
        Mark Complete
      </Button>
    </Container>
  );
};

export default PrimaryActionButton;
