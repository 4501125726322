import moment from 'moment';
import pluralize from 'pluralize';
import { useState } from 'react';

import { Event } from 'app/event/interfaces';
import AttendanceStatusWidget from 'event-shared/components/AttendanceStatusWidget';
import EventCardHeader from 'event-shared/components/EventCardHeader';
import EventCover from 'event-shared/components/EventCover';
import EventInviteUsersModal from 'event-shared/components/EventInviteUsersModal';
import { getEnrollmentOpensCutoff } from 'event-shared/services';
import colors from 'services/colors';
import { displayDatetime } from 'services/datetime';
import { mapRoute } from 'services/requests';
import ButtonLink from 'shared/components/ButtonLink';
import CancelledPill from 'shared/components/CancelledPill';
import { useCurrentUser } from 'shared/hooks';
import { toNumber, isNil, get } from 'vendor/lodash';
import { styled } from 'vendor/mui';

const Content = styled('div')``;

const Footer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.neutral200};
  padding: 8px 16px;
  color: ${colors.neutral400};
  align-self: flex-end;
  font-size: 11pt;
  padding-top: 16px;
`;

const EventCardContainer = styled('div')`
  width: 312px;
  height: 480px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 0;
`;

const Body = styled('div')``;

interface EventCardProps {
  event: Event;
  showCover?: boolean;
  shouldRefreshEvent?: boolean;
}

export const EventCard = ({
  event,
  showCover = true,
  shouldRefreshEvent = true,
}: EventCardProps) => {
  const [showInviteMorePeopleModal, setShowInviteMorePeopleModal] = useState(false);
  const currentUser = useCurrentUser();

  const enrollmentOpeningDate = getEnrollmentOpensCutoff(event);

  const isOpenedForEnrollment = isNil(enrollmentOpeningDate)
    ? true
    : moment().isSameOrAfter(enrollmentOpeningDate);

  if (!event) return <div />;

  const peopleGoing =
    toNumber(get(event, 'enrollment_counts_dict.going', 0)) +
    toNumber(get(event, 'enrollment_counts_dict.going_online', 0));

  return (
    <EventCardContainer>
      {showCover ? (
        <EventCover
          eventPublicIdAndSlug={event.public_id_and_slug}
          // CA 2.0
          eventCover={event.cover || event.default_cover || event.cover_url}
          linkDetailsInCover
          curvedBorders
        />
      ) : null}
      <Content>
        <Body>
          <EventCardHeader
            event={event}
            route={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
            mode="card"
          />
          <AttendanceStatusWidget
            event={event}
            viewMode="cozy"
            shouldRefreshEvent={shouldRefreshEvent}
          />
        </Body>

        {!isOpenedForEnrollment && enrollmentOpeningDate && (
          <Footer>
            <span>
              Opens on{' '}
              {displayDatetime(
                enrollmentOpeningDate,
                event.timezone,
                currentUser.timezone,
                event.is_online
              )}
            </span>
          </Footer>
        )}

        {isOpenedForEnrollment && !event.has_started && (
          <Footer>
            {event.is_archived && <CancelledPill size="small" />}
            <span>
              {pluralize('person', peopleGoing, true)} {pluralize('is', peopleGoing)} going
            </span>
            <ButtonLink
              alt={`Invite users to ${event.name}`}
              onClick={() => setShowInviteMorePeopleModal(true)}
            >
              Invite
            </ButtonLink>
          </Footer>
        )}
        {isOpenedForEnrollment && event.has_started && (
          <Footer>
            <span>
              {pluralize('person', get(event, 'enrollment_counts_dict.attended', 0), true)} went
            </span>
          </Footer>
        )}
      </Content>
      {showInviteMorePeopleModal && (
        <EventInviteUsersModal
          event={event}
          handleClose={() => setShowInviteMorePeopleModal(false)}
        />
      )}
    </EventCardContainer>
  );
};

export default EventCard;
