import queryString from 'query-string';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import {
  formValueSelector,
  getFormMeta,
  getFormSyncErrors,
  getFormSubmitErrors,
  isDirty,
  hasSubmitSucceeded,
} from 'redux-form';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import { useClipboard } from 'use-clipboard-copy';

import { LEARNING_TYPES } from 'app/catalog/constants';
import { isLocalStorageAvailable } from 'services/local-storage';
import { formatStickyFilters, getStickyFiltersFromUser } from 'services/sticky-filters';
import { getUID } from 'services/utils';
import * as sharedActions from 'shared/actions';
import {
  ceil,
  filter,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  keys,
  map,
  noop,
  pickBy,
  reject,
  replace,
  split,
  toInteger,
} from 'vendor/lodash';

/**
 * Checks if a DOM node has ellipsis or not
 *
 * Use { nodeRef } as a react callback ref on the node you want to check if has ellipsis
 * Use { hasEllipsis } to check if the element targeted by { nodeRef } has ellipsis
 *
 * See the following link for reference:
 *  https://reactjs.org/docs/hooks-faq.html?source=post_page-----eb7c15198780----------------------#how-can-i-measure-a-dom-node
 *
 * Remember to use CSS to allow { nodeRef } having ellipsis
 */
export function useEllipsisCheck({ multipleLines = false } = {}) {
  const [hasEllipsis, setHasEllipsis] = useState(false);

  const checkEllipsis = useCallback(
    (node) => {
      if (multipleLines) {
        if (node !== null && node.scrollHeight > node.offsetHeight) {
          setHasEllipsis(true);
        }
      } else if (node !== null && node.scrollWidth > node.offsetWidth) {
        setHasEllipsis(true);
      }
    },
    [multipleLines]
  );

  const nodeRef = useCallback(
    (node) => {
      if (!node) return;

      // We use ResizeObserver to check if the element has ellipsis
      const resizeObserver = new ResizeObserver(() => checkEllipsis(node));
      resizeObserver.observe(node);

      return () => resizeObserver.disconnect();
    },
    [checkEllipsis]
  );

  return { hasEllipsis, nodeRef };
}

export function useUID() {
  const memoUID = useMemo(() => getUID(), []);
  const [uid] = useState(memoUID);

  return uid;
}

// Spread 'targetProps' in the target element, and pass 'uid' as id prop to the tooltip
export function useTooltipUID() {
  const uid = useUID();
  const targetProps = {
    'data-tip': '',
    'data-for': uid,
  };

  return { uid, targetProps };
}

export function useOriginRoute(defaultRoute) {
  const location = useLocation();
  const args = queryString.parse(location.search);

  let route = defaultRoute;
  if (get(args, 'origin')) {
    route = args.origin;
  }

  return route;
}

export function useLocalStorage(key, version) {
  const versionedKey = version ? `v${version}_${key}` : key;

  const [items, setItems] = useState(JSON.parse(localStorage.getItem(versionedKey)));

  useEffect(() => {
    if (version && version > 1) {
      localStorage.removeItem(key);
      for (let i = 1; i < version; i += 1) {
        localStorage.removeItem(`v${i}_${key}`);
      }
    }
  }, []);

  if (!isLocalStorageAvailable()) {
    return [null, noop];
  }
  // Update localStorage item
  const updateItems = (newValues) => {
    setItems(newValues);
    localStorage.setItem(versionedKey, JSON.stringify(newValues));
  };

  return [items, updateItems];
}

export function useStickyFilters(filterPageName) {
  const { public_id: userPublicId, client_encryption_key: userEncryptionKey } = useCurrentUser();
  const [stickyFiltersObject, updateValue] = useLocalStorage('sticky_filters', 1);
  const stickyFiltersFromUser = getStickyFiltersFromUser(
    stickyFiltersObject,
    userPublicId,
    userEncryptionKey
  );

  const updateStickyFilters = (newFilters) =>
    updateValue({
      [userPublicId]: formatStickyFilters(
        stickyFiltersFromUser || {},
        filterPageName,
        newFilters,
        userEncryptionKey
      ),
    });

  if (!stickyFiltersFromUser) {
    return [null, updateStickyFilters];
  }

  return [get(stickyFiltersFromUser, filterPageName), updateStickyFilters];
}

/**
 * Data flow:
 *  - updateFilters update the URL
 *  - URL Changes trigger the effect
 *  - Filters object is updated
 */
export function useRouteFilters({ notListAttrs = ['q'], initialFilters = {} } = {}) {
  const location = useLocation();
  const history = useHistory();

  const getFiltersWithProcessedNonListAttrs = () => {
    const filters = queryString.parse(location.search);
    forEach(keys(filters), (key) => {
      // Exclude filters from transformation
      if (!isArray(filters[key]) && !includes(notListAttrs, key)) {
        filters[key] = [filters[key]];
      }
    });
    return filters;
  };

  const [filters, setFilters] = useState(getFiltersWithProcessedNonListAttrs());
  // Check this to avoid double requests on the first render:
  const [hasLoadedRouteFilters, setHasLoadedRouteFilters] = useState(false);

  // Update filters state from URL changes
  useEffect(() => {
    setHasLoadedRouteFilters(true);

    if (isEmpty(location.search)) {
      setFilters(initialFilters);
    } else {
      setFilters(getFiltersWithProcessedNonListAttrs());
    }
  }, [location]);

  // Update URL with new filters
  const updateFilters = (newFilters) => {
    // Clears empty string values from the URL
    const filtered = pickBy(newFilters, (value) => value !== '');
    // Lodash' `replace` doesn't work with `history` objects
    // eslint-disable-next-line lodash/prefer-lodash-method
    history.replace(`${location.pathname}?${queryString.stringify(filtered)}`);
  };

  return [filters, updateFilters, hasLoadedRouteFilters];
}

// Receives a filter to convert it from object to string format
// Removes undefined and empty lists params
// e.g.: { q: "frontend", page_size: 12 } --> "?q=frontend&page_size=12"
export function useStringFilters(filters, initialCharacter = '?') {
  const nonEmptyFilters = filter(Object.entries(filters), ([, value]) => {
    if (isArray(value)) {
      return value.length;
    }

    return value ?? false;
  });

  const paramsList = map(nonEmptyFilters, ([key, value]) => {
    // 'q' case
    if (!isArray(value)) {
      return `${key}=${value}`;
    }

    return map(value, (entry) => `${key}=${entry}`).join('&');
  });

  return `${paramsList.length ? initialCharacter : ''}${paramsList.join('&')}`;
}

export function useQueryParams() {
  const location = useLocation();
  return queryString.parse(location.search);
}

// Encode filters from URL so all of them can be identified and treated as filters
// e.g.: appear at location.search
export function useEncodedCurrentRoute() {
  const location = useLocation();
  return `${location.pathname}${encodeURIComponent(location.search)}`;
}

// Source: https://codesandbox.io/s/react-hooks-navigate-list-with-keyboard-eowzo?file=/src/index.js:111-711
export const useKeyPress = (targetKey, ref, setPreventDefault = false) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(event) {
    const { key } = event;
    if (key === targetKey) {
      setKeyPressed(true);

      if (setPreventDefault) {
        event.preventDefault();
      }
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    ref.current.addEventListener('keydown', downHandler);
    ref.current.addEventListener('keyup', upHandler);

    return () => {
      ref.current.removeEventListener('keydown', downHandler);
      ref.current.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
};

// Source: https://usehooks.com/useDebounce/
// TODO suggestion: add a way of forcing the change, useful for clearing input field, for instance
//  maybe returning a function that bypasses and clears the timeout
export function useDebounce(value, delay = 500) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

// Source: https://usehooks.com/usePrevious/
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// Source: https://usehooks.com/useOnClickOutside/
export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendant elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

// Use this for blocking route change
// From https://github.com/ReactTraining/react-router/issues/5405#issuecomment-526697676
export function useUnsavedChangesPrompt(when, message = 'Changes you made may not be saved.') {
  const history = useHistory();

  const self = useRef(null);

  const onWindowOrTabClose = (event) => {
    if (!when) {
      return undefined;
    }

    if (event) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    if (when) {
      self.current = history.block(message);
    } else {
      self.current = null;
    }

    window.addEventListener('beforeunload', onWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose);
    };
  }, [message, when]);
}

/**
 * Useful to manipulate query string parameters without affecting existing ones
 * Inspired by https://gist.github.com/DimitryDushkin/5ae91afbc5a51e4fb77772546551dfc5
 *
 * @returns
 * - addToQueryString: receive an object with `{ paramName: paramValue }` to add to current query string
 *
 * - removeFromQueryString: receive a list of keys to be removed from query string params
 */
export const useRouterQueryUtils = () => {
  const history = useHistory();
  const location = useLocation();

  const getUrlWithQueryString = (queryObj) => {
    const path = location.pathname;
    const searchObj = queryString.parse(location.search);
    const newSearch = queryString.stringify({ ...searchObj, ...queryObj });

    return `${path}?${newSearch}`;
  };

  const addToQueryString = (queryObj, replace = false) => {
    if (replace) {
      // Lodash' `replace` doesn't work with `history` objects
      // eslint-disable-next-line lodash/prefer-lodash-method
      history.replace(getUrlWithQueryString(queryObj));
      return;
    }
    history.push(getUrlWithQueryString(queryObj));
  };

  const getUrlWithoutQueryString = (removeKeys) => {
    const path = location.pathname;
    const searchObj = queryString.parse(location.search);
    const newSearchObj = reject(searchObj, (value, key) => includes(removeKeys, key));
    return `${path}?${newSearchObj}`;
  };

  const removeFromQueryString = (removeKeys, replace = false) => {
    if (replace) {
      // Lodash' `replace` doesn't work with `history` objects
      // eslint-disable-next-line lodash/prefer-lodash-method
      history.replace(getUrlWithoutQueryString(removeKeys));
      return;
    }
    history.push(getUrlWithoutQueryString(removeKeys));
  };

  return {
    getUrlWithQueryString,
    addToQueryString,
    getUrlWithoutQueryString,
    removeFromQueryString,
  };
};

// Redux Hooks

export const useCurrentUser = () => useSelector((state) => state.user.currentUser);

export const useCurrentUserShortTimezone = () => {
  const { timezone } = useCurrentUser();
  const now = new Date().toLocaleTimeString('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
  });
  return split(now, ' ')[2];
};

export const useToggles = () => {
  const currentUser = useCurrentUser();
  return currentUser.toggles;
};

export const useModuleToggles = () => {
  const currentUser = useCurrentUser();
  return currentUser.module_toggles;
};

export const useModuleLabels = () => {
  const currentUser = useCurrentUser();
  return currentUser.module_labels;
};

export const useLabels = () => {
  const currentUser = useCurrentUser();
  return currentUser.labels;
};

export const useFiltersOrder = () => {
  const currentUser = useCurrentUser();
  return currentUser.filters_order ?? {};
};

/* Redux-form hooks */

export const useFormSelector = (form, field) => {
  const formSelector = formValueSelector(form);
  return useSelector((state) => formSelector(state, field));
};

export const useFormSyncErrors = (form, field, defaultValue) => {
  const errorSelector = getFormSyncErrors(form);
  return useSelector((state) => get(errorSelector(state), field, defaultValue));
};
export const useFormSubmitErrors = (form, field, defaultValue) => {
  const errorSelector = getFormSubmitErrors(form);
  return useSelector((state) => get(errorSelector(state), field, defaultValue));
};

export const useFormMeta = (form, field, defaultValue) => {
  const metaSelector = getFormMeta(form);
  return useSelector((state) => get(metaSelector(state), field, defaultValue));
};

export const useReduxFormContext = () => {
  return useContext(ReduxFormContext);
};

export const useFormIsDirty = (form) => {
  const isDirtySelector = isDirty(form);
  return useSelector((state) => isDirtySelector(state));
};
export const useFormHasSubmitSucceeded = (form) => {
  const hasSubmitSucceededSelector = hasSubmitSucceeded(form);
  return useSelector((state) => hasSubmitSucceededSelector(state));
};

export const useFormPreventTransition = (
  form,
  message = 'Are you sure? Changes you made may not be saved.'
) => {
  const isFormDirty = useFormIsDirty(form);
  const hasSubmitSucceeded = useFormHasSubmitSucceeded(form);
  const shouldPreventPageTransition = isFormDirty && !hasSubmitSucceeded;
  const history = useHistory();

  const preventRefresh = (e) => {
    if (shouldPreventPageTransition) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  useEffect(() => {
    const unblock = history.block((loc) => {
      const currentUrl = get(history, 'location.pathname');
      const transitionUrl = get(loc, 'pathname');

      const isSameUrl = currentUrl === transitionUrl;

      if (!isSameUrl && shouldPreventPageTransition) {
        return message;
      }
    });

    window.addEventListener('beforeunload', preventRefresh);

    return () => {
      unblock();
      window.removeEventListener('beforeunload', preventRefresh);
    };
  }, [shouldPreventPageTransition, message]);
};

/*
  This prefixes the field name passed by parameter with the sectionPrefix provided by FormSection, if it exists
 */
export const usePrefixedFormName = (name) => {
  const ctx = useReduxFormContext();
  if (ctx?.sectionPrefix) {
    return `${ctx.sectionPrefix}.${name}`;
  }
  return name;
};

/*

Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useSetDetailedObject = (obj, type, deps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: sharedActions.default.setGlobalDetailedObject,
      payload: obj,
    });
    dispatch({ type: sharedActions.default.setGlobalDetailedObjectType, payload: type });
    dispatch({ type: sharedActions.default.setGlobalUsesDetailedObject, payload: true });
    return () => {
      dispatch({
        type: sharedActions.default.setGlobalDetailedObject,
        payload: {},
      });
      dispatch({ type: sharedActions.default.setGlobalDetailedObjectType, payload: null });
      dispatch({ type: sharedActions.default.setGlobalUsesDetailedObject, payload: false });
    };
  }, deps);
};

export const usePublicIdFromURL = () => {
  const { public_id_and_slug: publicIdAndSlug } = useParams();

  const publicId = publicIdAndSlug ? split(publicIdAndSlug, '_')[0] : null;

  return {
    publicId,
  };
};

export const usePaginationManager = (fetchPage, initialFilters = {}) => {
  const [currentFilters, setCurrentFilters] = useState({
    ...initialFilters,
    page: 1,
  });

  const handlePageChange = (_, page) => {
    applyChanges({
      ...currentFilters,
      page,
    });
  };

  useEffect(() => {
    fetchPage(currentFilters);
  }, [currentFilters]);

  const applyChanges = (newFilters) => {
    setCurrentFilters({ ...newFilters });
  };

  const handleFilterChange = (changedFilters) => {
    applyChanges({
      ...currentFilters,
      ...changedFilters,
      page: 1,
    });
  };

  const getPageCount = (totalCount, pageSize) => ceil(totalCount / pageSize);
  const currentPage = toInteger(currentFilters.page);

  return { handlePageChange, getPageCount, currentPage, currentFilters, handleFilterChange };
};

/**
 * Scrolls to a location specified by the anchor in the URL.
 *
 * It'll attempt to find the element before scrolling once to that location.
 *
 * To use it, simply call this in the component wou want to use.
 *
 * It'll scroll to the anchor everytime it value is updated.
 */
export const useScrollToURLAnchorLocation = () => {
  const scrolledRef = useRef(false);
  const { hash } = useLocation();
  const hashRef = useRef(hash);

  useEffect(() => {
    if (hash) {
      // We want to reset if the hash has changed
      if (hashRef.current !== hash) {
        hashRef.current = hash;
        scrolledRef.current = false;
      }

      // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
      if (!scrolledRef.current) {
        const id = replace(hash, '#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          scrolledRef.current = true;
        }
      }
    }
  });
};

export const useResource = (content, contentType) => {
  const hasResources =
    content?.office_hour || !isEmpty(content?.resources) || !isEmpty(content?.slack_channel);
  const showResource = hasResources && contentType !== LEARNING_TYPES.programs;
  return { showResource };
};

export const BrowserBackStackContext = createContext({ browserBackStack: [] });

export const useBrowserBackStack = () => {
  const history = useHistory();
  const [backStack, setBackStack] = useState([]);
  useEffect(() => {
    return history.listen((location, action) => {
      setBackStack((backStack) => {
        switch (action) {
          case 'POP':
            return backStack.slice(0, -1);
          case 'PUSH':
            return [...backStack, location];
          case 'REPLACE':
            return [...backStack.slice(0, -1), location];
          default:
            return backStack;
        }
      });
    });
  }, [setBackStack, history]);
  return backStack;
};

export const usePreviousLocation = () => {
  const { browserBackStack } = useContext(BrowserBackStackContext);
  return browserBackStack[browserBackStack.length - 2];
};

export const useCopyToClipboard = (timeout) => {
  const clipboard = useClipboard({ copiedTimeout: timeout });

  const copyToClipboard = (contentToCopy) => {
    clipboard.copy(contentToCopy);
  };
  return { clipboard, copyToClipboard };
};

export const useDataGridPaginationAndSorting = ({
  defaultPageNumber = 0,
  defaultRowsPerPage = 5,
  defaultSort = '',
} = {}) => {
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [sort, setSort] = useState(defaultSort);

  const rowsPerPageOptions = [5, 10, 50, 100];

  const handleChangePage = (page) => {
    if (page === pageNumber) return;
    setPageNumber(page);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    if (pageSize === rowsPerPage) return;
    setRowsPerPage(pageSize);
    setPageNumber(0);
  };

  const handleChangeSort = (model) => {
    if (isEmpty(model)) return;

    const { field, sort } = model[0];
    if (sort === 'asc') {
      setSort(field);
    }
    if (sort === 'desc') {
      setSort(`-${field}`);
    }
    setPageNumber(0);
  };

  return {
    pageNumber,
    rowsPerPage,
    rowsPerPageOptions,
    sort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSort,
  };
};

export const useIsPreviewQueryParam = () => {
  const { preview } = useQueryParams();
  return !!(preview && preview === 'true');
};

export const useOrderingLabel = (ordering) => {
  const orderingLabels = useMemo(
    () => ({
      name: 'A-Z',
      relevance: 'Relevance',
      '-total_assignments': 'Most Engaged',
      '-avg_feedback_rating': 'Best Rated',
      '-name': 'Z-A',
      '-created': 'Newest',
      created: 'Oldest',
      start_time: 'Date',
    }),
    []
  );

  const value = orderingLabels[ordering];

  return useMemo(() => {
    if (!value) return;
    return `Sorted by ${value}. You can change the sorting order from the top dropdown menu.`;
  }, [value]);
};

// Validate that the user has permissions  for the action and that the toggle is active.
export const useHasChannelPermission = (permission) => {
  const { permissions } = useCurrentUser();
  const { toggle_channels: toggleChannels } = useToggles();
  return toggleChannels && includes(permissions, permission);
};

export const useChannelToggle = () => {
  const { toggle_channels: toggleChannels } = useToggles();
  return toggleChannels;
};
