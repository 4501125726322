/* eslint-disable jsx-a11y/anchor-is-valid */

import Breadcrumbs from 'app/shared/components/Breadcrumbs';
import { useBreadcrumbs } from 'app/tracks/useBreadcrumbs';
import TopBar from 'shared/components/TopBar';
import { Track, TrackItem, FullTrackSection } from 'tracks/interfaces';
import { Box } from 'vendor/mui';

interface TrackConsumptionTopBarProps {
  track: Track;
  item?: TrackItem;
  section?: FullTrackSection;
}

const TrackConsumptionTopBar = ({ track, item, section }: TrackConsumptionTopBarProps) => {
  const breadcrumbs = useBreadcrumbs(track, item, section);

  return (
    <TopBar>
      <Box display="flex" alignItems="center" height="inherit" gap={2} padding="8px 16px">
        <Breadcrumbs
          breadcrumbsProps={{ maxItems: 5, 'aria-label': 'Track consumption breadcrumb' }}
          items={breadcrumbs}
        />
      </Box>
    </TopBar>
  );
};

export default TrackConsumptionTopBar;
