import { createContext, useContext, useEffect } from 'react';

import ConfirmationModal from 'app/shared/components/ConfirmationModal';
import { useLabels } from 'shared/hooks';
import { noop, toLower } from 'vendor/lodash';
import { Typography } from 'vendor/mui';

export const AssessmentQuestionSkipConfirmationModalContext = createContext<{
  callback?: () => void;
  open: boolean;
  setOpen: (open: boolean, callback?: () => void) => void;
  dirtyQuestion: boolean;
  setDirtyQuestion: (dirty: boolean) => void;
}>({ open: false, setOpen: noop, dirtyQuestion: false, setDirtyQuestion: noop });

export const AssessmentQuestionSkipConfirmationModal = () => {
  const { label_question: labelquestion } = useLabels();

  const { open, callback, setOpen, dirtyQuestion, setDirtyQuestion } = useContext(
    AssessmentQuestionSkipConfirmationModalContext
  );

  // Only show modal if the question has user input
  useEffect(() => {
    if (!dirtyQuestion) {
      setOpen(false);
      callback && callback();
    }
  }, []);

  if (!dirtyQuestion) {
    return null;
  }

  return (
    <ConfirmationModal
      open={open}
      onConfirmClick={() => {
        setOpen(false);
        setDirtyQuestion(false);
        callback && callback();
      }}
      handleClose={() => {
        setOpen(false);
      }}
      deleteModalTitle="Confirmation"
      confirmText="Skip"
      confirmButtonColor="primary"
    >
      <Typography variant="subtitle2">Skip this {toLower(labelquestion)}?</Typography>
      <Typography marginTop="16px">
        You can return to it later, but any current input will be cleared.
      </Typography>
    </ConfirmationModal>
  );
};

export default AssessmentQuestionSkipConfirmationModal;
