import { tint } from 'polished';

import { Assessment } from 'assessments/interfaces';
import { LEARNING_TYPES, LEARNING_TYPE_ICONS_MAPPING } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import ContentItemContainer from 'content-items/components/ContentItemContainer';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import BreadCrumbs from 'shared-content-item/components/BreadCrumbs';
import ContentItemBackgroundPanel from 'shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemHeader from 'shared-content-item/components/ContentItemHeader';
import { PageBody, PageContainer } from 'shared/components/DefaultPage';
import Icon from 'shared/components/Icon';
import PageTitle from 'shared/components/PageTitle';
import { useEncodedCurrentRoute } from 'shared/hooks';
import {
  CONTENT_ITEM_PERMISSIONS,
  EDIT_TRACK_PERMISSION,
  MANAGE_TRACK_ASSIGNMENT_PERMISSION,
} from 'shared/permissions';
import { ScheduledTrack, Track } from 'tracks/interfaces';
import { includes, isNil } from 'vendor/lodash';
import { Box, Chip } from 'vendor/mui';

import PrimaryActionButton from './PrimaryActionButton';
import { SecondaryActionButton } from './SecondaryActionButton/SecondaryActionButton';
import TrackContextMenu from './TrackContextMenu';
import { TrackDetailBody } from './TrackDetailBody';

interface TrackChipProps {
  track: Track;
}

const TrackChip = ({ track }: TrackChipProps) => {
  if (!track) return null;

  return (
    <Box marginTop={-2.5} marginBottom={2.5}>
      <Chip
        clickable
        component="a"
        href={mapRoute('trackDetails', {
          public_id_and_slug: track.public_id_and_slug,
        })}
        size="small"
        icon={
          <Icon
            name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tracks]}
            width={12}
            height={12}
            color={colors.emphasis700}
          />
        }
        label={track.name}
        sx={{
          backgroundColor: colors.emphasis200,
          color: colors.emphasis700,
          '&:hover': {
            color: colors.emphasis700,
            backgroundColor: tint(0.2, colors.emphasis200),
          },
        }}
      />
    </Box>
  );
};

interface TrackDetailPageProps {
  assessment?: Assessment;
  scheduledTrack?: ScheduledTrack;
  track?: Track;
  showBreadcrumbs?: boolean;
  isRequired?: boolean;
  refreshContent: CallableFunction;
  isRenderedWithinTrack?: boolean;
}

const TrackDetailPage = ({
  assessment,
  scheduledTrack,
  track,
  showBreadcrumbs = true,
  isRequired = true,
  refreshContent,
  isRenderedWithinTrack = false,
}: TrackDetailPageProps) => {
  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const currentRoute = useEncodedCurrentRoute();

  const content = assessment || scheduledTrack || track;

  if (isNil(content)) return null;

  const renderBreadcrumbs = () => {
    // Does not show breadcrumbs if content is a track item. See usages on TrackItemRenderer.
    if (isRenderedWithinTrack || !showBreadcrumbs) return null;

    return <BreadCrumbs content={content} margin="-20px 0 20px" />;
  };

  const canSeeSettings =
    includes(content.permissions, EDIT_TRACK_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.manage);
  const canManageAssignment =
    includes(content.permissions, MANAGE_TRACK_ASSIGNMENT_PERMISSION) ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign);
  const extraContent = {
    canSeeSettings,
    canManageAssignment,
    contentTypeRoute: contentTypeRoutes[content.content_type],
    currentRoute,
  };

  const clickableName = content.is_inline ? false : isRenderedWithinTrack;

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      <ContentItemBackgroundPanel height="120px" />
      <PageTitle title={content.name} />

      <PageContainer columnsWidth="1fr">
        <Box>
          {scheduledTrack && <TrackChip track={(content as ScheduledTrack).original_track} />}
          {renderBreadcrumbs()}

          <ContentItemHeader
            content={content}
            contextMenu={
              <TrackContextMenu
                content={content}
                contextMenuExtraProps={extraContent}
                refreshContent={refreshContent}
              />
            }
            primaryActionButton={PrimaryActionButton}
            secondaryActionButton={SecondaryActionButton}
            clickableName={clickableName}
            isRequired={isRequired}
            refreshContent={refreshContent}
          />
        </Box>

        <PageBody>
          <TrackDetailBody track={content} />
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
};

export default TrackDetailPage;
