import { CONTENT_TYPES, LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import TrackPageContainer from 'scenes/TrackPage/TrackPageContainer';
import { ComposableTrackProvider } from 'tracks/context-providers/ComposableTrackContext';

const AssessmentDetail = () => {
  const [fetchAssessment, { data: assessment, status, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    null,
    {
      schema: contentSchema,
    }
  );

  return (
    <ComposableTrackProvider track={assessment} trackType={CONTENT_TYPES.assessment}>
      <TrackPageContainer
        track={assessment}
        fetchTrack={fetchAssessment}
        status={status}
        statusCode={statusCode}
        entityType={LEARNING_TYPES.assessments}
      />
    </ComposableTrackProvider>
  );
};

export default AssessmentDetail;
