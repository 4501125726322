import React from 'react';
import styled from 'styled-components';

import BackgroundPanel from 'shared/components/BackgroundPanel';
import MediaPoint from 'shared/components/MediaPoint';

import { menuAreaWidth } from './constants';
import Container from './Container';

const MenuArea = styled.div`
  position: absolute;
  width: ${menuAreaWidth};
  height: 100%;
`;

const OuterContainer = styled.div`
  background-color: #f2f2f2;
  height: 100%;
  padding: 0;
  z-index: 0;
  position: relative;

  ${MediaPoint.MobileLg} {
    padding: 0 20px;
  }
`;

const StyledBackgroundPanel = styled(BackgroundPanel)`
  left: -20px;
  width: calc(100% + 20px);
`;

export interface BaseContainerProps {
  Menu?: React.ReactElement;
  backgroundPanel?: boolean;
  full?: boolean;
  className?: string;
}

const BaseContainer = (props: React.PropsWithChildren<BaseContainerProps>) => {
  const { children, Menu, className, backgroundPanel = false } = props;
  return (
    <OuterContainer className={className}>
      {backgroundPanel && <StyledBackgroundPanel />}
      {Menu && <MenuArea>{Menu}</MenuArea>}
      <Container {...props}>{children}</Container>
    </OuterContainer>
  );
};

export default BaseContainer;
