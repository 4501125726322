import { mergeQueryKeys, inferQueryKeyStore } from '@lukemorales/query-key-factory';

import { assessments } from 'data-fetching/queries/assessments';
import { assignments } from 'data-fetching/queries/assignments';
import { bulk_user_loads } from 'data-fetching/queries/bulk_user_loads';
import { catalog } from 'data-fetching/queries/catalog';
import { groups } from 'data-fetching/queries/groups';
import { linkedcontents } from 'data-fetching/queries/linkedcontents';
import { locations } from 'data-fetching/queries/locations';
import { logs } from 'data-fetching/queries/logs';
import { mentorship_queries } from 'data-fetching/queries/mentorship';
import { old_users } from 'data-fetching/queries/old_users';
import { programs } from 'data-fetching/queries/programs';
import { tags } from 'data-fetching/queries/tags';
import { track_items } from 'data-fetching/queries/track_items';
import { tracks } from 'data-fetching/queries/tracks';
import { user_loads } from 'data-fetching/queries/user_loads';
import { articles } from 'features/articles/api/queries';
import { badges } from 'features/badges/api/queries';
import { channels } from 'features/channels/api/queries';
import { codelabs } from 'features/codelabs/api/queries';
import { content_items } from 'features/contentitems/api/queries';
import { courses } from 'features/courses/api/queries';
import { event_types } from 'features/event_types/api/queries';
import { events } from 'features/events/api/queries';
import { my_upcoming } from 'features/my_upcoming/api/queries';
import { segments } from 'features/segments/api/queries';
import { surveys } from 'features/surveys/api/queries';
import { tasks } from 'features/tasks/api/queries';
import { users } from 'features/users/api/queries';
import { videos } from 'features/videos/api/queries';

// Add all queries from various domains to this global list

export const queries = mergeQueryKeys(
  articles,
  assessments,
  assignments,
  badges,
  bulk_user_loads,
  codelabs,
  content_items,
  courses,
  event_types,
  events,
  groups,
  linkedcontents,
  locations,
  logs,
  mentorship_queries,
  my_upcoming,
  old_users,
  programs,
  surveys,
  tags,
  tasks,
  track_items,
  tracks,
  user_loads,
  users,
  videos,
  catalog,
  segments,
  channels
);

export type QueryKeys = inferQueryKeyStore<typeof queries>;
