import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { ADD_CHANNEL_CONTENT_PERMISSION } from 'app/shared/permissions';
import * as constants from 'catalog/constants';
import { CHANNEL } from 'catalog/constants';
import { parseResources } from 'content-resources/services';
import actions from 'entities/actions';
import { videoSchema } from 'entities/schema';
import { programSelector } from 'entities/selectors';
import { useEntities } from 'entities/utils';
import ChannelSelectField from 'inputs/components/ChannelSelectField';
import CoverImageField from 'inputs/components/CoverImageField';
import { validateVideoDuration } from 'inputs/components/DurationField/helpers';
import FacilitatorField from 'inputs/components/FacilitatorField';
import FieldWarning from 'inputs/components/FieldWarning';
import InlineItemField from 'inputs/components/InlineItemField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TagTypesInputFields from 'inputs/components/TagTypesInputFields';
import TextField from 'inputs/components/TextField';
import VideoAutocompletionField from 'inputs/components/VideoAutocompletionField';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { isEmbeddedVideoUrl, extractEmbedUrl } from 'services/embed';
import { mapRoute } from 'services/requests';
import { useSettingsSectionsList } from 'settings/hooks';
import { prepareSettingsValuesForSubmission } from 'settings/services';
import ButtonLink from 'shared/components/ButtonLink';
import { FormFieldGroup } from 'shared/components/Form';
import HR from 'shared/components/HR';
import Icon from 'shared/components/Icon';
import InfoBox from 'shared/components/InfoBox';
import InfoText from 'shared/components/InfoText';
import NewContentForm from 'shared/components/NewContentForm';
import CompletionSection from 'shared/components/NewContentForm/CompletionSection';
import AccessControlSection from 'shared/components/NewContentForm/sections/AccessControlSection';
import ResourcesSection from 'shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from 'shared/components/NewContentForm/sections/SurveysSection';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { STATUS_DONE } from 'shared/constants';
import {
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useToggles,
  useHasChannelPermission,
} from 'shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from 'topics/services';
import { concat, get, isNil, map, pick, toLower, isEmpty, some } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';
import VideoPreview from 'video/components/VideoPreview';

import VideoEmbedDocsModal from './VideoEmbedDocsModal';

const DurationFieldContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VideoPreviewContainer = styled.div`
  display: flex;
  flow-direction: row;
  column-gap: 16px;
`;

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

export const VideoForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  selectedOfficeHour,
  isModalForm,
  currentUser,
}) => {
  const videoName = useFormSelector(form, 'name');
  const videoLink = useFormSelector(form, 'link');
  const videoDuration = useFormSelector(form, 'duration');
  const videoId = useFormSelector(form, 'id');

  useFormPreventTransition(form);

  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();
  const { label_track_plural: labelTrackPlural, label_channel: labelChannel } = useLabels();

  const labelVideo = 'Video';
  const labelVideoPlural = 'Videos';

  const isEmbeddedVideoFile = isEmbeddedVideoUrl(videoLink, currentUser.allowed_video_url_pattern);

  const infoPanelText = `${labelVideoPlural} allow you to serve video content from a variety of streaming sources. They can be added to ${toLower(
    labelTrackPlural
  )} and individually assigned to people.`;

  const canAddChannelContent = useHasChannelPermission(ADD_CHANNEL_CONTENT_PERMISSION);
  const channelField = {
    id: 'channel',
    label: labelChannel,
    icon: CHANNEL.icon,
    section: <ChannelSelectField />,
    sectionProps: {
      defaultOpen: true,
    },
  };

  const [settingsSectionsList] = useSettingsSectionsList('video/form', form);

  const [showVideoEmbedDocsModal, setShowVideoEmbedDocsModal] = useState(false);
  const [videosWithSameURL, setVideosWithSameURL] = useState({});

  const handleVideoDurationUpdate = (duration) => {
    if (isEmbeddedVideoFile || !duration) return;

    // We only want to update the duration when the user manually edits the video URL field
    // This avoids unintended updates when opening the edit page
    if (videoDuration) return;
    change('duration', moment.utc(duration * 1000).format('HH:mm:ss'));
  };

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const [fetchVideo, { data }] = useEntities(
    actions.video.retrieveList,
    ({ status }) => {
      if (status === STATUS_DONE) {
        setVideosWithSameURL(data?.results);
      }
    },
    {
      schema: [videoSchema],
    }
  );

  useEffect(() => {
    if (videoLink) {
      fetchVideo(
        {
          action: 'check_video_link',
          link: videoLink,
        },
        { skipSchema: true }
      );

      if (isEmbeddedVideoFile) {
        change('fraction_watched_for_completion', 0);
      }
    }
  }, [videoLink]);

  const sectionsList = [
    {
      id: 'inline',
      label: 'Inline Item',
      icon: 'inline',
      section: <InlineItemField />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...(canAddChannelContent ? [channelField] : []),
    {
      id: 'video-details',
      label: `${labelVideo} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelVideo)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="video_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            {videoId && (
              <InfoText
                top={4}
                bottom={4}
                fontSize={12}
                content="When updating this URL, please make sure it represents the same content, for accurate assignment tracking."
              />
            )}
            <Field
              name="link"
              label={`${labelVideo} URL`}
              required
              component={TextField}
              normalize={(value) => extractEmbedUrl(value)}
              validate={[validateRequired]}
              type="text"
              warning={
                videosWithSameURL.length > 0 &&
                (!videoId || (videoId && some(videosWithSameURL, ({ id }) => id !== videoId))) && (
                  <InfoBox
                    margin="8px 0"
                    type="warning"
                    content={`Another ${toLower(labelVideo)} with the same URL already exists.`}
                    route={mapRoute('standAloneVideoDetail', {
                      public_id_and_slug: videosWithSameURL[0].public_id_and_slug,
                    })}
                    actionText={`Go to ${labelVideo}`}
                    target="_b"
                  />
                )
              }
            />
            {showVideoEmbedDocsModal && (
              <VideoEmbedDocsModal handleClose={() => setShowVideoEmbedDocsModal(false)} />
            )}
            <VideoPreviewContainer>
              <VideoPreview
                videoSource={videoLink}
                onVideoDurationUpdate={handleVideoDurationUpdate}
                isEmbeddedVideoFile={isEmbeddedVideoFile}
              />
              <InfoText
                top={4}
                bottom={16}
                content={
                  <>
                    Supported sources: HTML5-compatible video, YouTube, Google Drive, Vimeo, Wistia,
                    Vidyard, Panopto, Descript, Microsoft Stream, Loom, and others. &nbsp;
                    <ButtonLink
                      size="medium"
                      target="_blank"
                      icon="support"
                      iconPlacement="left"
                      onClick={() => setShowVideoEmbedDocsModal(true)}
                    >
                      <Text size="h5">How to Embed {labelVideoPlural}</Text>
                    </ButtonLink>
                  </>
                }
              />
            </VideoPreviewContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <DurationFieldContainer>
              <Field
                name="duration"
                label="Duration (HH:MM:SS)"
                component={TextField}
                validate={[validateVideoDuration]}
              />
            </DurationFieldContainer>
          </FormFieldGroup>

          <Fields
            useNewFormFieldLabel
            names={[
              'topics',
              'tags',
              ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_videos']),
            ]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_videos']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: <FacilitatorField label="Curator(s)" />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: `Completion`,
      icon: 'clock',
      section: (
        <>
          <CompletionSection form={form} labelContentType={labelVideo} change={change} />
          <HR color={colors.neutral200} margin="20px -20px" />
          <FormFieldGroup>
            {isEmbeddedVideoFile && (
              <FieldWarning
                warning={
                  <>
                    <Icon name="exclamation" width={12} height={12} color={colors.alert400} />
                    &nbsp; This video source does not support automatic completion tracking.
                  </>
                }
                meta={{}}
              />
            )}
            {!isEmbeddedVideoFile && (
              <Field
                name="fraction_watched_for_completion"
                props={{ showSlider: true, showToggle: false }}
                component={VideoAutocompletionField}
                infoText={`Enables ${toLower(
                  labelVideo
                )} to complete automatically after the person watches the minimum percentage to Auto-Completion. Set this value to 0 to disable Auto-Completion.`}
              />
            )}
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'resources',
      label: `Resources`,
      icon: 'teach',
      section: <ResourcesSection formName={form} selectedOfficeHour={selectedOfficeHour} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={constants.CONTENT_TYPES.video}
          formName={form}
          assignmentsCount={initialValues.assignments_count}
          isEditing={isEdit}
        />
      ),
      sectionProps: {
        defaultOpen:
          !isEmpty(initialValues.survey_relationships) ||
          !isEmpty(initialValues.external_survey_link),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={labelVideo}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  return (
    <NewContentForm
      contentNameSingular={labelVideo}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL="https://help.plusplus.app/en/articles/5518622-manage-video-content"
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={videoName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={sectionsList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={isModalForm}
    />
  );
};

VideoForm.defaultProps = {
  initialValues: {},
};

VideoForm.propTypes = {
  form: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isModalForm: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,

  selectedOfficeHour: PropTypes.object,
  currentUser: PropTypes.object,
};

const ConnectedVideoForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { isEdit, formName, currentUser, initialValues = {} }) => {
    const actionName = actions.video[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, (values) => {
      const payload = {
        ...pick(values, [
          'content_body',
          'cover',
          'duration',
          'external_survey_link',
          'facilitators_ids',
          'fraction_watched_for_completion',
          'groups_ids',
          'hide_attendees',
          'is_hidden',
          'is_inline',
          'link',
          'name',
          'office_hour_id',
          'resources',
          'survey_relationships',
          'time_to_complete',
          'time_to_retake',
          'topics',
          'channel_id',
        ]),
      };

      if (!payload.channel_id) {
        delete payload.channel_id;
      }

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_videos'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const tags = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

      // Forced as true until we remove the old forms and remove this field from the video model
      payload.is_completable = true;
      payload.tags = concat(tags, customTopicsList);
      payload.facilitators = map(payload.facilitators_ids, (id) => ({
        user_id: id,
        role: constants.FACILITATOR_ROLES.maintainer,
      }));

      if (isEmbeddedVideoUrl(payload.link, currentUser.allowed_video_url_pattern)) {
        payload.duration = moment.duration(payload.duration).asSeconds();
      }

      // Surveys
      let { survey_relationships: surveyRelationships } = payload;
      const { external_survey_link: surveyLink } = payload;

      surveyRelationships = map(surveyRelationships, (sr) => {
        const surveyRelationship = pick(sr, [
          'survey_id',
          'survey_cutoff_period_value',
          'survey_cutoff_period_option',
          'survey_schedule_period_value',
          'survey_schedule_period_option',
          'survey_schedule_kind',
        ]);

        if (isEdit) surveyRelationship.id = sr.id;

        return surveyRelationship;
      });

      payload.external_survey_link = surveyLink;
      payload.survey_relationships = surveyRelationships;

      // Resources Links
      payload.resources = parseResources(payload.resources, isEdit);

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(VideoForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);
  return {
    selectedOfficeHour,
    currentUser: get(state, 'user.currentUser'),
  };
};

export default connect(mapStateToProps)(ConnectedVideoForm);
