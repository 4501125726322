// In a separate file from other hooks to prevent circular import
import { useEffect } from 'react';

import { STATUS_DONE } from 'app/shared/constants';
import actions from 'entities/actions';
import { useEntities } from 'entities/utils';
import { useRouterQueryUtils } from 'shared/hooks';
import { map } from 'vendor/lodash';

import { useComposableTrackContext } from './context-providers/ComposableTrackContext';
import { TrackItem, Track, FullTrackSection } from './interfaces';

export const useBreadcrumbs = (
  track: Track,
  item?: TrackItem,
  section?: FullTrackSection
): {
  label: string;
  link?: string;
}[] => {
  const { getUrlWithQueryString, getUrlWithoutQueryString } = useRouterQueryUtils();

  const defaultBreadcrumbs = [];

  const [
    fetchBreadcrumbs,
    {
      status,
      data: { breadcrumbs },
    },
  ] = useEntities(actions.track.retrieveBreadcrumbs, null);

  const { getTrackSectionIndex, getTrackItemIndex } = useComposableTrackContext();

  useEffect(() => {
    if (item) {
      fetchBreadcrumbs(track.public_id, {
        item: item.content_item.public_id,
        direct_parent: item.track_id,
      });
    } else if (section) {
      fetchBreadcrumbs(track.public_id, {
        section: section.id,
        direct_parent: section.track_id,
      });
    } else {
      fetchBreadcrumbs(track.public_id);
    }
  }, [track.public_id, item?.content_item.public_id, section?.id]);

  if (status !== STATUS_DONE) {
    return defaultBreadcrumbs;
  }

  const getBreadcrumbUrl = (crumb) => {
    if (crumb.type === 'section') {
      return getUrlWithQueryString({
        page: getTrackSectionIndex(crumb.parent_track ?? track.public_id, crumb.public_id) + 1,
      });
    }
    if (crumb.type === 'item') {
      return getUrlWithQueryString({
        page: getTrackItemIndex(crumb.parent_track ?? track.public_id, crumb.public_id) + 1,
      });
    }
    if (crumb.type === 'track') {
      return getUrlWithoutQueryString(['page']);
    }

    return '#';
  };

  const mappedBreadcrumbs = map(breadcrumbs, (crumb) => ({
    label: crumb.name,
    image: crumb.cover && { src: crumb.cover, alt: crumb.name },
    link: getBreadcrumbUrl(crumb),
  }));

  return [...defaultBreadcrumbs, ...mappedBreadcrumbs];
};
