import { useState } from 'react';

import TextInput from 'app/inputs/components/TextInput';
import colors from 'services/colors';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { Rating, Stack, Tooltip, Typography } from 'vendor/mui';
import { StarIcon } from 'vendor/mui-icons';

interface Props {
  handleNext: (data) => void;
  handleClose?: () => void;
}
const TrackItemRateAndCompleteModal = ({ handleNext, handleClose }: Props) => {
  const [rating, setRating] = useState<number | null>(0);
  const [feedback, setFeedback] = useState<string | null>(null);

  const handleSubmit = () => {
    const payload = rating ? { rating, feedback } : {};
    handleNext(payload);
  };

  return (
    <Modal
      handleClose={handleClose}
      title="Rate Content"
      width={window.innerWidth > 600 ? 600 : null}
    >
      <ModalBody>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="h4" fontSize={16} fontWeight={500} align="center">
            This content will be Marked as Completed
          </Typography>
          <Typography variant="h5" fontSize={14} color={colors.neutral500} align="center">
            You can always skip this next step, switch to a different <br /> content from the
            navigation on the left, and come back later.
          </Typography>
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            sx={{ border: `1px solid ${colors.neutral200}`, borderRadius: 1, p: 2, width: '100%' }}
          >
            <Typography variant="h4" fontSize={14} fontWeight={500} align="center">
              The Author requested your rating
            </Typography>

            <Rating
              size="large"
              value={rating}
              onChange={(_, newValue) => setRating(newValue)}
              emptyIcon={<StarIcon style={{ opacity: 0.35 }} fontSize="inherit" />}
            />

            <TextInput
              multiline
              label="Please leave a review"
              placeholder="How was your experience with this content?"
              rows={2}
              inputProps={{}}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Stack>
        </Stack>
      </ModalBody>

      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton onClick={handleClose} color="primary">
          Cancel
        </ModalFooterButton>
        <Tooltip title={!rating && feedback ? 'You cannot provide a review without a rating' : ''}>
          <span>
            <ModalFooterButton
              variant="contained"
              onClick={handleSubmit}
              disabled={Boolean(!rating && feedback)}
            >
              Next
            </ModalFooterButton>
          </span>
        </Tooltip>
      </ModalFooter>
    </Modal>
  );
};

export default TrackItemRateAndCompleteModal;
