import styled from 'styled-components';

import { LEARNING_TYPES } from 'catalog/constants';
import { useContentFeedback, useContentPeople } from 'catalog/hooks';
import { useLatestContentItemAssignment } from 'common/hooks/useLatestContentItemAssignment';
import BaseContainer from 'navigation/components/BaseContainer';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import ContentItemAdditional from 'shared-content-item/components/ContentItemAdditional';
import ContentItemBody from 'shared-content-item/components/ContentItemBody';
import ContentItemHeader from 'shared-content-item/components/ContentItemHeader';
import DescriptionContent from 'shared-content-item/components/DescriptionContent';
import PrimaryActionButton from 'shared-content-item/components/PrimaryActionButton';
import { ContentItem } from 'shared-content-item/interfaces';
import BackgroundPanel from 'shared/components/BackgroundPanel';
import { PageBody, PageContainer } from 'shared/components/DefaultPage';
import Text from 'shared/components/Text';
import { STATUS_DONE } from 'shared/constants';
import { useIsPreviewQueryParam } from 'shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from 'stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import TaskContextMenu from 'task/components/TaskContextMenu';
import { Track } from 'tracks/interfaces';
import { get, isNil } from 'vendor/lodash';

const ContentItemBackgroundPanel = styled(BackgroundPanel)`
  left: 0;
  top: -40px;
`;

interface TaskDetailProps {
  content: ContentItem;
  track?: Track;
  isRequired?: boolean;
}

const TaskDetail = ({ content: baseContent, track, isRequired = true }: TaskDetailProps) => {
  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssigment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssigment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { assignmentsCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.tasks
  );

  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [showPeople],
  });

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const isTrackItem = !isNil(track);
  const isPreviewMode = useIsPreviewQueryParam();

  if (!isTrackItem) return null;

  return (
    <BaseContainer>
      <ContentItemBackgroundPanel height="120px" />
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          maintainers={maintainers}
          contextMenu={
            <TaskContextMenu content={content} contextMenuExtraProps={{ isTrackItem }} />
          }
          primaryActionButton={PrimaryActionButton}
          clickableName={false}
          isRequired={isRequired}
          isPreviewMode={isPreviewMode}
        />
        <PageBody>
          <ContentItemBody>
            <DescriptionContent content={content} />
            <ContentItemAdditional>
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.tasks}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={assignmentsCount}
                  engagedPeopleHeading={
                    <Text>
                      {assignmentsCount} {assignmentsCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode={feedbackContainerMode}
                  isLoading={isLoadingFeedback}
                />
              )}
            </ContentItemAdditional>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </BaseContainer>
  );
};

export default TaskDetail;
