import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Button from 'app/shared/components/Button';
import { Assessment } from 'assessments/interfaces';
import { ASSIGNMENT_REASONS, ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import { canBeDropped } from 'assignments/services';
import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import RetryAssessmentModal from 'features/assessments/components/RetryAssessmentModal';
import colors from 'services/colors';
import { ContentItem } from 'shared-content-item/interfaces';
import ButtonLink from 'shared/components/ButtonLink';
import Icon from 'shared/components/Icon';
import Pill from 'shared/components/Pill';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import { User } from 'shared/components/types';
import { STATUS_DONE } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import ContentRating from 'stand-alone-shared/components/ContentRating';
import ScheduledTrackEnrollModal from 'tracks/components/ScheduledTrackEnrollModal';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { Track } from 'tracks/interfaces';
import { get, map, includes, findIndex, size, some } from 'vendor/lodash';
import { styled, Box } from 'vendor/mui';
import {
  OpenInNewOutlinedIcon,
  PlayCircleOutlinedIcon,
  RedoOutlined,
  StartOutlined,
  VisibilityIcon,
} from 'vendor/mui-icons';

const ContainerTrack = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const hasSpot = (content: ContentItem): boolean => {
  const assignmentsCount = get(content, 'assignments_count', 0);
  // The scheduled track detail endpoint returns 'total_assignments' instead of 'assignments_count'
  const totalAssignmentsCount = get(content, 'total_assignments', 0);
  const numberOfAssignments = assignmentsCount || totalAssignmentsCount;

  const capacity = get(content, 'capacity', 0);
  return capacity === 0 || capacity - numberOfAssignments > 0;
};

const canStart = (content: ContentItem, currentUser: User): boolean => {
  const facilitatorsIds = map(content.facilitators, (facilitator) => facilitator.user_id);
  return (
    content.access_level === 'public' ||
    currentUser.role === 'admin' ||
    currentUser.role === 'organizer' ||
    includes(facilitatorsIds, currentUser.id) ||
    includes(content.permissions, 'group_access') ||
    includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign)
  );
};

const getStartProps = (isScheduledTrack: boolean, isAssessment: boolean) => {
  if (isScheduledTrack)
    return {
      startLabel: 'Enroll',
      startIcon: <Icon color={colors.action600} height={16} width={16} name="world" />,
    };

  if (isAssessment)
    return {
      startLabel: 'Launch',
      startIcon: <OpenInNewOutlinedIcon />,
    };

  // Default: Track
  return {
    startLabel: 'Start',
    startIcon: <StartOutlined />,
  };
};

interface PrimaryActionButtonProps {
  content: ContentItem;
  contentType?: string;
  refreshContent?: CallableFunction;
}

const PrimaryActionButton = ({
  content,
  contentType: learningType,
  refreshContent,
}: PrimaryActionButtonProps) => {
  const [isRatingContent, setRatingContent] = useState(false);
  const [showScheduledTrackEnrollModal, setShowScheduledTrackEnrollModal] = useState(false);
  const [showRetryAssessmentModal, setShowRetryAssessmentModal] = useState(false);
  const [redirectAfterCreate, setRedirectAfterCreate] = useState(false);

  const history = useHistory();
  const currentUser = useCurrentUser();

  const { create, createStatus, rate, isLoading } = useAssignmentsActionsContext();
  const { parentTrack, getTrackItemIndex, onRefreshData } = useComposableTrackContext();
  const contentTypeRoutes = useContentTypeRoutes();

  const { content_type: contentType } = content;
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignment = content?.user_assignment || content?.assignment;
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);
  const isScheduledTrack = contentType === CONTENT_TYPES.scheduled_track;
  const isAssessment = contentType === CONTENT_TYPES.assessment;

  const getResumeTrackPosition = useCallback(
    (track: Track): number => {
      const { track_items: trackItems } = track;
      const index = findIndex(
        trackItems,
        ({ content_item: contentItem }) =>
          get(contentItem, 'assignment.state', get(contentItem, 'user_assignment.state')) !==
          ASSIGNMENT_STATES.completed
      );
      return (
        getTrackItemIndex(
          track.public_id,
          get(get(trackItems, index === -1 ? 0 : index, {}), 'content_item.public_id', '')
        ) + 1
      );
    },
    [getTrackItemIndex]
  );

  const goToPage = useCallback(
    (targetPage: number, preview = false) => {
      const { content_type: contentType, public_id_and_slug: publicIdAndSlug } = parentTrack;
      const url = contentTypeRoutes[contentType].details({
        public_id_and_slug: publicIdAndSlug,
      });

      if (preview) {
        history.push(`${url}?page=${targetPage}&preview=${preview}`);
        return;
      }

      history.push(`${url}?page=${targetPage}`);
    },
    [history, parentTrack, contentTypeRoutes]
  );

  const handleRedirectAfterCreate = useCallback(() => {
    // It is not possible to check only by createStatus because other flows may call the same
    // request or use the same hook. For example, the "Save" button for assessments.
    if (redirectAfterCreate && createStatus === STATUS_DONE) {
      refreshContent?.();
      goToPage(getResumeTrackPosition(content));
      setRedirectAfterCreate(false);
      onRefreshData();
    }
  }, [
    content,
    createStatus,
    redirectAfterCreate,
    goToPage,
    getResumeTrackPosition,
    refreshContent,
    onRefreshData,
  ]);

  useEffect(handleRedirectAfterCreate, [handleRedirectAfterCreate]);

  const { startLabel, startIcon } = getStartProps(isScheduledTrack, isAssessment);

  const assignmentState = get(assignment, 'state');

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    const hasRetriesEnabled = (content as Assessment)?.max_attempts !== 1;
    const hasAttemptsLeft = get(content as Assessment, 'remaining_attempts', 0) > 0;
    const canAssign = includes(content.permissions, CONTENT_ITEM_PERMISSIONS.assign);
    const canRetry = hasRetriesEnabled && canAssign && hasAttemptsLeft;

    return (
      <>
        <Box display="flex" alignItems="center" gap="12px">
          {currentRating > 0 && (
            <Box display="flex" alignItems="center" gap="8px">
              <Rating value={currentRating} readOnly />

              <ButtonLink onClick={() => setRatingContent(true)} variant="primary">
                Edit
              </ButtonLink>
            </Box>
          )}

          {currentRating === 0 && (
            <RateButton size="medium" onClick={() => setRatingContent(true)}>
              Rate
            </RateButton>
          )}

          {isAssessment && (
            <>
              {canRetry && (
                <Button
                  startIcon={<RedoOutlined />}
                  onClick={() => setShowRetryAssessmentModal(true)}
                  disabled={isLoading}
                >
                  Retake
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<VisibilityIcon />}
                onClick={() => goToPage(getResumeTrackPosition(content))}
                disabled={isLoading}
              >
                Review
              </Button>
            </>
          )}
        </Box>
        {isRatingContent && (
          <ContentRating
            contentType={learningType}
            content={content}
            onSubmitFunc={rate}
            handleClose={() => setRatingContent(false)}
          />
        )}
        {showRetryAssessmentModal && (
          <RetryAssessmentModal
            assessment={content as Assessment}
            handleClose={() => setShowRetryAssessmentModal(false)}
            handleConfirm={() => {
              create({ is_reassign: true, reason: ASSIGNMENT_REASONS.assignment_retry });
              setRedirectAfterCreate(true);
            }}
          />
        )}
      </>
    );
  }

  if (canBeDropped(assignment)) {
    return (
      <Button
        variant="contained"
        startIcon={<PlayCircleOutlinedIcon />}
        onClick={() => goToPage(getResumeTrackPosition(content))}
        disabled={isLoading}
      >
        Resume
      </Button>
    );
  }

  if (isScheduledTrack && !hasSpot(content)) {
    return (
      <ContainerTrack>
        <Pill variant="lightGray" label="No spots left" />
      </ContainerTrack>
    );
  }

  if (canStart(content, currentUser)) {
    const disabled = !size(content.track_items) || isLoading;
    const hasEvents = some(content.track_items, ['content_type', CONTENT_TYPES.event]);
    return (
      <>
        <Button
          variant="contained"
          startIcon={startIcon}
          onClick={() => {
            if (isScheduledTrack && hasEvents) {
              setShowScheduledTrackEnrollModal(true);
              return;
            }
            create();
            setRedirectAfterCreate(true);
          }}
          disabled={disabled}
        >
          {startLabel}
        </Button>
        {showScheduledTrackEnrollModal && (
          <ScheduledTrackEnrollModal
            content={content}
            handleClose={() => setShowScheduledTrackEnrollModal(false)}
            handleAssign={({ eventEnrollmentOptions }) => {
              create({ event_enrollment_options: eventEnrollmentOptions });
              setRedirectAfterCreate(true);
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default PrimaryActionButton;
