import { useEffect, useState } from 'react';

import { ASSESSMENT_FEEDBACK_POLICY } from 'app/assessments/constants';
import { Assessment } from 'app/assessments/interfaces';
import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { CONTENT_TYPES } from 'catalog/constants';
import colors from 'services/colors';
import Text from 'shared/components/Text';
import { useCurrentUser, useIsPreviewQueryParam } from 'shared/hooks';
import TrackItemCard from 'tracks/components/TrackItemCard';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { Track } from 'tracks/interfaces.js';
import { getTrackItemsWithHumanizedDate } from 'tracks/services';
import { isNil, isEmpty, map, isEqual, includes } from 'vendor/lodash';
import { Typography, Avatar, Box, Button, Card, CardHeader } from 'vendor/mui';
import { ArrowBack } from 'vendor/mui-icons';

import TrackConsumptionSectionList from './TrackConsumptionSectionList';

interface ParentTrackCardProps {
  rootTrack: Track;
  currentTrack: Track;
  currentTrackIndex: number;
  onItemClick?: (index: number) => void;
}

const ParentTrackCard = ({
  rootTrack,
  currentTrack,
  currentTrackIndex,
  onItemClick,
}: ParentTrackCardProps) => {
  if (isEqual(rootTrack, currentTrack)) return null;

  return (
    <Card>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        fullWidth
        sx={{ justifyContent: 'start', backgroundColor: colors.neutral50 }}
        onClick={onItemClick && (() => onItemClick(currentTrackIndex))}
      >
        Back
      </Button>
      <CardHeader
        title={<Typography>{currentTrack.name}</Typography>}
        avatar={
          currentTrack.cover ? (
            <Avatar
              alt={currentTrack.name}
              src={currentTrack.cover}
              variant="rounded"
              sx={{ height: 26, width: 36 }}
            />
          ) : null
        }
        sx={{ paddingY: 1 }}
      />
    </Card>
  );
};

function isTrackAssessment(track: Track): track is Assessment {
  return track.content_type === CONTENT_TYPES.assessment;
}

function getShouldShowAssignmentState<T extends Track>(track: T) {
  if (!isTrackAssessment(track)) return true;

  const assignment = track.user_assignment;
  return (
    track.feedback_policy === ASSESSMENT_FEEDBACK_POLICY.after_question ||
    assignment?.state === ASSIGNMENT_STATES.completed
  );
}

interface TrackConsumptionItemListProps {
  rootTrack: Track;
  selectedIndex: number;
  onItemClick?: (index: number) => void;
  refreshContent: CallableFunction;
}

const TrackConsumptionItemList = ({
  rootTrack,
  selectedIndex,
  onItemClick,
  refreshContent,
}: TrackConsumptionItemListProps) => {
  const currentUser = useCurrentUser();

  const {
    filterTrackItems,
    filterTrackSections,
    getTrackItemIndex,
    getTrackSectionIndex,
    getSectionOrItemByIndex,
    getTrackByPublicId,
  } = useComposableTrackContext();

  const [currentTrack, setCurrentTrack] = useState<Track>(rootTrack);
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(-1);

  const sectionsWithItems = filterTrackSections(currentTrack);
  const trackItems = getTrackItemsWithHumanizedDate(
    filterTrackItems(currentTrack) || [],
    currentUser
  );

  const isPreviewMode = useIsPreviewQueryParam();

  useEffect(() => {
    const selectedItem = getSectionOrItemByIndex(selectedIndex);

    if (selectedItem?.track_id === undefined || selectedItem.track_id === rootTrack.public_id) {
      setCurrentTrack(rootTrack);
      return;
    }

    const [selectedParentTrack, selectedParentTrackIndex] = getTrackByPublicId(
      selectedItem.track_id
    );

    // selectedParentTrackIndex can be -1 if not found
    if (!selectedParentTrack || selectedParentTrackIndex < 0) {
      setCurrentTrack(rootTrack);
      return;
    }

    setCurrentTrack(selectedParentTrack);
    setCurrentTrackIndex(selectedParentTrackIndex);
  }, [selectedIndex, rootTrack, getSectionOrItemByIndex, getTrackByPublicId]);

  const shouldShowAssignmentState = getShouldShowAssignmentState(currentTrack);

  if (isEmpty(trackItems)) {
    return (
      <Box display="flex" alignItems="center" padding="8px 12px">
        <Text size="h4" color={colors.neutral600}>
          No content
        </Text>
      </Box>
    );
  }

  if (isEmpty(sectionsWithItems)) {
    return (
      <>
        <ParentTrackCard
          rootTrack={rootTrack}
          currentTrack={currentTrack}
          currentTrackIndex={currentTrackIndex}
          onItemClick={onItemClick}
        />
        <Box display="flex" flexDirection="column" gap={1} padding={1}>
          {map(trackItems, (item) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const index = getTrackItemIndex(item.track_id!, item.content_item.public_id);
            const isCheckListItem = !includes(
              [
                CONTENT_TYPES.question,
                CONTENT_TYPES.text_question,
                CONTENT_TYPES.multiple_choice_question,
              ],
              item.content_item.content_type
            );

            return (
              <TrackItemCard
                key={item.content_item.public_id}
                item={item}
                itemIndex={index}
                selected={selectedIndex === index}
                onClick={() => onItemClick?.(index)}
                isCheckListItem={isCheckListItem}
                showAssignmentState={shouldShowAssignmentState}
                refreshContent={refreshContent}
                isPreviewMode={isPreviewMode}
              />
            );
          })}
        </Box>
      </>
    );
  }

  return (
    <>
      <ParentTrackCard
        rootTrack={rootTrack}
        currentTrack={currentTrack}
        currentTrackIndex={currentTrackIndex}
        onItemClick={onItemClick}
      />
      <Box display="flex" flexDirection="column">
        {map(sectionsWithItems, (sectionWithItems, i) => (
          <TrackConsumptionSectionList
            key={sectionWithItems.id}
            section={sectionWithItems}
            selectedIndex={selectedIndex}
            getTrackItemIndex={getTrackItemIndex}
            getTrackSectionIndex={getTrackSectionIndex}
            onItemClick={onItemClick}
            userIsAssignedToTrack={!isNil(rootTrack?.assignment)}
            sectionCount={i + 1}
            showAssignmentState={shouldShowAssignmentState}
            refreshContent={refreshContent}
          />
        ))}
      </Box>
    </>
  );
};

export default TrackConsumptionItemList;
