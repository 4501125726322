import PropTypes from 'prop-types';
import React from 'react';

import TableDisplayModal from 'shared-modals/components/TableDisplayModal';

const CustomAttributesModal = ({ handleClose, peopleIntegrationData }) => {
  return (
    <TableDisplayModal
      title="Custom Attributes"
      handleClose={handleClose}
      tableInfoText="This person has the following custom attributes:"
      emptyDataMessage="This person has no custom attributes."
      data={peopleIntegrationData}
    />
  );
};

CustomAttributesModal.propTypes = {
  handleClose: PropTypes.func,
  peopleIntegrationData: PropTypes.array,
};

export default CustomAttributesModal;
