/* eslint-disable react/jsx-no-useless-fragment */

import colors from 'services/colors';
import { Box, Button, Stack, Tooltip } from 'vendor/mui';
import { ArrowForwardIcon } from 'vendor/mui-icons';

interface NavigationContainerProps {
  maxWidth?: string;
  isCurrentItemCompletable?: boolean;
  isFirstPage?: boolean;
  isLastPage?: boolean;
  isLastContentItem?: boolean;
  isFinished?: boolean;
  onPrevious: () => void;
  onAdvance: () => void;
}

const NavigationContainer = (props: NavigationContainerProps) => {
  const {
    maxWidth = '1440px',
    isCurrentItemCompletable = false,
    isFirstPage = false,
    isLastPage = false,
    isLastContentItem = false,
    isFinished = false,
    onPrevious,
    onAdvance,
  } = props;

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      padding="20px 48px 40px"
      sx={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)',
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%" maxWidth={maxWidth}>
        <Button
          variant="contained"
          size="large"
          onClick={onPrevious}
          disabled={isFirstPage}
          sx={{
            backgroundColor: colors.neutral0,
            color: colors.neutral0TextColor,
            '&:hover': {
              backgroundColor: colors.neutral100,
              color: colors.neutral100TextColor,
            },
          }}
        >
          Previous
        </Button>

        <Stack direction="row" spacing={1}>
          {!isLastPage && !isLastContentItem && (
            <>
              {!isCurrentItemCompletable ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={onAdvance}
                  sx={{
                    backgroundColor: colors.action700,
                    color: colors.action700TextColor,
                    '&:hover': {
                      backgroundColor: colors.action800,
                      color: colors.action800TextColor,
                    },
                  }}
                >
                  Next
                </Button>
              ) : (
                <Tooltip title="Complete this item and go to the next step. To skip for now and go to a different step, use the navigation on the left">
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onAdvance}
                    sx={{
                      backgroundColor: colors.action700,
                      color: colors.action700TextColor,
                      '&:hover': {
                        backgroundColor: colors.action800,
                        color: colors.action800TextColor,
                      },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Complete
                  </Button>
                </Tooltip>
              )}
            </>
          )}
          {isLastPage && !isLastContentItem && !isFinished && (
            <Tooltip title="The track can't be finished until all the items are completed. Please review non-completed items at the left menu">
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onAdvance}
                  disabled
                  endIcon={<ArrowForwardIcon />}
                >
                  Finish
                </Button>
              </Box>
            </Tooltip>
          )}
          {isLastContentItem && (
            <Button
              variant="contained"
              size="large"
              onClick={onAdvance}
              sx={{
                backgroundColor: colors.action700,
                color: colors.action700TextColor,
                '&:hover': {
                  backgroundColor: colors.action800,
                  color: colors.action800TextColor,
                },
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Finish
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default NavigationContainer;
