import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  CardMedia,
  Typography,
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  menuItemClasses,
  styled,
} from '@mui/material';
import { darken, emphasize, alpha } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { matchPath } from 'react-router';
import { useLocation, Link } from 'react-router-dom';

import { queries } from 'queries';
import { find, get, map } from 'vendor/lodash';

import { useChannelRoutes } from '../hooks/useChannelRoutes';

const ChannelMenuItem = styled(MenuItem)<
  MenuItemProps & { component: React.ElementType; to: string }
>`
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 64px;
  border-radius: 8px;

  &:hover,
  &:focus {
    color: unset;
  }
`;

function useGetCurrentChannelSlug() {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: '/channels/:slug',
  });

  if (!match) {
    return '';
  }

  const channelSlug = get(match, 'params.slug');

  if (!channelSlug) {
    return '';
  }

  return channelSlug;
}

export function ChannelSelector() {
  const currenChannelSlug = useGetCurrentChannelSlug();
  const { detail: detailRoute } = useChannelRoutes();

  const { data: channelsData } = useQuery({
    ...queries.channels.list('view_mode=lite'),
  });

  const channelOptions = get(channelsData, 'results', []);
  const currentChannel = find(channelOptions, { slug: currenChannelSlug });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="channel-selector-button"
        aria-controls={open ? 'channel-selector-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="inherit"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        disableRipple
        sx={{
          padding: '8px 11px',
          backgroundColor: currentChannel
            ? currentChannel.color
              ? currentChannel.color
              : 'neutral.light'
            : undefined,
          color: currentChannel?.color ? darken(currentChannel.color, 0.8) : alpha('#000000', 0.6),

          '&:hover': {
            backgroundColor: currentChannel?.color
              ? emphasize(currentChannel.color, 0.05)
              : undefined,
          },
        }}
      >
        {currentChannel ? currentChannel.name : 'Channels'}
      </Button>

      <Menu
        id="channel-selector-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: 450,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'channel-selector-button',
          sx: {
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
          },
        }}
      >
        {map(channelOptions, (channel) => (
          <ChannelMenuItem
            onClick={handleClose}
            disableRipple
            component={Link}
            to={detailRoute({ slug: channel.slug })}
            selected={channel.slug === currenChannelSlug}
            sx={{
              backgroundColor: channel.color ? `${channel.color} !important` : undefined,
              '&:hover': {
                boxShadow: 1,
                backgroundColor: channel.color ? `${channel.color} !important` : undefined,
              },
              [`&.${menuItemClasses.selected}`]: {
                border: '1px solid',
                borderColor: channel.color ? darken(channel.color, 0.2) : '#00000061',
                backgroundColor: channel.color ? `${channel.color} !important` : undefined,
              },
            }}
          >
            <CardMedia
              component="img"
              sx={{ width: 72, height: 44, objectFit: 'cover', borderRadius: 1 }}
              image={channel.cover}
              alt={channel.name}
            />
            <Typography variant="body1" component="span">
              {channel.name}
            </Typography>
          </ChannelMenuItem>
        ))}
      </Menu>
    </>
  );
}
