import { useCallback, useEffect } from 'react';

import { useAssignmentsActions } from 'assignments/hooks';
import FeedbackForm from 'shared-cards/components/FeedbackForm';
import Modal from 'shared/components/Modal';
import { STATUS_DONE, STATUS_NOT_REQUESTED } from 'shared/constants';
import { useLabels } from 'shared/hooks';
import RichTextDescription from 'stand-alone-shared/components/ContentDescription';
import { Track } from 'tracks/interfaces';
import { get, isNil, reduce, replace } from 'vendor/lodash';
import { styled } from 'vendor/mui';

const CongratulationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface TrackCompletionModal {
  track: Track;
  handleClose: () => void;
}

const TrackCompletionModal = ({ track, handleClose }: TrackCompletionModal) => {
  const { label_track: labelTrack } = useLabels();

  const { user_assignment: assignment } = track;

  const { rate, rateStatus, retrieve, retrieveStatus, acknowledgeCompletion, isLoading } =
    useAssignmentsActions(undefined, get(assignment, 'id'));

  const loadCompletion = useCallback(() => {
    if (isNil(get(assignment, 'completion')) && retrieveStatus === STATUS_NOT_REQUESTED) {
      retrieve();
    }
  }, [assignment, retrieve, retrieveStatus]);

  useEffect(() => {
    loadCompletion();
  }, [loadCompletion]);

  useEffect(() => {
    if (rateStatus === STATUS_DONE) {
      handleClose();
    }
  }, [rateStatus, handleClose]);

  const handleCloseAndAckCompletion = () => {
    handleClose();
    acknowledgeCompletion();
  };

  const initial = {
    id: track.id,
    feedback_rating: get(assignment, 'rating.rating', 5),
    feedback_text: get(assignment, 'rating.feedback', ''),
    user_id: get(assignment, 'user_id'),
  };

  const fieldLabels = {
    rating: 'The Author requested your Rating',
    feedback: 'Please leave a review',
  };

  const fieldPlaceholders = {
    feedback: 'How was your experience with this content?',
  };

  const replacementsMap = [
    ['contentName', track.name],
    ['labelTrack', labelTrack],
  ];

  const interpolatedCompletionDialogContent = reduce(
    replacementsMap,
    (acc, [replacementKey, replacementValue]) =>
      replace(acc, `{{ ${replacementKey} }}`, replacementValue),
    get(track, 'settings.track_completion_dialog_content.value.1')
  );

  return (
    <Modal title={`${labelTrack} Completed`} handleClose={handleCloseAndAckCompletion} width={500}>
      <CongratulationsContainer>
        <RichTextDescription description={interpolatedCompletionDialogContent} noPadding />
      </CongratulationsContainer>
      <FeedbackForm
        user={get(assignment, 'user')}
        initialValues={initial}
        onSubmitFunc={rate}
        fieldLabels={fieldLabels}
        fieldPlaceholders={fieldPlaceholders}
        handleDismiss={handleCloseAndAckCompletion}
        formMarginBottom={15}
        submitting={isLoading}
        feedbackIsPublic
      />
    </Modal>
  );
};

export default TrackCompletionModal;
