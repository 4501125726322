import { useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import { useContentTypeRoutes } from 'catalog/hooks';
import { ContentItemContextMenu } from 'content-items/components/interfaces';
import actions from 'entities/actions';
import { useArchiveRestore } from 'entities/utils';
import ContentItemLogtrailModal from 'scenes/ContentItemLogtrailModal';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import DropDownMenu from 'shared/components/DropDownMenu';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal/DeleteStandAloneModal';
import { includes } from 'vendor/lodash';

const LinkedContentContextMenu = ({ content, refreshContent }: ContentItemContextMenu) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);

  const contentTypeRoutes = useContentTypeRoutes({ withOriginRoute: true });
  const { archiveItem, restoreItem } = useArchiveRestore(actions.linkedContent, refreshContent);

  const isArchived = Boolean(content.archival);
  const itemId = content.id;

  const canManage = includes(content.permissions, CONTENT_ITEM_PERMISSIONS.manage);

  if (!canManage) {
    return null;
  }

  return (
    <>
      <DropDownMenu icon="elipsis" fontSize={20} buttonAriaLabel="Settings">
        <>
          <DropDownMenu.Item
            route={contentTypeRoutes[CONTENT_TYPES.linkedcontent].edit({
              public_id: content.public_id,
            })}
            title="Edit"
            icon="pencil"
          />
          {canManage && (
            <DropDownMenu.Item
              route={mapRoute('contentItemManageAssignments', {
                public_id_and_slug: content.public_id_and_slug,
              })}
              title="Manage Assignments"
              icon="group"
            />
          )}
          {!isArchived && (
            <DropDownMenu.Item onClick={() => archiveItem(itemId)} title="Archive" icon="archive" />
          )}
          {isArchived && (
            <>
              <DropDownMenu.Item
                onClick={() => restoreItem(itemId)}
                title="Restore"
                icon="unarchive"
              />
              <DropDownMenu.Item
                onClick={() => setShowDeleteModal(true)}
                title="Delete"
                icon="delete"
                color={colors.error600}
                textColor={colors.error600}
              />
            </>
          )}
          {canManage && (
            <DropDownMenu.Item onClick={() => setShowLogModal(true)} title="View Logs" icon="log" />
          )}
        </>
      </DropDownMenu>

      {/* Modals */}
      {showDeleteModal && (
        <DeleteStandAloneModal content={content} handleClose={() => setShowDeleteModal(false)} />
      )}
      {showLogModal && (
        <ContentItemLogtrailModal content={content} handleClose={() => setShowLogModal(false)} />
      )}
    </>
  );
};

export default LinkedContentContextMenu;
