import { useState } from 'react';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import ConfirmOpenLinkModal from 'linkedcontent/components/ConfirmOpenLinkModal/ConfirmOpenLinkModal';
import { COMPLETION_POLICY } from 'linkedcontent/constants';
import { LinkedContent } from 'linkedcontent/interfaces';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import { useCurrentUser, useLocalStorage } from 'shared/hooks';
import ContentRating from 'stand-alone-shared/components/ContentRating';
import { get, isNil } from 'vendor/lodash';
import { Stack } from 'vendor/mui';
import { CheckIcon } from 'vendor/mui-icons';

import OpenLinkButton from './OpenLinkButton';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

interface PrimaryActionButtonProps {
  content: LinkedContent;
  contentType?: string;
}

const PrimaryActionButton = ({ content, contentType: learningType }: PrimaryActionButtonProps) => {
  const [isRatingContent, setRatingContent] = useState(false);
  const { is_completable: isCompletable } = content;
  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);
  const { create, complete, rate, isLoading } = useAssignmentsActionsContext();
  const [showLinkInfoModal, setShowLinkInfoModal] = useState(false);
  const currentUser = useCurrentUser();
  const [skipInfoModal, setSkipInfoModal] = useLocalStorage(
    `skip-${content.completion_policy}-${currentUser.id}-${learningType}`
  );
  const infoModalContent =
    {
      [COMPLETION_POLICY.complete_when_opened]:
        'Opening this external link will mark it as complete.',
      [COMPLETION_POLICY.mark_completion]:
        'Remember to return to “Mark complete” after finishing this external content.',
    }[content.completion_policy] || '';

  const onMarkCompleteClick =
    isNil(assignment) || assignmentState === ASSIGNMENT_STATES.dropped
      ? () => create({ is_completed: true })
      : complete;

  const handleOpenLink = () => {
    if (
      content.completion_policy === COMPLETION_POLICY.complete_when_opened &&
      assignmentState !== ASSIGNMENT_STATES.completed &&
      isCompletable
    )
      onMarkCompleteClick();
    window.open(content.url, '_blank');
  };

  const handleLinkInfoModalOpen = () => {
    if (assignmentState === ASSIGNMENT_STATES.completed || !isCompletable) {
      handleOpenLink();
      return;
    }

    if (skipInfoModal) {
      handleOpenLink();
    } else {
      setShowLinkInfoModal(true);
    }
  };

  const handleLinkInfoModalClose = (dismiss) => {
    setSkipInfoModal(dismiss);
    setShowLinkInfoModal(false);
    handleOpenLink();
  };

  if (!isCompletable)
    return (
      <Container>
        <OpenLinkButton url={content.url} onClick={handleLinkInfoModalOpen} />
      </Container>
    );

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    return (
      <>
        <Container>
          {currentRating > 0 && (
            <>
              <RatingContainer>
                <Rating value={currentRating} readOnly />
              </RatingContainer>
              <ButtonLink
                onClick={() => setRatingContent(true)}
                variant="primary"
                disabled={isLoading}
              >
                Edit
              </ButtonLink>
            </>
          )}
          {currentRating <= 0 && (
            <RateButton size="medium" onClick={() => setRatingContent(true)} disabled={isLoading}>
              Rate
            </RateButton>
          )}
        </Container>

        <Stack direction="row" spacing={1} alignItems="center">
          {isRatingContent && (
            <ContentRating
              content={content}
              contentType={learningType}
              onSubmitFunc={rate}
              handleClose={() => setRatingContent(false)}
            />
          )}
          <OpenLinkButton url={content.url} onClick={handleLinkInfoModalOpen} />
        </Stack>
        {showLinkInfoModal && (
          <ConfirmOpenLinkModal
            handleConfirm={handleLinkInfoModalClose}
            handleClose={() => setShowLinkInfoModal(false)}
          >
            {infoModalContent}
          </ConfirmOpenLinkModal>
        )}
      </>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        {content.completion_policy === COMPLETION_POLICY.mark_completion && (
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => onMarkCompleteClick()}
            disabled={isLoading}
          >
            Mark Complete
          </Button>
        )}
        <OpenLinkButton url={content.url} onClick={handleLinkInfoModalOpen} />
      </Stack>
      {showLinkInfoModal && (
        <ConfirmOpenLinkModal
          handleConfirm={handleLinkInfoModalClose}
          handleClose={() => setShowLinkInfoModal(false)}
        >
          {infoModalContent}
        </ConfirmOpenLinkModal>
      )}
    </>
  );
};

export default PrimaryActionButton;
