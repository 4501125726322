import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'app/shared/components/Button';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import { canBeDropped } from 'assignments/services';
import { CONTENT_TYPES, DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME } from 'catalog/constants';
import { Course } from 'course/interfaces';
import { ContentItem } from 'shared-content-item/interfaces';
import ButtonLink from 'shared/components/ButtonLink';
import Datetime from 'shared/components/Datetime';
import Pill from 'shared/components/Pill';
import { STATUS_ERROR } from 'shared/constants';
import { useToggles, useIsPreviewQueryParam } from 'shared/hooks';
import DropTrackItemWarnModal from 'stand-alone-shared/components/DropTrackItemWarnModal';
import { includes, get, map, size, isEmpty } from 'vendor/lodash';
import { BookmarkBorderOutlined, HighlightOffIcon } from 'vendor/mui-icons';

import OldSecondaryActionButton from '../OldSecondaryActionButton';

const Container = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

const DateContainer = styled.div`
  flex: 1 0 auto;

  line-height: 18px;
`;

interface SecondaryActionButtonProps {
  content: ContentItem;
  contentType?: string;
}

const SecondaryActionButton = (props: SecondaryActionButtonProps) => {
  const { content, contentType: learningType } = props;

  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();
  const [showDropTrackItemWarnModal, setShowDropTrackItemWarnModal] = useState(false);

  const { content_type: contentType, is_completable: isCompletable } = content;
  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const isCourse = contentType === CONTENT_TYPES.course;
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignment = content?.user_assignment || content?.assignment;
  const assignmentState = get(assignment, 'state');
  const { create, drop, undoCompletion, dropStatus, dropError, isLoading } =
    useAssignmentsActionsContext();

  const validateDrop = useCallback(() => {
    if (
      dropStatus === STATUS_ERROR &&
      get(dropError, 'exception') === DROP_TRACK_ITEM_NOT_GRANTED_EXCEPTION_NAME
    )
      setShowDropTrackItemWarnModal(true);
  }, [dropStatus, dropError]);

  const isPreviewMode = useIsPreviewQueryParam();

  useEffect(validateDrop, [validateDrop]);

  if (!devToggleContentAssignment2) {
    return <OldSecondaryActionButton content={content} contentType={learningType || ''} />;
  }

  if (
    includes(
      [CONTENT_TYPES.event, CONTENT_TYPES.track, CONTENT_TYPES.scheduled_track],
      contentType
    ) ||
    (!isCompletable &&
      includes(
        [
          CONTENT_TYPES.article,
          CONTENT_TYPES.program,
          CONTENT_TYPES.video,
          CONTENT_TYPES.linkedcontent,
        ],
        contentType
      )) ||
    (assignmentState === ASSIGNMENT_STATES.completed && isAssessment)
  )
    return null;

  if (assignmentState === ASSIGNMENT_STATES.completed && !isPreviewMode) {
    return (
      <Container>
        <ButtonLink onClick={undoCompletion} variant="error" disabled={isLoading}>
          Undo
        </ButtonLink>
      </Container>
    );
  }

  if (canBeDropped(assignment)) {
    // The code is dealing with both formats, because the old content item endpoint is still
    // returning the assignment in the old format, as long as the migration to CA.2.0 is not
    // done completely, we have to deal with these two formats.
    const dueDate = get(assignment, 'due_date') || get(assignment, 'due_at');
    const isOverdue = dueDate ? moment().isAfter(moment(dueDate).endOf('day')) : false;
    const formattedDueDate = dueDate ? Datetime.Date({ datetime: dueDate }) : '';

    return (
      <Container>
        {!dueDate && (
          <>
            <Button onClick={() => drop()} startIcon={<HighlightOffIcon />} disabled={isLoading}>
              Drop
            </Button>
            {showDropTrackItemWarnModal && (
              <DropTrackItemWarnModal
                handleClose={() => setShowDropTrackItemWarnModal(false)}
                unassign={() => drop({ force: true })}
                content={content}
                tracks={map(get(dropError, 'dependents', []), 'content_item')}
              />
            )}
          </>
        )}
        {dueDate && (
          <DateContainer>
            {isOverdue ? (
              <Pill icon="exclamation" label={formattedDueDate} variant="error200" size="medium" />
            ) : (
              <Pill
                icon="clock-filled"
                label={formattedDueDate}
                variant="neutral200"
                size="medium"
              />
            )}
          </DateContainer>
        )}
      </Container>
    );
  }

  const isSaveButtonDisabled =
    isLoading ||
    (isAssessment && !size(content?.track_items)) ||
    (isCourse && isEmpty((content as Course)?.scorm_cloud_id));

  return (
    <Container>
      <Button
        onClick={() => create()}
        disabled={isSaveButtonDisabled}
        startIcon={<BookmarkBorderOutlined />}
      >
        Save
      </Button>
    </Container>
  );
};

export default SecondaryActionButton;
