import { createContext, useContext, useEffect, useCallback } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import Loading from 'shared/components/Loading';
import { STATUS_DONE } from 'shared/constants';
import { useLabels, usePublicIdFromURL } from 'shared/hooks';
import { useDescendantTrackSectionsAndItems, useFetchDescendantTrackItems } from 'tracks/hooks';
import { FullTrackSection, Track, TrackItem, TrackContentItem } from 'tracks/interfaces';
import { map } from 'vendor/lodash';

const DEFAULT_INDEX = -1;

const DEFAULT_STEP_LABELS = { singular: 'Step', plural: 'Steps' };

export interface LabelInstance {
  singular: string;
  plural: string;
}

const Context = createContext<{
  parentTrack: Track;
  sectionsAndItemsOrderedList: (FullTrackSection | TrackItem)[];
  nonEmptySectionsAndItemsOrderedList: (FullTrackSection | TrackItem)[];
  nonEmptySectionsWithItems: FullTrackSection[];
  totalCount: number;
  filterTrackItems: (track: Track) => TrackItem[];
  filterTrackSections: (track: Track) => FullTrackSection[];
  getSectionOrItemByIndex: (index: number) => FullTrackSection | TrackItem | undefined;
  getStepLabels: () => LabelInstance;
  getTrackByPublicId: (publicId: string) => [Track | undefined, number];
  getTrackItemIndex: (trackId: string, contentItemId: string) => number;
  getTrackSectionIndex: (trackId: string, sectionId: string) => number;
  uncompletedLastContentItem: TrackContentItem | undefined;
  trackCompleted: boolean;
  onRefreshData: () => void;
}>({
  parentTrack: {} as Track,
  sectionsAndItemsOrderedList: [],
  nonEmptySectionsAndItemsOrderedList: [],
  nonEmptySectionsWithItems: [],
  totalCount: 0,
  filterTrackItems: () => [],
  filterTrackSections: () => [],
  getSectionOrItemByIndex: () => undefined,
  getStepLabels: () => DEFAULT_STEP_LABELS,
  getTrackByPublicId: () => [undefined, -1],
  getTrackItemIndex: () => DEFAULT_INDEX,
  getTrackSectionIndex: () => DEFAULT_INDEX,
  uncompletedLastContentItem: undefined,
  trackCompleted: false,
  onRefreshData: () => undefined,
});

export const useComposableTrackContext = () => useContext(Context);

interface ComposableTrackProviderProps {
  track: Track;
  trackType?:
    | typeof CONTENT_TYPES.track
    | typeof CONTENT_TYPES.scheduled_track
    | typeof CONTENT_TYPES.assessment;
  children: React.ReactNode;
}

export const ComposableTrackProvider = ({
  track,
  trackType = CONTENT_TYPES.track,
  children,
}: ComposableTrackProviderProps) => {
  const { publicId: trackId } = usePublicIdFromURL();

  const { fetchDescendantTrackItems, descendantTrackItems, fetchDescendantTrackItemsStatus } =
    useFetchDescendantTrackItems();

  const isTrack = trackType === CONTENT_TYPES.track || trackType === CONTENT_TYPES.scheduled_track;
  const isAssessment = trackType === CONTENT_TYPES.assessment;

  const trackItems = !isTrack
    ? map(track.track_items, (item) => ({ ...item, track_id: track.public_id }))
    : descendantTrackItems;

  const {
    sectionsAndItemsOrderedList,
    nonEmptySectionsAndItemsOrderedList,
    nonEmptySectionsWithItems,
    totalCount,
    filterTrackItems,
    filterTrackSections,
    getSectionOrItemByIndex,
    getTrackByPublicId,
    getTrackItemIndex,
    getTrackSectionIndex,
    uncompletedLastContentItem,
    trackCompleted,
  } = useDescendantTrackSectionsAndItems(track, trackItems);

  const handleFetchDescendantTrackItems = useCallback(() => {
    if (!isTrack) return;
    fetchDescendantTrackItems(trackId);
  }, [fetchDescendantTrackItems, isTrack, trackId]);

  useEffect(() => {
    handleFetchDescendantTrackItems();
  }, [handleFetchDescendantTrackItems]);

  const { label_question: labelQuestionSingular, label_question_plural: labelQuestionPlural } =
    useLabels();

  const getStepLabels = () => {
    if (isAssessment) return { singular: labelQuestionSingular, plural: labelQuestionPlural };
    return DEFAULT_STEP_LABELS;
  };

  if (isTrack && fetchDescendantTrackItemsStatus !== STATUS_DONE) return <Loading />;

  return (
    <Context.Provider
      value={{
        parentTrack: track,
        sectionsAndItemsOrderedList,
        nonEmptySectionsAndItemsOrderedList,
        nonEmptySectionsWithItems,
        totalCount,
        filterTrackItems,
        filterTrackSections,
        getSectionOrItemByIndex,
        getStepLabels,
        getTrackByPublicId,
        getTrackItemIndex,
        getTrackSectionIndex,
        uncompletedLastContentItem,
        trackCompleted,
        onRefreshData: handleFetchDescendantTrackItems,
      }}
    >
      {children}
    </Context.Provider>
  );
};
