import { useCallback, useEffect } from 'react';

import { Assessment } from 'assessments/interfaces';
import { useAssignmentsActions } from 'assignments/hooks';
import FeedbackForm from 'shared-cards/components/FeedbackForm';
import Modal from 'shared/components/Modal';
import { STATUS_DONE, STATUS_NOT_REQUESTED } from 'shared/constants';
import { useLabels } from 'shared/hooks';
import RichTextDescription from 'stand-alone-shared/components/ContentDescription';
import { get, toNumber, toLower, trimEnd, replace, isNil, reduce } from 'vendor/lodash';
import { styled } from 'vendor/mui';

const CongratulationsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface AssessmentCompletionModalProps {
  assessment: Assessment;
  handleClose: () => void;
}

export const AssessmentCompletionModal = ({
  assessment,
  handleClose,
}: AssessmentCompletionModalProps) => {
  const { user_assignment: assignment } = assessment;
  const { rate, rateStatus, retrieve, retrieveStatus, acknowledgeCompletion, isLoading } =
    useAssignmentsActions(undefined, get(assignment, 'id'));
  const { label_assessment: labelAssessment } = useLabels();
  const { id: contentId, passing_score: rawPassingScore } = assessment;
  const feedbackText = get(assignment, 'rating.feedback', '');
  const feedbackRating = get(assignment, 'rating.rating', 5);
  const passingScore = toNumber(rawPassingScore) * 100 || 0;
  const hasPassingGrade = passingScore > 0;
  const score = toNumber(get(assignment, 'completion.score', '0')) * 100;
  const percentageScore = `${replace(trimEnd(score.toFixed(2), '0'), /\.$/, '')}%`;
  const percentagePassingScore = `${replace(trimEnd(passingScore.toFixed(2), '0'), /\.$/, '')}%`;
  const fieldLabels = {
    rating: `Rate the ${toLower(labelAssessment)}`,
    feedback: 'Leave a review',
  };
  const fieldPlaceholders = {
    // prettier-ignore
    feedback: 'We\'d appreciate your feedback.',
  };

  const loadCompletion = useCallback(() => {
    if (isNil(get(assignment, 'completion')) && retrieveStatus === STATUS_NOT_REQUESTED) {
      retrieve();
    }
  }, [assignment, retrieve, retrieveStatus]);

  useEffect(() => {
    if (rateStatus === STATUS_DONE) {
      handleClose();
    }
  }, [rateStatus, handleClose]);

  useEffect(() => {
    loadCompletion();
  }, [loadCompletion]);

  const handleCloseAndAckCompletion = () => {
    handleClose();
    acknowledgeCompletion();
  };

  const initial = {
    id: contentId,
    feedback_rating: feedbackRating || 5,
    feedback_text: feedbackText,
    user_id: get(assignment, 'user_id'),
  };
  const getCompletionDialogSetting = () => {
    if (hasPassingGrade && score >= passingScore) {
      return 'assessment_completion_success_dialog_content';
    }
    if (hasPassingGrade) {
      return 'assessment_completion_fail_dialog_content';
    }
    return 'assessment_completion_dialog_content';
  };

  const replacementsMap = [
    ['contentName', assessment.name],
    ['labelAssessment', labelAssessment],
    ['grade', percentageScore],
    ['passingGradeThreshold', percentagePassingScore],
  ];

  const interpolatedCompletionDialogContent = reduce(
    replacementsMap,
    (acc, [replacementKey, replacementValue]) =>
      replace(acc, `{{ ${replacementKey} }}`, replacementValue),
    get(assessment, `settings.${getCompletionDialogSetting()}.value.1`)
  );

  return (
    <Modal
      title={`${labelAssessment} Complete`}
      handleClose={handleCloseAndAckCompletion}
      width={500}
    >
      <CongratulationsContainer>
        <RichTextDescription description={interpolatedCompletionDialogContent} noPadding />
      </CongratulationsContainer>
      <FeedbackForm
        user={get(assignment, 'user')}
        initialValues={initial}
        onSubmitFunc={rate}
        fieldLabels={fieldLabels}
        fieldPlaceholders={fieldPlaceholders}
        handleDismiss={handleCloseAndAckCompletion}
        formMarginBottom={15}
        submitting={isLoading}
        feedbackIsPublic
      />
    </Modal>
  );
};

export default AssessmentCompletionModal;
