import { useEffect } from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { contentSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import Page404 from 'scenes/404';
import TrackPageContainer from 'scenes/TrackPage/TrackPageContainer';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from 'shared/constants';
import { usePublicIdFromURL, useQueryParams, useSetDetailedObject } from 'shared/hooks';
import { ComposableTrackProvider } from 'tracks/context-providers/ComposableTrackContext';

const TrackDetail = () => {
  const { publicId } = usePublicIdFromURL();
  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { trackActivity } = useMetrics();

  const [fetchContent, { data: content, status: contentStatus, statusCode }] = useEntities(
    actions.content.retrieveDetails,
    ({ status }) => {
      if (status === STATUS_DONE && page === 0) {
        trackActivity(METRICS_ACTIVITIES.CONTENT_ITEM_VIEW, {
          contentItemId: content.id,
          contentItemType: content.content_type,
        });
      }
    },
    {
      schema: contentSchema,
    }
  );

  useEffect(() => {
    fetchContent(publicId);
  }, []);

  useSetDetailedObject({ id: publicId }, 'tracks', []);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;

  return (
    <ComposableTrackProvider track={content}>
      <TrackPageContainer
        track={content}
        fetchTrack={fetchContent}
        status={contentStatus}
        statusCode={statusCode}
        entityType={LEARNING_TYPES.tracks}
      />
    </ComposableTrackProvider>
  );
};

export default TrackDetail;
