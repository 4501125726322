import * as React from 'react';

import { AssessmentQuestionSkipConfirmationModalContext } from 'assessments/components/AssessmentQuestionSkipConfirmationModal';
import { ASSESSMENT_FEEDBACK_POLICY } from 'assessments/constants';
import { useCompleteQuestion } from 'assessments/hooks';
import { Assessment, TextQuestionResponse, TextQuestion } from 'assessments/interfaces';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useContentItemFetcher } from 'catalog/hooks';
import { getIsLastQuestionInAssessment } from 'features/assessments/utils/helpers';
import { useTrackContentNavigation } from 'features/tracks/contexts/TrackContentNavigationCtx';
import Button from 'shared/components/Button';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { Track, TrackItem } from 'tracks/interfaces';
import { isEmpty, isNil, get } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

import { QuestionDetail } from './QuestionDetail';
import { TextInputFeedbackState, TextQuestionInput } from './TextQuestionInput';

interface TextQuestionDetailProps {
  question: TextQuestion;
  assessment: Assessment;
  track?: Track;
  item?: TrackItem;
}

export const TextQuestionDetail = (props: TextQuestionDetailProps) => {
  const { question, assessment, track, item } = props;

  const { user_assignment: questionUserAssignment } = question;
  const { user_assignment: assessmentUserAssignment } = assessment;

  const [initialTextResponse, setInitialTextResponse] = React.useState(
    questionUserAssignment ? questionUserAssignment.text_response : ''
  );
  const [textResponse, setTextResponse] = React.useState(initialTextResponse);

  const isCompleted = !isEmpty(initialTextResponse);
  const [isSuccess, setIsSuccess] = React.useState(questionUserAssignment?.is_success);

  const { setDirtyQuestion } = React.useContext(AssessmentQuestionSkipConfirmationModalContext);
  const { goBack, advance } = useTrackContentNavigation();

  React.useEffect(() => {
    setDirtyQuestion(initialTextResponse !== textResponse);
  }, [initialTextResponse, textResponse, setDirtyQuestion]);

  const { fetchItem: fetchAssessment } = useContentItemFetcher(
    assessment.public_id,
    assessment.content_type
  );
  const { fetchItem: fetchTrack } = useContentItemFetcher(
    track?.public_id || '',
    track?.content_type || ''
  );

  const itemPublicId = get(item, 'content_item.public_id');
  const itemContentType = get(item, 'content_item.content_type');
  const { fetchItem } = useContentItemFetcher(itemPublicId, itemContentType);

  const shouldDisplayFeedback =
    assessmentUserAssignment?.state === ASSIGNMENT_STATES.completed ||
    assessment.feedback_policy === ASSESSMENT_FEEDBACK_POLICY.after_question;

  const isLastQuestion = getIsLastQuestionInAssessment(question.id, assessment);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextResponse(e.target.value);
  };

  const handleRefetchData = () => {
    /* Fetch assessment to:
     - Update the track_items (questions) in redux state
     - Check if the assessment was completed after the question */
    fetchAssessment();
    fetchItem();
  };

  const handleRefetchTrackData = () => {
    // Fetch track when the assessment is within a track
    if (track) fetchTrack();
  };

  const handleSubmitSuccessful = (questionResponse: TextQuestionResponse) => {
    const isCorrect = questionResponse.is_success;

    setInitialTextResponse(questionResponse.text_response);
    setIsSuccess(isCorrect);
    handleRefetchData();
  };

  const { completeQuestion, isLoading } = useCompleteQuestion({
    contentType: question.content_type,
    onSuccess: handleSubmitSuccessful,
  });

  const handleSubmit = () => {
    completeQuestion(questionUserAssignment?.id, {
      content_type: question.content_type,
      text_response: textResponse,
    });
  };

  const handleFinish = () => {
    advance();
    handleRefetchData();
    handleRefetchTrackData();
  };

  const handleGetFeedbackState = (): TextInputFeedbackState => {
    if (!isCompleted) {
      return 'none';
    }

    if (!shouldDisplayFeedback) {
      return 'indeterminate';
    }

    return isSuccess ? 'correct' : 'incorrect';
  };

  const [displayFeedbackText, feedbackText] = React.useMemo(() => {
    let feedbackText;
    const contentItem = get(item, 'content_item');

    if (!isNil(isSuccess)) {
      if (isSuccess) {
        const questionFeedback = get(question, 'feedback_text_on_success');
        const contentItemFeedback = get(contentItem, 'feedback_text_on_success');

        feedbackText = questionFeedback || contentItemFeedback;
      } else {
        const questionFeedback = get(question, 'feedback_text_on_fail');
        const contentItemFeedback = get(contentItem, 'feedback_text_on_fail');

        feedbackText = questionFeedback || contentItemFeedback;
      }
    }

    const shouldDisplayFeedbackText = feedbackText && shouldDisplayFeedback;

    return [shouldDisplayFeedbackText, feedbackText] as const;
  }, [isSuccess, question, shouldDisplayFeedback, item]);

  const disableSubmitButton = isEmpty(questionUserAssignment) || isEmpty(textResponse) || isLoading;

  return (
    <QuestionDetail question={question}>
      <Box display="flex" justifyContent="space-between" marginTop={1.5} pb={2.5}>
        <TextQuestionInput
          value={textResponse}
          disabled={isCompleted}
          handleChange={handleChange}
          feedbackState={handleGetFeedbackState}
        />
      </Box>

      {displayFeedbackText && (
        <Box padding={1.5}>
          <Typography variant="caption">Feedback</Typography>
          <ContentDescription description={feedbackText} noPadding />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between">
        <Button onClick={goBack}>Previous</Button>

        {!isCompleted ? (
          <Button
            color="primary"
            variant="contained"
            disabled={disableSubmitButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : !isLastQuestion ? (
          <Button color="primary" variant="contained" onClick={advance}>
            Next
          </Button>
        ) : (
          <Button color="primary" variant="contained" onClick={handleFinish}>
            Finish
          </Button>
        )}
      </Box>
    </QuestionDetail>
  );
};
