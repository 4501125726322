import { createActions } from 'redux-actions';

import { noop } from 'vendor/lodash';

const retrieve = (key, params) => ({ key, params });
const retrieveById = (key, id, params) => ({ key, id, params });
const filter = (key, filters, options) => ({ key, filters, options });
const loadMore = (key, url) => ({ key, url });
const filterById = (key, id, filters) => ({ key, id, filters });
const filterByNestedId = (key, public_id, id, filters) => ({ key, public_id, id, filters });
const update = (key, id, body) => ({ key, id, body });
const createById = (key, id, body) => ({ key, id, body });
const create = (key, body) => ({ key, body });
const exportData = (key, filters, body) => ({ key, filters, body });
const remove = (key, id) => ({ key, id });
const failure = (error) => error;
const success = (data) => data;

const storeEntities = (data, schema) => ({ data, schema });
const updateEventEnrollment = (key, id, userId, body) => ({ key, id, userId, body });
const updateSessionEnrollment = (key, id, userId, body) => ({ key, id, userId, body });
const updateSnippetUserAssignment = (key, id, userId, body) => ({ key, id, userId, body });
const updateCodelabAssignment = (key, id, body) => ({ key, id, body });
const updateCourseRegistration = (key, id, body) => ({ key, id, body });

export default createActions(
  {
    STORE_ENTITIES: storeEntities,
    GRAPHQL: {
      REFRESH_TOKEN: update,
    },
    CURRENT_USER: {
      FILTERS: retrieve,
    },

    EVENT: {
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_DETAILS_EMAIL: retrieveById,
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_RQL: filter,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,

      BULK_COPY: noop,
      BULK_COPY_SUBMIT: create,
      BULK_COPY_SUCCESS: success,
      BULK_COPY_FAILURE: failure,

      FEEDBACK_LIST: retrieveById,

      ARCHIVE: update,
    },

    EVENT_ENROLLMENT: {
      UPDATE_ENROLLED_USER: updateEventEnrollment,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },

    ENROLLMENT: {
      DEPRECATED_RETRIEVE_LIST: filter,
      RETRIEVE_LIST: filter,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      LIST_EMAILS: filter,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      RETRIEVE: retrieveById,
      UPDATE_ORDER: update,
      CHECKIN: update,
      UNDO_CHECKIN: update,
      PROMOTE: update,
      DEMOTE: update,
      DROP: update,

      BULK: create,
      BULK_CHECKIN: create,
      BULK_UNDO_CHECKIN: create,
      BULK_PROMOTE: create,
      BULK_DEMOTE: create,
      BULK_DROP: create,
    },

    USER_DATA: {
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_RQL: filter,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_RECENT_ACTIVITY_LIST: filterById,

      RETRIEVE_IMPACTED_LIST: filterById,
      RETRIEVE_FACILITATOR_FEEDBACK: filterById,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,

      RETRIEVE_CONSUMED_CONTENT_LIST: filterById,
      RETRIEVE_OVERALL_IMPACT_LIST: filterById,
    },

    EVENT_TYPE: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_RQL: filter,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      REQUEST_EVENT: create,
      RETRIEVE_REQUESTED_EVENTS: filter,
      RETRIEVE_REQUESTED_EVENTS_LOAD_MORE: loadMore,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: update,
    },

    CONTENT: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_RQL: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_DETAILS: retrieveById,

      FEEDBACK_LIST: filter,
    },

    TRACK: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_BREADCRUMBS: retrieveById,
      FEEDBACK_LIST: filter,

      RETRIEVE_DESCENDANT_TRACK_ITEMS: filterById,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: createById,
      RESTORE: remove,
    },

    TRACK_ITEM: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      ARCHIVE: update,
      UNARCHIVE: remove,
    },

    SCHEDULED_TRACK: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_FILTERS: retrieve,
      RETRIEVE_DETAILS: retrieveById,
      FEEDBACK_LIST: filter,
      RETRIEVE_TRACK_EVENT_LIST: filterById,

      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: createById,
      RESTORE: remove,
    },

    ASSESSMENT: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      FEEDBACK_LIST: filter,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: createById,
      RESTORE: remove,
    },

    QUESTION: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
    },

    MULTIPLE_CHOICE_QUESTION: {
      OPTIONS_LIST: filter,
    },

    MULTIPLE_CHOICE_QUESTION_RESPONSE: {
      COMPLETION: update,
    },

    TEXT_QUESTION_RESPONSE: {
      COMPLETION: update,
    },

    LINKED_CONTENT: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      FEEDBACK_LIST: filter,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: createById,
      RESTORE: remove,
    },

    CUSTOMER: {
      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },

    ARTICLE: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      FEEDBACK_LIST: filter,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: update,
    },

    ARTICLE_ASSIGNMENT: {
      RETRIEVE_LIST: filter,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },

    TASK: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CONVERT: create,
    },

    CONTENT_ASSIGNMENT: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_RQL: filter,
      RETRIEVE_RQL_FILTERS: retrieve,
      TEAM_RETRIEVE_RQL: filter,
      TEAM_RETRIEVE_RQL_FILTERS: retrieve,

      UPDATE: updateSnippetUserAssignment,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      BULK_ASSIGN: create,
      RETRIEVE_USERS_FOR_EMAILS: filter,
    },

    ASSIGNMENT: {
      LIST: filter,
      LIST_LOAD_MORE: loadMore,

      LIST_EMAILS: filter,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      RETRIEVE: retrieveById,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,

      PROGRESS_RETRIEVE: retrieveById,
      PROGRESS_SUBMIT: update,
      PROGRESS_SUCCESS: success,
      PROGRESS_FAILURE: failure,
      PROGRESS_REMOVE: remove,

      COMPLETION_RETRIEVE: retrieveById,
      COMPLETION_SUBMIT: update,
      COMPLETION_SUCCESS: success,
      COMPLETION_FAILURE: failure,
      COMPLETION_REMOVE: remove,

      DROP_RETRIEVE: retrieveById,
      DROP_SUBMIT: update,
      DROP_SUCCESS: success,
      DROP_FAILURE: failure,

      EXEMPTION_RETRIEVE: retrieveById,
      EXEMPTION_SUBMIT: update,
      EXEMPTION_SUCCESS: success,
      EXEMPTION_FAILURE: failure,
      EXEMPTION_REMOVE: remove,

      RATING_RETRIEVE: retrieveById,
      RATING_SUBMIT: update,
      RATING_SUCCESS: success,
      RATING_FAILURE: failure,

      COMPLETION_ACKNOWLEDGEMENT_RETRIEVE: retrieveById,
      COMPLETION_ACKNOWLEDGEMENT_SUBMIT: update,
      COMPLETION_ACKNOWLEDGEMENT_SUCCESS: success,
      COMPLETION_ACKNOWLEDGEMENT_FAILURE: failure,

      BULK: create,
      BULK_UPDATE: create,
      BULK_COMPLETION: create,
      BULK_UNDO_COMPLETION: create,
      BULK_EXEMPTION: create,
      BULK_UNDO_EXEMPTION: create,
      BULK_DROP: create,
    },

    LOCATION: {
      RETRIEVE_LIST: filter,
    },

    SURVEY: {
      RETRIEVE_LIST: filter,
      RETRIEVE_DETAILS: retrieveById,
      PREVIEW: retrieveById,

      // _SUBMIT and other methods not needed since there's no ReduxForm
      CREATE: create,
      UPDATE: update,
    },

    SURVEY_RELATIONSHIP: {
      RETRIEVE_LIST: filter,
      RETRIEVE_DETAILS: retrieveById,
      EXPORT_MULTIPLE: exportData,

      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      INSIGHTS: retrieveById,
    },

    USER_SURVEY: {
      RETRIEVE_LIST: filter,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,
    },

    PROGRAM: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: update,
    },

    MENTORSHIP_PROGRAM: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: update,
    },

    SESSION: {
      RETRIEVE_COLUMNS: (key, numberOfColumns, filters, options) => ({
        key,
        numberOfColumns,
        filters,
        options,
      }),
      RETRIEVE_COLUMNS_LOAD_MORE: (key, url, currentDates, numberOfColumns) => ({
        key,
        url,
        currentDates,
        numberOfColumns,
      }),

      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      ENROLLMENT_UPDATE: updateSessionEnrollment,
      ENROLLMENT_UPDATE_SUBMIT: update,
      ENROLLMENT_UPDATE_SUCCESS: success,
      ENROLLMENT_UPDATE_FAILURE: failure,

      FEEDBACK_LIST: filter,

      REQUEST_SESSION: create,

      BULK_COPY: noop,
      BULK_COPY_SUBMIT: create,
      BULK_COPY_SUCCESS: success,
      BULK_COPY_FAILURE: failure,
    },
    SESSION_MENTORSHIP: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },
    SESSION_PROGRAM: {
      RETRIEVE_COLUMNS: (key, numberOfColumns, filters, options) => ({
        key,
        numberOfColumns,
        filters,
        options,
      }),

      RETRIEVE_LIST: filter,
      RETRIEVE_DETAILS: retrieveById,
      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },
    MENTORSHIP_REQUEST: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      CREATE: create,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },
    SESSION_ENROLLMENT: {
      CREATE: create,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      FEEDBACK_UPDATE: noop,
      FEEDBACK_UPDATE_SUBMIT: update,
      FEEDBACK_UPDATE_SUCCESS: success,
      FEEDBACK_UPDATE_FAILURE: failure,

      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
    },

    MENTOR: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
    },

    TESTIMONIAL: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      UPDATE_HIDDEN_STATUS: update,
    },

    GOOGLE_CALENDAR: {
      FREE_BUSY_CHECK: retrieve,
      RETRIEVE_ROOMS: retrieve,
      CHECK_ROOM_AVAILABILITY: retrieve,
    },

    CALENDAR: {
      RESYNC_RESOURCE_TO_CALENDAR: create,
    },

    TOPIC: {
      RETRIEVE_LIST: filter,
    },

    CHANNEL: {
      RETRIEVE_LIST: filter,
    },

    CODELAB: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,

      UPDATE: noop,
      UPDATE_SUBMIT: create,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      UPLOAD: create,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      ARCHIVE: createById,
      RESTORE: remove,
    },

    CODELAB_ASSIGNMENT: {
      REGISTER: create,
      BULK_REGISTER: create,

      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_USERS_FOR_EMAILS: filter,

      FEEDBACK_LIST: filter,

      UPDATE: updateCodelabAssignment,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
    },

    COURSE: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_CONFIGURATION: retrieveById,
      UPDATE_CONFIGURATION: update,

      UPDATE: noop,
      UPDATE_SUBMIT: create,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      UPLOAD: create,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      ARCHIVE: update,
    },

    COURSE_REGISTRATION: {
      REGISTER: create,
      BULK_REGISTER: create,

      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_USERS_FOR_EMAILS: filter,

      FEEDBACK_LIST: filter,

      UPDATE: updateCourseRegistration,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
    },

    CATALOG_DISCOVER: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
    },

    RELATED_TAG: {
      RETRIEVE_LIST: filter,
    },

    GENERATE_S3_SIGNED_URL: create,
    FETCH_AWS_DATA: retrieveById,

    LOGTRAIL: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_EVENTS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_ENROLLMENTS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_ATTENDANCES: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_ENROLLMENTS_BY_USER: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_PRESENTERS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_SESSIONS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_MENTORS: {
      RETRIEVE_LIST: filter,
    },

    GROUPS: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
    },

    OPENGRAPH: {
      RETRIEVE_METADATA: create,
    },

    ANALYTICS_FOR_ASSIGNMENTS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_SESSIONS: {
      RETRIEVE_LIST: filter,
    },

    // Analytics app
    ANALYTICS_FOR_CHART_EVENTS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_ENGAGEMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP_FEEDBACK: {
      RETRIEVE_LIST: filter,
    },

    // Charts app
    // Content Item and Assignment
    CHARTS_CONTENT_ITEM: {
      TYPE_COUNT_BY_TIME_INTERVAL: filter,
      COUNT_BY_TIME_INTERVAL: filter,
      COUNT_BY_FACILITATOR: filter,
      COUNT_BY_TAG: filter,
      COUNT_BY_CATEGORY: filter,
      COUNT_BY_FLEXIBLE_FILTER: filter,
      ASSIGNMENT_COUNT_BY_TIME_INTERVAL: filter,
      ASSIGNMENT_STATE_COUNT_BY_TIME_INTERVAL: filter,
      ASSIGNMENT_RATING_BY_TIME_INTERVAL: filter,
      ASSIGNMENT_RATING_COUNT_BY_TIME_INTERVAL: filter,
      ASSIGNMENT_COUNT_BY_FACILITATOR: filter,
      ASSIGNMENT_COUNT_BY_TAG: filter,
    },

    ANALYTICS_FOR_CHART_EVENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_ENROLLMENT_COUNT_HOURS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_ENROLLMENT_RATINGS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_ENROLLMENT_STATUS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ENROLLMENT_COUNT_HOURS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ATTENDEE_RATINGS: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_USER_ENGAGEMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP_ENROLLMENT_COUNT_HOURS_BY_MENTOR_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP_ENROLLMENT_COUNT_HOURS_BY_MENTOR_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP_ENROLLMENT_COUNT_HOURS_BY_MENTEE_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_MENTORSHIP_ENROLLMENT_COUNT_HOURS_BY_MENTEE_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ENROLLMENT_COUNT_HOURS_BY_HOST_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ENROLLMENT_COUNT_HOURS_BY_HOST_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ENROLLMENT_COUNT_HOURS_BY_ATTENDEE_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_PROGRAM_SESSION_ENROLLMENT_COUNT_HOURS_BY_ATTENDEE_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_EVENT_ENROLLMENT_COUNT_HOURS_BY_PRESENTER_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_EVENT_ENROLLMENT_COUNT_HOURS_BY_PRESENTER_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_EVENT_ENROLLMENT_COUNT_HOURS_BY_ENROLLEE_DEPARTMENT: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_EVENT_ENROLLMENT_COUNT_HOURS_BY_ENROLLEE_TITLE: {
      RETRIEVE_LIST: filter,
    },

    ANALYTICS_FOR_CHART_EVENTS_BY_ATTRIBUTE: {
      RETRIEVE_LIST: filter,
    },

    ANNOUNCEMENTS: {
      RETRIEVE_LIST: filter,
    },

    VIDEO: {
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_DETAILS: retrieveById,
      FEEDBACK_LIST: filter,

      REMOVE: remove,
      FORCE_REMOVE: remove,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      ARCHIVE: update,
    },

    VIDEO_ASSIGNMENT: {
      RETRIEVE_LIST: filter,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },

    CSV_IMPORT: {
      RETRIEVE_DETAILS: retrieveById,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,
    },

    SLACK: {
      TURN_OFF: retrieve,
    },

    SEGMENT: {
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_DASHBOARD: retrieve,
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,
    },

    AUTOMATED_RULE: {
      RETRIEVE_DETAILS: retrieveById,
      RETRIEVE_LIST: filter,
      RETRIEVE_LIST_LOAD_MORE: loadMore,
      RETRIEVE_RULE_LOGS: filterById,
      RETRIEVE_RULE_LOGTRAIL: filterByNestedId,

      CREATE: noop,
      CREATE_SUBMIT: create,
      CREATE_SUCCESS: success,
      CREATE_FAILURE: failure,

      UPDATE: noop,
      UPDATE_SUBMIT: update,
      UPDATE_SUCCESS: success,
      UPDATE_FAILURE: failure,

      REMOVE: remove,

      RUN: create,
    },
  },

  'UPDATE_ENTITIES',
  'RUN_CUSTOM_REDUCER',
  'LOAD_MORE',
  'TOGGLE_APPLY_TO_EVENTS_MODAL'
);
