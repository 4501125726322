import VisibilityIcon from '@mui/icons-material/Visibility';

import { PillsDefinitionProps } from 'app/filters/types';
import { get } from 'vendor/lodash';

export function useChannelHomePillsDefinition(options: PillsDefinitionProps) {
  const { filters } = options;

  const currentTabValue = get(filters, 'tab.$eq', '');

  return {
    pills: [
      ...(currentTabValue
        ? [
            {
              id: 'currentTab',
              value: currentTabValue,
              label: currentTabValue,
              icon: VisibilityIcon,
              filterName: 'tab',
              variant: 'emphasis',
            },
          ]
        : []),
    ],
  };
}
