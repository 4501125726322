import * as React from 'react';
import { useHistory } from 'react-router';
import Sticky from 'react-sticky-el';

import { useAssignmentsActions } from 'app/assignments/hooks';
import { ContentItem } from 'app/shared-content-item/interfaces';
import AssessmentQuestionSkipConfirmationModal, {
  AssessmentQuestionSkipConfirmationModalContext,
} from 'assessments/components/AssessmentQuestionSkipConfirmationModal';
import { Assessment } from 'assessments/interfaces';
import { CONTENT_TYPES } from 'catalog/constants';
import { useContentItemFetcher } from 'catalog/hooks';
import { useContentItemAssignment } from 'common/hooks/useContentItemAssignment';
import ArticleDetailPage from 'features/articles/components/ArticleDetailPage';
import {
  TrackContentNavigationProvider,
  useTrackContentNavigation,
} from 'features/tracks/contexts/TrackContentNavigationCtx';
import { SidebarButton } from 'navigation/components/FilterPage';
import CodelabDetailPage from 'scenes/CodelabDetail/CodelabDetailPage';
import CourseDetailPage from 'scenes/CourseDetail/CourseDetailPage';
import EventDetailPage from 'scenes/EventDetail/EventDetailPage';
import EventTypeDetailPage from 'scenes/EventTypeDetail/EventTypeDetailPage';
import LinkedContentDetailPage from 'scenes/LinkedContentDetail/LinkedContentDetailPage';
import { MultipleChoiceQuestionDetail, TextQuestionDetail } from 'scenes/QuestionDetail';
import { TaskDetailPage } from 'scenes/TaskPage/';
import TrackSectionDetailPage from 'scenes/TrackSectionPage/TrackSectionDetailPage';
import VideoDetailPage from 'scenes/VideoDetail/VideoDetailPage';
import colors from 'services/colors';
import { isContentItemCompletable } from 'shared-content-item/services';
import ContentTopbarPage from 'shared-layouts/ContentTopbarPage';
import HR from 'shared/components/HR';
import Loading from 'shared/components/Loading';
import NavigationContainer from 'shared/components/NavigationContainer';
import { STATUS_DONE } from 'shared/constants';
import { useRouterQueryUtils, useToggles } from 'shared/hooks';
import TrackConsumptionItemList from 'tracks/components/TrackConsumptionItemList';
import TrackConsumptionTopBar from 'tracks/components/TrackConsumptionTopBar';
import TrackItemInfoBox from 'tracks/components/TrackItemInfoBox';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { FullTrackSection, ScheduledTrack, Track, TrackItem } from 'tracks/interfaces';
import { getTrackProgress } from 'tracks/services';
import { filter, find, findIndex, get, isEmpty, isNil, size, includes } from 'vendor/lodash';
import { Box, IconButton, LinearProgressWithLabel, Typography } from 'vendor/mui';
import { MenuOpenOutlinedIcon } from 'vendor/mui-icons';

import TrackDetailPage from './TrackDetailPage';
import TrackItemRateAndCompleteModal from './TrackItemRateAndCompleteModal';

interface TrackItemRendererProps {
  item?: TrackItem;
  section?: FullTrackSection;
  renderTopSection: ({ item }) => React.ReactNode;
  track: Track;
  onItemClick?: (item: TrackItem | FullTrackSection, index: number) => void;
}

/**
 * item = The track item that wraps the content item
 * contentItem = The actual content item that is being rendered
 */

const TrackItemRenderer = (props: TrackItemRendererProps) => {
  const { item, section, renderTopSection, track, onItemClick } = props;

  const { toggle_composable_tracks: toggleComposableTracks } = useToggles();
  const publicId = get(item, 'content_item.public_id');
  const contentType = get(item, 'content_item.content_type');
  const directParentTrackId = get(item, 'track_id', '');
  const isSection = !isEmpty(section);
  const isQuestion = includes(
    [CONTENT_TYPES.multiple_choice_question, CONTENT_TYPES.text_question, CONTENT_TYPES.question],
    contentType
  );
  const isItemRequired = get(item, 'is_required', true);

  const { setDisplayBottomNavigation } = useTrackContentNavigation();

  React.useEffect(() => {
    if (isQuestion) {
      setDisplayBottomNavigation(false);
    } else {
      setDisplayBottomNavigation(true);
    }
  }, [isQuestion, setDisplayBottomNavigation]);

  const { fetchItem, contentItem, status } = useContentItemFetcher(publicId, contentType);
  const {
    fetchItem: fetchAssessment,
    contentItem: assessment,
    status: fetchAssessmentStatus,
  } = useContentItemFetcher(directParentTrackId, CONTENT_TYPES.assessment);

  const { nonEmptySectionsWithItems, getTrackItemIndex, onRefreshData } =
    useComposableTrackContext();

  React.useEffect(() => {
    if (isSection) return;

    if (publicId) {
      fetchItem();
    }
  }, [isSection, publicId]); // eslint-disable-line react-hooks/exhaustive-deps

  // When a question is rendered inside a track (not an assessment) the current
  // context does not have the assessment-specific values, as feedback policy,
  // so it is necessary to fetch the assessment.
  React.useEffect(() => {
    if (isQuestion) {
      fetchAssessment();
    }
  }, [directParentTrackId]); // eslint-disable-line react-hooks/exhaustive-deps

  // If an assessment is retried, we need to recreate the track descendant items
  // Otherwise, the assessment items will hold a reference to the outdated assignment
  const handleRefreshAssessmentContent = React.useCallback(() => {
    fetchItem();
    onRefreshData();
  }, [fetchItem, onRefreshData]);

  if (isSection) {
    const trackSectionsWithItems = filter(nonEmptySectionsWithItems, [
      'track_id',
      section?.track_id,
    ]);
    const sectionWithItems = find(trackSectionsWithItems, ['id', section?.id]);
    const sectionIndex = findIndex(trackSectionsWithItems, ['id', section?.id]);

    if (isEmpty(sectionWithItems)) return null;

    return (
      <TrackSectionDetailPage
        section={sectionWithItems as FullTrackSection}
        currentTrackSectionIndex={sectionIndex + 1}
        trackSectionsCount={size(trackSectionsWithItems)}
        getTrackItemIndex={getTrackItemIndex}
        onItemClick={onItemClick}
      />
    );
  }

  // As we now have only one endpoint for contents, when the user is navigating,
  // there may be an intermediate state in that the fetchItem has not started yet
  // but the contentItem variable already has a value from a previous request.
  if (
    get(contentItem, 'public_id') !== publicId ||
    (isQuestion && get(assessment, 'public_id') !== directParentTrackId)
  )
    return null;

  if (status !== STATUS_DONE || (isQuestion && fetchAssessmentStatus !== STATUS_DONE)) {
    return <Loading />;
  }

  return (
    <>
      {renderTopSection({ item })}
      {contentType === CONTENT_TYPES.eventtype && (
        <EventTypeDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          isRequired={isItemRequired}
        />
      )}
      {contentType === CONTENT_TYPES.event && <EventDetailPage event={contentItem} track={track} />}
      {contentType === CONTENT_TYPES.article && (
        <ArticleDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          isRequired={isItemRequired}
        />
      )}
      {contentType === CONTENT_TYPES.linkedcontent && (
        <LinkedContentDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          refreshContent={fetchItem}
          isRequired={isItemRequired}
        />
      )}
      {contentType === CONTENT_TYPES.assessment && (
        <TrackDetailPage
          isRenderedWithinTrack
          assessment={contentItem}
          track={track}
          isRequired={isItemRequired}
          refreshContent={handleRefreshAssessmentContent}
        />
      )}
      {contentType === CONTENT_TYPES.video && (
        <VideoDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          isRequired={isItemRequired}
        />
      )}
      {contentType === CONTENT_TYPES.codelab && (
        <CodelabDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          refreshContent={fetchItem}
        />
      )}
      {contentType === CONTENT_TYPES.course && (
        <CourseDetailPage
          isRenderedWithinTrack
          content={contentItem}
          track={track}
          isRequired={isItemRequired}
        />
      )}
      {contentType === CONTENT_TYPES.task && (
        <TaskDetailPage content={contentItem} track={track} isRequired={isItemRequired} />
      )}
      {toggleComposableTracks && contentType === CONTENT_TYPES.track && (
        <TrackDetailPage
          isRenderedWithinTrack
          track={contentItem}
          showBreadcrumbs={false}
          isRequired={isItemRequired}
          refreshContent={fetchItem}
        />
      )}
      {contentType === CONTENT_TYPES.multiple_choice_question && (
        <MultipleChoiceQuestionDetail
          question={contentItem}
          assessment={assessment}
          // Pass along the track if the assessment is within a track
          track={track.content_type != CONTENT_TYPES.assessment ? track : undefined}
        />
      )}
      {contentType === CONTENT_TYPES.text_question && (
        <TextQuestionDetail
          question={contentItem}
          assessment={assessment}
          // Pass along the track if the assessment is within a track
          track={track.content_type != CONTENT_TYPES.assessment ? track : undefined}
          item={item}
        />
      )}
      <AssessmentQuestionSkipConfirmationModal />
    </>
  );
};

interface TrackContentPageProps {
  assessment?: Assessment;
  scheduledTrack?: ScheduledTrack;
  track?: Track;
  page: number;
  refreshContent: CallableFunction;
}

const TrackContentPage = (props: TrackContentPageProps) => {
  const { assessment, scheduledTrack, track, page, refreshContent } = props;

  const { addToQueryString, getUrlWithoutQueryString } = useRouterQueryUtils();
  const history = useHistory();

  const {
    nonEmptySectionsAndItemsOrderedList,
    totalCount,
    getStepLabels,
    uncompletedLastContentItem,
    trackCompleted,
  } = useComposableTrackContext();

  const [showRateAndCompleteModal, setShowRateAndCompleteModal] = React.useState(false);
  const [showNavigationContainer, setNavigationContainer] = React.useState(false);

  const [questionSkipConfirmationModalState, setQuestionSkipConfirmationModalState] =
    React.useState<{
      open: boolean;
      dirtyQuestion: boolean;
      callback?: () => void;
    }>({ open: false, dirtyQuestion: false });

  const content = assessment || scheduledTrack || track;

  const goToPage = React.useCallback(
    (targetPage) => {
      if (questionSkipConfirmationModalState.dirtyQuestion) {
        setQuestionSkipConfirmationModalState({
          open: true,
          dirtyQuestion: true,
          callback: () => addToQueryString({ page: targetPage }),
        });
      } else {
        addToQueryString({ page: targetPage });
      }
    },
    [questionSkipConfirmationModalState, addToQueryString]
  );

  const currentItem = nonEmptySectionsAndItemsOrderedList?.[page - 1];
  const currentTrackContentItem: ContentItem = get(currentItem, 'content_item');
  const currentAssigment = useContentItemAssignment(
    get(
      currentTrackContentItem,
      'user_assignment.id',
      get(currentTrackContentItem, 'assignment.id')
    )
  );

  const isLastTrackItem =
    uncompletedLastContentItem && uncompletedLastContentItem.id === currentTrackContentItem?.id;

  // An assessment cannot be the last content item in a track because it has its questions.
  const isLastContentItem =
    isLastTrackItem && currentTrackContentItem.content_type != CONTENT_TYPES.assessment;

  const currentAssignmenCompleted = get(currentAssigment, 'state') == 'completed';

  const isCurrentTrackItemCompletable = isContentItemCompletable(
    currentTrackContentItem,
    currentAssigment
  );

  const currentTrackItem = currentTrackContentItem ? (currentItem as TrackItem) : undefined;
  const currentSection = !currentTrackContentItem ? (currentItem as FullTrackSection) : undefined;

  const { retrieve: trackRetrieve } = useAssignmentsActions(
    undefined,
    get(content, 'user_assignment.id')
  );

  const { complete } = useAssignmentsActions(currentTrackContentItem, currentAssigment?.id, () => {
    trackRetrieve();
    if (isLastContentItem) {
      history.push(getUrlWithoutQueryString(['page']));
    } else {
      setShowRateAndCompleteModal(false);
      setTimeout(() => goToPage(page + 1));
    }
    refreshContent();
  });

  const handleGoToNextPage = React.useCallback(() => {
    goToPage(page + 1);
  }, [page, goToPage]);

  const handleGoToPreviousPage = React.useCallback(() => {
    goToPage(page - 1);
  }, [page, goToPage]);

  const handleCompleteAndGoNext = React.useCallback(() => {
    if (currentAssigment?.awaiting_feedback) {
      setShowRateAndCompleteModal(true);
    } else {
      complete();
    }
  }, [complete, currentAssigment]);

  const handleFinish = React.useCallback(() => {
    if (isCurrentTrackItemCompletable) {
      setShowRateAndCompleteModal(true);
    } else {
      history.push(getUrlWithoutQueryString(['page']));
    }
  }, [isCurrentTrackItemCompletable, history, getUrlWithoutQueryString]);

  const handleAdvance = React.useCallback(() => {
    if (isLastContentItem) {
      handleFinish();
      return;
    }

    if (isCurrentTrackItemCompletable) {
      handleCompleteAndGoNext();
    } else {
      handleGoToNextPage();
    }
  }, [
    isLastContentItem,
    isCurrentTrackItemCompletable,
    handleGoToNextPage,
    handleCompleteAndGoNext,
    handleFinish,
  ]);

  const handleSetOpenAssessmentQuestionSkipConfirmationModal = React.useCallback(
    (open, callback) => {
      setQuestionSkipConfirmationModalState(({ dirtyQuestion }) => ({
        open,
        callback,
        dirtyQuestion,
      }));
    },
    []
  );

  const handleSetDirtyQuestion = React.useCallback((dirtyQuestion) => {
    setQuestionSkipConfirmationModalState(({ open, callback }) => ({
      open,
      callback,
      dirtyQuestion,
    }));
  }, []);

  const assessmentQuestionSkipConfirmationModalState = React.useMemo(() => {
    return {
      open: questionSkipConfirmationModalState.open,
      dirtyQuestion: questionSkipConfirmationModalState.dirtyQuestion,
      callback: questionSkipConfirmationModalState.callback,
      setOpen: handleSetOpenAssessmentQuestionSkipConfirmationModal,
      setDirtyQuestion: handleSetDirtyQuestion,
    };
  }, [
    questionSkipConfirmationModalState,
    handleSetOpenAssessmentQuestionSkipConfirmationModal,
    handleSetDirtyQuestion,
  ]);

  if (isNil(content)) return null;

  const { singular: stepLabel } = getStepLabels();
  const assignmentProgress = getTrackProgress(content);

  return (
    <AssessmentQuestionSkipConfirmationModalContext.Provider
      value={assessmentQuestionSkipConfirmationModalState}
    >
      <TrackContentNavigationProvider
        page={page}
        totalPages={totalCount}
        isDisplayingBottomNavigation={showNavigationContainer}
        setDisplayBottomNavigation={setNavigationContainer}
        advance={handleAdvance}
        goBack={handleGoToPreviousPage}
      >
        <ContentTopbarPage
          showScroll
          sidebarComponentContent={({ openSidebar }) => (
            <SidebarButton onClick={openSidebar} renderContent={() => <MenuOpenOutlinedIcon />} />
          )}
          renderSidebarContent={({ closeSidebar }) => (
            <>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap={0.5}
                padding="8px 0 8px 4px"
              >
                <IconButton size="small" onClick={closeSidebar}>
                  <MenuOpenOutlinedIcon />
                </IconButton>
                <Typography variant="caption" color={colors.neutral500}>
                  {stepLabel} {page} of {totalCount}
                </Typography>

                {/* Should show "zero" progress */}
                {!isNil(assignmentProgress) && (
                  <Box width="80px" marginLeft={1}>
                    <LinearProgressWithLabel value={assignmentProgress} />
                  </Box>
                )}
              </Box>

              <HR color={colors.neutral200} />
              <TrackConsumptionItemList
                rootTrack={content}
                selectedIndex={page - 1}
                onItemClick={(i) => goToPage(i + 1)}
                refreshContent={refreshContent}
              />
            </>
          )}
          renderTopbar={
            page !== 0 && (
              <TrackConsumptionTopBar
                track={content}
                item={currentTrackItem}
                section={currentSection}
              />
            )
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            padding="0"
            id="trackConsumptionContentContainer"
          >
            <TrackItemRenderer
              renderTopSection={({ item }) => (
                <TrackItemInfoBox
                  item={item}
                  track={content}
                  completed={currentAssignmenCompleted}
                />
              )}
              item={currentTrackItem}
              section={currentSection}
              track={content}
              onItemClick={(_, i) => goToPage(i + 1)}
            />

            {showNavigationContainer && (
              <Sticky
                boundaryElement="#trackConsumptionContentContainer"
                mode="bottom"
                hideOnBoundaryHit={false}
              >
                <NavigationContainer
                  isFirstPage={page === 1}
                  isLastPage={page === totalCount}
                  isCurrentItemCompletable={isCurrentTrackItemCompletable}
                  onPrevious={handleGoToPreviousPage}
                  onAdvance={handleAdvance}
                  isLastContentItem={isLastContentItem}
                  isFinished={trackCompleted}
                />
              </Sticky>
            )}
          </Box>
        </ContentTopbarPage>
        {showRateAndCompleteModal && (
          <TrackItemRateAndCompleteModal
            handleClose={() => setShowRateAndCompleteModal(false)}
            handleNext={complete}
          />
        )}
      </TrackContentNavigationProvider>
    </AssessmentQuestionSkipConfirmationModalContext.Provider>
  );
};

export default TrackContentPage;
