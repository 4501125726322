import {
  Autocomplete,
  CardMedia,
  Typography,
  ListItem,
  ListItemButton,
  menuItemClasses,
} from '@mui/material';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import { styled, darken } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { ElementType } from 'react';

import TextInput from 'app/inputs/components/TextInput';
import { queries } from 'queries';
import { find, map } from 'vendor/lodash';

const ChannelListItem = styled(ListItem)`
  padding: 0px !important;
  border-radius: 8px;
  background-color: transparent !important;
`;

interface ChannelListOptionProps extends ListItemButtonProps {
  to?: string;
  component?: ElementType;
  backgroundColor?: string;
}

const ChannelListOption = styled(ListItemButton)<ChannelListOptionProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  height: 54px;
  border-radius: 8px;
  margin: 5px 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }

  &.${menuItemClasses.selected} {
    border: 1px solid;
    border-color: ${({ backgroundColor }) =>
      backgroundColor ? darken(backgroundColor, 0.2) : '#00000061'};
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'} !important;
    border-radius: 8px;
  }
`;

const makeOptions = (data) => {
  return map(data.results, (row) => ({
    value: row.value,
    label: row.name,
    cover: row.cover,
    color: row.color,
    name: row.name,
  }));
};

interface ChannelSelectFieldInputProps {
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: {
    error?: string;
  };
  [key: string]: any; // Other props
}

export function ChannelSelectFieldInput(prop: ChannelSelectFieldInputProps) {
  const {
    input: { value, onChange },
    meta: { error },
    ...props
  } = prop;

  const { data: channels, isLoading } = useQuery({
    ...queries.channels.list('view_mode=lite'),
    select: makeOptions,
  });

  const currentChannel = channels ? find(channels, (channel) => channel.value === value) : null;

  return (
    <Autocomplete
      size="small"
      multiple={false}
      options={channels || []}
      loading={isLoading}
      getOptionLabel={(option) => option.label}
      value={currentChannel}
      onChange={(_e, newValue) => {
        onChange(newValue?.value || null);
      }}
      renderInput={(params) => (
        <TextInput
          required
          {...params}
          label="Channels"
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(props, option) => (
        <ChannelListItem {...props} key={option.value}>
          <ChannelListOption backgroundColor={option?.color}>
            <CardMedia
              component="img"
              sx={{ width: 62, height: 34, objectFit: 'cover', borderRadius: 1 }}
              image={option.cover}
              alt={option.name}
            />
            <Typography variant="body1" component="span">
              {option.name}
            </Typography>
          </ChannelListOption>
        </ChannelListItem>
      )}
      {...props}
    />
  );
}
