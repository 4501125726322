import { useCallback } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { assignmentSchema, multipleChoiceOptionSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { toast } from 'notifications/components/NotificationCenter';
import { STATUS_DONE, STATUS_ERROR, STATUS_LOADING } from 'shared/constants';
import { get } from 'vendor/lodash';

import { QuestionResponse } from './interfaces';

interface UseCompleteQuestionProps {
  contentType: string;
  onSuccess: (questionResponse: QuestionResponse) => void;
}

const getErrorMessage = (error: any): string => {
  // If it's an application error from the CA-2.0, it will be an object with the detail key.
  // If the error is a data validation, it will be an array.
  return get(error, 'detail', null) || get(error, 0, null);
};

export const useCompleteQuestion = ({ contentType, onSuccess }: UseCompleteQuestionProps) => {
  const questionTypeMap = {
    [CONTENT_TYPES.multiple_choice_question]: [
      actions.multipleChoiceQuestionResponse.completion,
      assignmentSchema,
    ],
    [CONTENT_TYPES.text_question]: [actions.textQuestionResponse.completion, assignmentSchema],
  };

  const [actionCreator, schema] = questionTypeMap[contentType];

  const [complete, { data, status }] = useEntities(
    actionCreator,
    ({ error }) => {
      if (status === STATUS_DONE) {
        onSuccess(data);
      }
      if (status === STATUS_ERROR) {
        const errorMessage = getErrorMessage(error);
        toast.error('Failed to submit response.', errorMessage);
      }
    },
    { schema }
  );

  const completeQuestion = (assignmentId?: number, options?: object) => {
    if (!assignmentId) return;
    complete(assignmentId, options);
  };

  return {
    completeQuestion,
    questionResponse: data,
    isLoading: status === STATUS_LOADING,
  };
};

export const useFetchMultipleChoiceOptions = () => {
  const [fetchOptions, { data, status }] = useEntities(
    actions.multipleChoiceQuestion.optionsList,
    null,
    { schema: [multipleChoiceOptionSchema] }
  );

  const cachedFetchOptions = useCallback(
    (options) => {
      fetchOptions(options);
    },
    [fetchOptions]
  );

  return {
    fetchOptions: cachedFetchOptions,
    options: data,
    fetchOptionsDone: status === STATUS_DONE,
  };
};
