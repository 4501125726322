import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { React } from 'react';
import { connect } from 'react-redux';

import { ATTENDANCE_METHOD_ONLINE } from 'program/constants';
import {
  getEnrollmentsByMethod,
  getUserEnrollment,
  sessionStartsAtHasPassed,
  userIsHost as currentUserIsHost,
  userIsAttendee as currentUserIsAttendee,
  userHasRole,
} from 'program/services';
import { queries } from 'queries';
import { mapRoute } from 'services/requests';
import {
  EDIT_SESSION_ENROLLMENT_PERMISSION,
  EDIT_SESSION_FEEDBACK_PERMISSION,
  EDIT_SESSION_PERMISSION,
} from 'shared/permissions';
import { get, includes, isEmpty } from 'vendor/lodash';

import AvailableSessionArea from './AvailableSessionArea';
import EnrolledSessionArea from './EnrolledSessionArea';
import FeedbackArea from './FeedbackArea';

const useUserIsMenteeOfSessionProgram = (session, user) => {
  const { data: isMentee } = useQuery(
    queries.mentorship_program.mentees(session.program_id, null)._ctx.is_mentee(user.id)
  );
  return isMentee;
};

export const SessionCardButtons = ({
  currentUser,
  session,
  sessionIsBooked,
  handleEnrollmentModalOpen,
  handleOpenViewDetailsModal,
  handleOpenFeedbackModal,
}) => {
  const userIsHost = currentUserIsHost(session, currentUser);
  const userIsMentee = useUserIsMenteeOfSessionProgram(session, currentUser);
  const userIsAttendee = currentUserIsAttendee(session, currentUser);
  const userIsAdmin = userHasRole(currentUser, 'admin');
  const userEnrollment = getUserEnrollment(session, currentUser);
  const hasOnlineEnrollments = getEnrollmentsByMethod(session, ATTENDANCE_METHOD_ONLINE).length > 0;
  let editSessionRoute = '';
  if (session.program_id) {
    editSessionRoute = mapRoute('sessionEdit', { public_id: session.public_id });
  } else {
    editSessionRoute = mapRoute('sessionMentorshipEdit', { public_id: session.public_id });
  }

  if (
    includes(session.permissions, EDIT_SESSION_FEEDBACK_PERMISSION) ||
    (userIsHost && sessionStartsAtHasPassed(session, currentUser.timezone))
  ) {
    return (
      <FeedbackArea
        session={session}
        userIsHost={userIsHost}
        userIsAttendee={userIsAttendee}
        userEnrollment={userEnrollment}
        handleOpenFeedbackModal={handleOpenFeedbackModal}
      />
    );
  }

  if (!isEmpty(session.enrollments) && (userEnrollment || userIsHost || userIsAdmin)) {
    if (includes(session.permissions, EDIT_SESSION_ENROLLMENT_PERMISSION)) {
      return (
        <EnrolledSessionArea
          meetingUrl={session.meeting_url}
          userIsHost={userIsHost}
          userIsAdmin={userIsAdmin}
          attendanceMethod={get(userEnrollment, 'attendance_method', null)}
          editSessionRoute={editSessionRoute}
          handleOpenViewDetailsModal={handleOpenViewDetailsModal}
          sessionIsBooked={sessionIsBooked}
          hasOnlineEnrollments={hasOnlineEnrollments}
        />
      );
    }
    if (includes(session.permissions, EDIT_SESSION_PERMISSION)) {
      return (
        <EnrolledSessionArea
          userIsHost={userIsHost}
          editSessionRoute={editSessionRoute}
          handleOpenViewDetailsModal={handleOpenViewDetailsModal}
        />
      );
    }
  }

  return session.is_available ? (
    <AvailableSessionArea
      userIsHost={userIsHost}
      userIsMentee={userIsMentee}
      editSessionRoute={editSessionRoute}
      handleEnrollmentModalOpen={handleEnrollmentModalOpen}
      programType={session.program_type}
    />
  ) : null;
};

SessionCardButtons.propTypes = {
  session: PropTypes.object,
  currentUser: PropTypes.object,
  handleEnrollmentModalOpen: PropTypes.func,
  handleOpenViewDetailsModal: PropTypes.func,
  handleOpenFeedbackModal: PropTypes.func,
  sessionIsBooked: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(SessionCardButtons);
