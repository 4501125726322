import React, { useCallback, useEffect } from 'react';

import { CONTENT_TYPES } from 'app/catalog/constants';
import actions from 'entities/actions';
import { userProfileSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import colors from 'services/colors';
import { formatDate } from 'services/datetime';
import { ContentItem } from 'shared-content-item/interfaces';
import { get, isNil, isNumber, replace, toNumber, trimEnd } from 'vendor/lodash';
import { Avatar, Box, Typography, Stack } from 'vendor/mui';
import { TrendingFlatIcon, CheckCircleIcon } from 'vendor/mui-icons';

const useGetAssignmentData = (contentItem: ContentItem) => {
  const [fetchUser, { data: assignedBy }] = useEntities(actions.userData.retrieveDetails, null, {
    schema: userProfileSchema,
  });

  const createdById = get(contentItem, 'user_assignment.created_by_id', null);

  const fetchAssignedBy = useCallback(() => {
    if (isNil(createdById)) return;
    fetchUser(createdById, { view_mode: 'lite' });
  }, [createdById, fetchUser]);

  useEffect(() => {
    fetchAssignedBy();
  }, [fetchAssignedBy]);

  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  if (contentItem?.user_assignment) {
    const assignment = contentItem?.user_assignment;
    return {
      assignedBy,
      assignedDate: get(assignment, 'created_at', null),
      startedDate: get(assignment, 'progress.started_at', null),
      dueDate: get(assignment, 'due_at', null),
      completedDate: get(assignment, 'completion.completed_at', null),
      droppedDate: get(assignment, 'drop.dropped_at', null),
    };
  }

  const assignment = contentItem?.assignment;
  return {
    assignedBy: get(assignment, 'created_by', null),
    assignedDate: get(assignment, 'created', null),
    startedDate: get(assignment, 'started_at', null),
    dueDate: get(assignment, 'due_date', null),
    completedDate: get(assignment, 'completed_datetime', null),
    droppedDate: get(assignment, 'drop.dropped_at', null),
  };
};

export interface ContentItemHeaderTimelineProps {
  contentItem: ContentItem;
}

export const ContentItemHeaderTimeline = (props: ContentItemHeaderTimelineProps) => {
  const { contentItem } = props;
  const { content_type: contentType } = contentItem;

  const isAssessment = contentType === CONTENT_TYPES.assessment;
  const isEvent = contentType === CONTENT_TYPES.event;

  const assignmentScore = toNumber(get(contentItem, 'user_assignment.completion.score', '0')) * 100;

  const { assignedBy, assignedDate, startedDate, dueDate, completedDate, droppedDate } =
    useGetAssignmentData(contentItem);

  const Text = (props: React.ComponentProps<typeof Typography>) => (
    <Typography variant="body1" fontSize="13px" {...props} />
  );

  const timeline: React.ReactElement[] = [];

  if (assignedDate) {
    if (isEvent) {
      timeline.push(<Text key="assignedDate">{`Enrolled ${formatDate(assignedDate)}`}</Text>);
    } else {
      timeline.push(<Text key="assignedDate">{`Assigned ${formatDate(assignedDate)}`}</Text>);
    }
  }

  if (startedDate) {
    if (isEvent) {
      timeline.push(<Text key="startedDate">{`Attended ${formatDate(startedDate)}`}</Text>);
    } else {
      timeline.push(<Text key="startedDate">{`Started ${formatDate(startedDate)}`}</Text>);
    }
  }

  if (droppedDate) {
    timeline.push(<Text key="droppedDate">{`Dropped ${formatDate(droppedDate)}`}</Text>);
  }

  if (dueDate && !completedDate) {
    timeline.push(<Text key="dueDate">{`Due ${formatDate(dueDate)}`}</Text>);
  }

  if (completedDate) {
    timeline.push(
      <React.Fragment key="completedDate">
        <CheckCircleIcon fontSize="small" htmlColor={colors.success700} />
        <Text fontWeight={700} color={colors.success700}>{`Completed ${formatDate(
          completedDate
        )}`}</Text>
      </React.Fragment>
    );
  }

  if (isAssessment && completedDate && isNumber(assignmentScore)) {
    timeline.push(
      <Text key="score">{`Score: ${replace(
        trimEnd(assignmentScore.toFixed(2), '0'),
        /\.$/,
        ''
      )}%`}</Text>
    );
  }

  return (
    <Box width="auto" display="flex" alignItems="center" padding="20px 20px 20px 0px">
      {assignedBy && (
        <Avatar
          src={assignedBy.profile_image}
          alt={assignedBy.display_name}
          sx={{ width: 24, height: 24, marginRight: '4px' }}
        />
      )}
      <Stack
        direction="row"
        divider={
          <TrendingFlatIcon
            fontSize="small"
            htmlColor={colors.neutral400}
            sx={{ margin: '0 4px' }}
          />
        }
      >
        {timeline}
      </Stack>
    </Box>
  );
};
