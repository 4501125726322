import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import AssessmentCompletionModal from 'assessments/components/AssessmentCompletionModal';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { CONTENT_TYPES, LEARNING_TYPES } from 'catalog/constants';
import TrackCompletionModal from 'features/tracks/components/TrackCompletionModal';
import Page404 from 'scenes/404';
import TrackContentPage from 'scenes/TrackPage/TrackContentPage';
import TrackDetailPage from 'scenes/TrackPage/TrackDetailPage';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { HTTP_404_NOT_FOUND, STATUS_DONE } from 'shared/constants';
import {
  usePublicIdFromURL,
  useQueryParams,
  useRouterQueryUtils,
  useSetDetailedObject,
} from 'shared/hooks';
import { ContentItemListenerContext } from 'stand-alone-shared/context-providers/ContentItemListenerContext';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { get, isEmpty } from 'vendor/lodash';

export interface TrackPageContainerProps {
  track: any;
  fetchTrack: (trackId, options?: { view_mode?: string }) => any;
  status: string;
  statusCode?: number;
  entityType:
    | typeof LEARNING_TYPES.assessments
    | typeof LEARNING_TYPES.scheduled_tracks
    | typeof LEARNING_TYPES.tracks;
}

function TrackPageContainer(props: TrackPageContainerProps) {
  const { track, fetchTrack, status, statusCode, entityType } = props;

  const { publicId: trackId } = usePublicIdFromURL();

  const [showCompletionModal, setShowCompletionModal] = useState(true);

  const history = useHistory();

  const { addToQueryString, removeFromQueryString } = useRouterQueryUtils();

  const { page: pageParam } = useQueryParams();
  const page = pageParam ? Number(pageParam) : 0;

  const { totalCount } = useComposableTrackContext();
  const isAwaitingFeedback = get(track, 'user_assignment.awaiting_feedback', false);
  const isCompleted = get(track, 'user_assignment.state') === ASSIGNMENT_STATES.completed;
  const isAssessment = get(track, 'content_type') === CONTENT_TYPES.assessment;

  const fetchContent = useCallback(
    () => fetchTrack(trackId, { view_mode: 'detail' }),
    [trackId, fetchTrack]
  );

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const validatePage = useCallback(() => {
    if (status === STATUS_DONE && page > totalCount) {
      removeFromQueryString(['page'], true);
    }
  }, [status, page, removeFromQueryString, totalCount]);

  useEffect(() => {
    validatePage();
  }, [validatePage]);

  const listenerValue = useRef({
    onChange: (props: any) => {
      const goToFirstPage = get(props, 'goToFirstPage');
      fetchTrack(trackId, { view_mode: 'detail' });
      if (goToFirstPage) {
        addToQueryString({ page: 1 });
      }
    },
    // onDelete is called on DeleteStandAloneModal when a track item is deleted from the track
    // consumption page, or when a track/scheduled track/assessment is deleted from its detail page.
    onDelete: (props: any) => {
      const content = get(props, 'content');
      if (content?.public_id === trackId) {
        const isScheduleTrack = get(content, 'content_type') === CONTENT_TYPES.scheduled_track;
        const parentTrackPublicIdAndSlug = get(content, 'original_track.public_id_and_slug');

        history.push(
          isScheduleTrack && parentTrackPublicIdAndSlug
            ? mapRoute('trackDetails', {
                public_id_and_slug: parentTrackPublicIdAndSlug,
              })
            : mapRoute('unifiedCatalogList')
        );
      } else {
        window.location.reload();
      }
    },
  });

  useSetDetailedObject({ id: trackId }, entityType, []);

  const handleCompletionModalClose = () => setShowCompletionModal(false);

  if (statusCode === HTTP_404_NOT_FOUND) return <Page404 />;
  if (isEmpty(track) && status !== STATUS_DONE) return <Loading />;

  const shouldDisplayCompletionModal = isCompleted && isAwaitingFeedback && showCompletionModal;

  return (
    <ContentItemListenerContext.Provider value={listenerValue.current}>
      {page === 0 ? (
        <TrackDetailPage
          {...(isAssessment && { assessment: track })}
          {...(track.content_type === CONTENT_TYPES.scheduled_track && { scheduledTrack: track })}
          {...(track.content_type === CONTENT_TYPES.track && { track })}
          refreshContent={fetchContent}
        />
      ) : (
        <TrackContentPage
          page={page}
          {...(isAssessment && { assessment: track })}
          {...(track.content_type === CONTENT_TYPES.scheduled_track && { scheduledTrack: track })}
          {...(track.content_type === CONTENT_TYPES.track && { track })}
          refreshContent={fetchContent}
        />
      )}

      {shouldDisplayCompletionModal && !isAssessment && (
        <TrackCompletionModal track={track} handleClose={handleCompletionModalClose} />
      )}
      {isAssessment && shouldDisplayCompletionModal && (
        <AssessmentCompletionModal assessment={track} handleClose={handleCompletionModalClose} />
      )}
    </ContentItemListenerContext.Provider>
  );
}

export default TrackPageContainer;
