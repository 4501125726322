import { Assessment } from 'app/assessments/interfaces';
import { get, isEmpty } from 'vendor/lodash';

export function getIsLastQuestionInAssessment(currentQuestionId: number, assessment: Assessment) {
  const track_items = get(assessment, 'track_items', []);

  if (isEmpty(track_items)) return false;

  const lastTrackItem = track_items[track_items.length - 1];
  const lastQuestionId = get(lastTrackItem, 'content_item.id');

  return lastQuestionId === currentQuestionId;
}
