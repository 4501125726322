import colors from 'services/colors';
import { TrackItemCardList } from 'tracks/components/TrackItemCardList';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { Track, trackTypes } from 'tracks/interfaces';
import { size } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

interface SectionlessTrackItemSummaryProps {
  track: Track;
}

export const SectionlessTrackItemSummary = ({ track }: SectionlessTrackItemSummaryProps) => {
  const pageSize = 9;

  const { filterTrackItems, getTrackItemIndex } = useComposableTrackContext();
  const trackItems = filterTrackItems(track);

  return (
    <Box marginY={2.5}>
      <Box marginBottom={2}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Summary</Typography>
        <Typography sx={{ fontSize: 14, color: colors.neutral500 }}>
          {size(trackItems)} {size(trackItems) === 1 ? 'item' : 'items'}
        </Typography>
      </Box>
      <TrackItemCardList
        items={trackItems}
        getTrackItemIndex={getTrackItemIndex}
        pageSize={pageSize}
        isSummary
        trackType={track.content_type as trackTypes}
      />
    </Box>
  );
};
