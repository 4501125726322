import React, { useEffect } from 'react';

import { ASSIGNMENT_STATES } from 'app/assignments/constants';
import { useAssignmentsActions } from 'app/assignments/hooks';
import Pill from 'app/shared/components/Pill';
import { CONTENT_TYPES } from 'catalog/constants';
import { useContentItemAssignment } from 'common/hooks/useContentItemAssignment';
import ContentItemContextMenu from 'scenes/ContentItemContextMenu/ContentItemContextMenu';
import colors from 'services/colors';
import {
  getCompletionCheckboxTooltip,
  shouldEnableToggleCompletionCheckbox,
} from 'shared-content-item/services';
import ConditionalWrapper from 'shared/components/ConditionalWrapper';
import { useToggles } from 'shared/hooks';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { TrackItem } from 'tracks/interfaces.js';
import { getTrackItemBorderStyle } from 'tracks/services';
import { get, has, isNumber } from 'vendor/lodash';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Stack,
  Tooltip,
  Typography,
} from 'vendor/mui';
import { useIsProgressableVideo } from 'video/hooks';

import { AssignmentStateIcon } from './AssignmentStateIcon';
import { useTrackItemCardStyle } from './hooks';
import NestedTrackItemCardWrapper from './NestedTrackItemCardWrapper';
import { TrackItemCardSubheader } from './TrackItemCardSubheader';

function useCurrentAssignment(contentItem: TrackItem['content_item']) {
  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignmentId = contentItem?.user_assignment?.id || contentItem?.assignment?.id;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const assignmentFromStore = useContentItemAssignment(assignmentId!);
  const assignment = assignmentFromStore || contentItem?.user_assignment || contentItem?.assignment;

  return assignment;
}

type CardActionWrapperProps = {
  onClick?: () => void;
  children: React.ReactNode;
};

function CardActionWrapper(props: CardActionWrapperProps) {
  const { onClick, children } = props;

  if (onClick) {
    return (
      <CardActionArea
        onClick={onClick}
        sx={{
          paddingX: 1,
          paddingY: 0.5,
          display: 'flex',
          justifyContent: 'left',
        }}
      >
        {children}
      </CardActionArea>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
      }}
    >
      {children}
    </Box>
  );
}

type TrackType =
  | typeof CONTENT_TYPES.track
  | typeof CONTENT_TYPES.scheduled_track
  | typeof CONTENT_TYPES.assessment;

interface TrackItemCardProps {
  item: TrackItem;
  itemIndex?: number | undefined;
  selected?: boolean;
  isSummary?: boolean;
  isCheckListItem?: boolean;
  onClick?: () => void;
  width?: string;
  cover?: { alt: string; src: string };
  showAssignmentState?: boolean;
  refreshContent?: CallableFunction;
  trackType?: TrackType;
  isPreviewMode?: boolean;
}

const TrackItemCard = (props: TrackItemCardProps) => {
  const {
    item,
    itemIndex,
    selected = false,
    isSummary = false,
    isCheckListItem = false,
    showAssignmentState = true,
    trackType = CONTENT_TYPES.track,
    onClick: onClickProp,
    refreshContent,
    isPreviewMode = false,
  } = props;
  const { toggle_composable_tracks: toggleComposableTracks } = useToggles();

  const { content_item: contentItem } = item;
  const {
    name,
    content_type: contentType,
    cover,
    default_cover: defaultCover,
    cover_url: coverUrl,
  } = contentItem;

  // check if content item is a progressable video or not

  const isProgressableVideo = useIsProgressableVideo(contentItem);
  // Set whether completable videos by updating attribute which isn't (yet) plumbed from backend
  if (isProgressableVideo) {
    contentItem.fraction_watched_for_completion = 0;
  }

  const assignment = useCurrentAssignment(contentItem);

  const { parentTrack, getStepLabels } = useComposableTrackContext();
  const parentTrackIsAssessment = parentTrack.content_type === CONTENT_TYPES.assessment;
  const { singular: stepLabel } = getStepLabels();
  const showStepLabel = parentTrackIsAssessment && !isSummary && isNumber(itemIndex);

  const cardStyle = useTrackItemCardStyle({
    assignment,
    isSelected: selected,
    showAssignmentState,
  });

  const titleExtraProps = !isSummary ? { noWrap: true, maxLines: 3 } : {};
  const isTrack = contentType === CONTENT_TYPES.track;
  const coverMedia = cover ?? coverUrl ?? defaultCover;

  const [isAssignmentCompleted, setIsAssignmentCompleted] = React.useState(
    assignment?.state === ASSIGNMENT_STATES.completed
  );

  useEffect(() => {
    setIsAssignmentCompleted(assignment?.state === ASSIGNMENT_STATES.completed);
  }, [assignment]);

  const handleAssignmentCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      complete();
    } else {
      undoCompletion();
    }

    refreshContent?.();
    setIsAssignmentCompleted(isChecked);
  };

  const { retrieve: trackRetrieve } = useAssignmentsActions(
    undefined,
    get(parentTrack, 'user_assignment.id')
  );

  const { complete, undoCompletion } = useAssignmentsActions(
    contentItem,
    get(assignment, 'id'),
    () => (has(parentTrack, 'user_assignment') ? trackRetrieve() : null)
  );

  const shouldDisplayAssessmentState = showAssignmentState && !isCheckListItem;
  const shouldDisplayNestedTrackItemCard = toggleComposableTracks && isTrack;

  return (
    <ConditionalWrapper
      condition={shouldDisplayNestedTrackItemCard}
      wrapper={(children) => (
        <NestedTrackItemCardWrapper cardStyle={cardStyle}>{children}</NestedTrackItemCardWrapper>
      )}
    >
      <Card
        variant="outlined"
        sx={{
          ...cardStyle,
          zIndex: 2,
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          ...getTrackItemBorderStyle(item),
        }}
      >
        <CardActionWrapper onClick={onClickProp}>
          {isSummary && coverMedia && (
            <CardMedia sx={{ width: '50px', height: '32px' }} image={coverMedia} title={name} />
          )}

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 0.5,

              ...(!isSummary && { padding: 1 }),
            }}
          >
            <CardHeader
              title={
                <Stack spacing={0}>
                  {showStepLabel && (
                    <Typography variant="caption" color={colors.neutral600}>
                      {stepLabel} {itemIndex + 1}
                    </Typography>
                  )}

                  <Tooltip title={name} disableHoverListener={isSummary || isPreviewMode} arrow>
                    {/* Add a simple span tag to make the MUI Tooltip work with other components
                besides buttons, such as Typography */}
                    <span>
                      <Typography variant="body2" {...titleExtraProps}>
                        {name}
                      </Typography>
                    </span>
                  </Tooltip>
                </Stack>
              }
              subheader={!parentTrackIsAssessment && <TrackItemCardSubheader item={item} />}
              sx={{ padding: 0 }}
            />
            {shouldDisplayAssessmentState && (
              <AssignmentStateIcon isSelected={selected} assignment={assignment} />
            )}
          </CardContent>
        </CardActionWrapper>
        {isCheckListItem && assignment?.id && !isPreviewMode && (
          <Stack direction="column" alignItems="start" alignSelf="stretch">
            <Tooltip title={getCompletionCheckboxTooltip(contentItem, assignment)}>
              {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
              <span>
                <Checkbox
                  checked={isAssignmentCompleted}
                  onChange={handleAssignmentCheckboxChange}
                  disabled={!shouldEnableToggleCompletionCheckbox(contentItem, assignment)}
                />
              </span>
            </Tooltip>
          </Stack>
        )}
        {trackType === CONTENT_TYPES.scheduled_track && contentType === CONTENT_TYPES.eventtype && (
          <Box display="flex" alignItems="center" paddingRight="12px">
            <Pill variant="warning200" round={false} icon="calendar" label="Not Scheduled" />
          </Box>
        )}
        {isSummary && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentItemContextMenu content={contentItem} isTrackItem />
          </Box>
        )}
      </Card>
    </ConditionalWrapper>
  );
};

export default TrackItemCard;
