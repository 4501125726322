import React, { useMemo, Fragment } from 'react';

import { getSpotsStatus } from 'app/event-shared/services';
import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING, CONTENT_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import { LinkedContent } from 'linkedcontent/interfaces';
import colors from 'services/colors';
import { formatDuration, isEmptyDuration } from 'services/datetime';
import { ContentItem } from 'shared-content-item/interfaces';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { get, isEmpty, map, toLower, filter, includes } from 'vendor/lodash';
import { Typography, Box } from 'vendor/mui';
import { InventoryIcon, LockIcon, StarIcon, VisibilityOffIcon } from 'vendor/mui-icons';

type SegmentPlugin = {
  key: string;
  check: (contentItem: ContentItem) => boolean;
  render: (contentItem: ContentItem) => React.ReactElement;
};

type SegmentPluginProps = {
  contentItem: ContentItem;
};

const Container = (props: React.ComponentProps<typeof Box>) => (
  <Box display="flex" gap="4px" alignItems="center" {...props} />
);

const Subtitle = (props: React.ComponentProps<typeof Typography>) => (
  <Typography variant="subtitle1" color={colors.neutral700} alignItems="center" {...props} />
);

const ContentTypeLabel = (props: SegmentPluginProps) => {
  const { contentItem } = props;
  const contentType = contentItem.content_type;

  const learningTypeLabels = useLearningTypeLabels();
  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[contentType];

  return (
    <Subtitle key="content-type">
      {learningTypeLabels[learningType] ?? learningType ?? contentType}
    </Subtitle>
  );
};

const HiddenLabel = (
  <Container key="hidden">
    <VisibilityOffIcon htmlColor={colors.learningTask600} />
    <Subtitle color={colors.learningTask600}>Hidden</Subtitle>
  </Container>
);

const RestrictedLabel = (
  <Container key="restricted">
    <LockIcon htmlColor={colors.learningTask600} />
    <Subtitle color={colors.learningTask600}>Restricted</Subtitle>
  </Container>
);

const ArchivedLabel = (props: SegmentPluginProps) => {
  const { contentItem } = props;

  const isScheduled = includes(
    [CONTENT_TYPES.event, CONTENT_TYPES.scheduled_track],
    contentItem.content_type
  );

  return (
    <Container key="archived">
      <InventoryIcon htmlColor={colors.error700} />
      <Subtitle color={colors.error700}>{isScheduled ? 'Cancelled' : 'Archived'}</Subtitle>
    </Container>
  );
};

const StepsLabel = (props: SegmentPluginProps) => {
  const { contentItem } = props;

  const steps = get(contentItem, 'number_completable_track_items') as number;
  const { getStepLabels } = useComposableTrackContext();
  const { singular, plural } = getStepLabels();

  return <Subtitle key="steps">{`${steps} ${toLower(steps > 1 ? plural : singular)}`}</Subtitle>;
};

const parseRating = (average_rating: number) => {
  if (average_rating) return (Math.round(average_rating * 10) / 10).toFixed(1);
  return '';
};

const FeedbackLabel = (props: SegmentPluginProps) => {
  const { contentItem } = props;

  const feedback =
    contentItem.avg_feedback_rating ??
    contentItem.average_feedback_rating ??
    contentItem.feedback_rating;

  return (
    <Container key="feedback">
      <StarIcon fontSize="small" htmlColor={colors.rating600} />
      <Subtitle>{parseRating(feedback!)}</Subtitle>
    </Container>
  );
};

type SpotLabelProps = {
  spotsLeft: number;
  isUnlimited: boolean;
  type: string;
};

const SpotLabel = (props: SpotLabelProps) => {
  const { spotsLeft, isUnlimited, type } = props;

  return (
    <Subtitle>
      {isUnlimited
        ? `Unlimited ${type} spots`
        : `${spotsLeft} ${type} ${spotsLeft > 1 ? 'spots' : 'spot'}`}
    </Subtitle>
  );
};

const EventSpotsLabel = (props: SegmentPluginProps) => {
  const { contentItem } = props;

  const isLocal = get(contentItem, 'is_local', false);
  const isOnline = get(contentItem, 'is_online', false);

  const { hasUnlimitedLocalSpots, localSpotsLeft, hasUnlimitedOnlineSpots, onlineSpotsLeft } =
    getSpotsStatus(contentItem);

  if (hasUnlimitedLocalSpots && hasUnlimitedOnlineSpots) {
    return <Subtitle>Unlimited spots</Subtitle>;
  }

  const canDisplayLocalSpots = isLocal && (hasUnlimitedLocalSpots || localSpotsLeft > 0);
  const canDisplayOnlineSpots = isOnline && (hasUnlimitedOnlineSpots || onlineSpotsLeft > 0);

  return (
    <>
      {canDisplayLocalSpots && (
        <SpotLabel
          spotsLeft={localSpotsLeft}
          isUnlimited={hasUnlimitedLocalSpots}
          type="in-person"
        />
      )}
      {canDisplayLocalSpots && canDisplayOnlineSpots && <span>•</span>}
      {canDisplayOnlineSpots && (
        <SpotLabel
          spotsLeft={onlineSpotsLeft}
          isUnlimited={hasUnlimitedOnlineSpots}
          type="online"
        />
      )}
    </>
  );
};

const segmentPlugins: SegmentPlugin[] = [
  {
    key: 'content-type',
    check: (contentItem) => !!contentItem.content_type,
    render: (contentItem) => <ContentTypeLabel contentItem={contentItem} />,
  },
  {
    key: 'provider',
    check: (contentItem) => !!(contentItem as LinkedContent).provider,
    render: (contentItem) => (
      <Subtitle key="provider">Provided by {get(contentItem, 'provider')}</Subtitle>
    ),
  },
  {
    key: 'duration',
    check: (contentItem) => contentItem.duration != null && !isEmptyDuration(contentItem.duration),
    render: (contentItem) => (
      <Subtitle key="duration">{formatDuration(get(contentItem, 'duration'))}</Subtitle>
    ),
  },
  {
    key: 'event-spots',
    check: (contentItem) => {
      return contentItem.content_type === CONTENT_TYPES.event;
    },
    render: (contentItem) => <EventSpotsLabel contentItem={contentItem} />,
  },
  {
    key: 'steps',
    check: (contentItem) => get(contentItem, 'number_completable_track_items') != null,
    render: (contentItem) => <StepsLabel contentItem={contentItem} />,
  },
  {
    key: 'feedback',
    check: (contentItem) =>
      !!contentItem.avg_feedback_rating ||
      !!contentItem.average_feedback_rating ||
      !!contentItem.feedback_rating,
    render: (contentItem) => <FeedbackLabel contentItem={contentItem} />,
  },
  {
    key: 'hidden',
    check: (contentItem) => !!contentItem.is_hidden,
    render: () => HiddenLabel,
  },
  {
    key: 'group-restricted',
    check: (contentItem) => !isEmpty(contentItem.groups),
    render: () => RestrictedLabel,
  },
  {
    key: 'archived',
    check: (contentItem) => contentItem.is_archived || Boolean(contentItem.archival),
    render: (contentItem) => <ArchivedLabel contentItem={contentItem} />,
  },
];

export interface ContentItemSubtitleProps {
  contentItem: ContentItem;
}

export const ContentItemHeaderSubtitle = (props: ContentItemSubtitleProps) => {
  const { contentItem } = props;

  const segmentPluginsToRender = useMemo(() => {
    return filter(segmentPlugins, (plugin) => plugin.check(contentItem));
  }, [contentItem]);

  return (
    <Box display="flex" gap="8px" rowGap="8px" alignItems="center" flexWrap="wrap">
      {map(segmentPluginsToRender, (plugin, idx) => (
        <Fragment key={plugin.key}>
          {plugin.render(contentItem)}
          {idx < segmentPluginsToRender.length - 1 && <span key={`span-${idx}`}>•</span>}
        </Fragment>
      ))}
    </Box>
  );
};

export default ContentItemHeaderSubtitle;
