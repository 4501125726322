/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/first */
/* eslint-disable no-underscore-dangle */

if (process.env.NODE_ENV === 'production' && window.WEBPACK_PUBLIC_PATH) {
  // @ts-ignore
  __webpack_public_path__ = window.WEBPACK_PUBLIC_PATH;
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as browserRum from '@datadog/browser-rum';
// @ts-ignore
import React from 'react';
import { render } from 'react-dom';

// eslint-disable-next-line import/extensions
import { LicenseInfo } from 'vendor/mui';

import 'what-input';

import './ie-polyfill';

import './sass/style.scss';

import ReactApp, { Container, elementId, UserAddButtonContainer, PosterContainer } from './scenes';

if (
  window.ENVIRONMENT_NAME !== 'Local' &&
  window.DD_RUM_APPLICATION_ID !== '' &&
  window.DD_RUM_CLIENT_TOKEN !== ''
) {
  browserRum.datadogRum.init({
    applicationId: window.DD_RUM_APPLICATION_ID,
    clientToken: window.DD_RUM_CLIENT_TOKEN,
    site: window.DD_RUM_SITE,
    service: window.DD_RUM_SERVICE_NAME,
    env: window.ENVIRONMENT_NAME,
    version: window.DD_RELEASE,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.plusplus\.app/],
  });
}

let children: React.ReactNode = null;
const element = document.getElementById(elementId);
if (elementId === 'user-add-button') {
  render(<UserAddButtonContainer />, element);
} else if (elementId === 'poster-app') {
  render(<PosterContainer />, element);
} else if (element) {
  children = Container;
}

LicenseInfo.setLicenseKey(window.MUI_LICENSE_KEY);

const reactApp = document.getElementById('react-app');
if (reactApp) {
  render(<ReactApp>{children}</ReactApp>, reactApp);
}

if (module.hot) {
  module.hot.accept('./scenes/index');
}
