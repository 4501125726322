import { useState } from 'react';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import { LEARNING_TYPES } from 'catalog/constants';
import { launchCodelabRequest } from 'codelab/services';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { ContentItem } from 'shared-content-item/interfaces';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import ContentRating from 'stand-alone-shared/components/ContentRating';
import { get, isNil } from 'vendor/lodash';
import { OpenInNewOutlinedIcon } from 'vendor/mui-icons';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

interface PrimaryActionButtonProps {
  content: ContentItem;
}

const PrimaryActionButton = ({ content }: PrimaryActionButtonProps) => {
  const [isCodelabLaunchLoading, setIsCodelabLaunchLoading] = useState(false);
  const [isRatingContent, setRatingContent] = useState(false);
  const { trackActivity } = useMetrics();
  const { assignment } = content;
  const assignmentState = get(assignment, 'state');
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);
  const { rate, isLoading } = useAssignmentsActionsContext();

  const handleCodelabLaunch = () => {
    trackActivity(METRICS_ACTIVITIES.CODELAB_LAUNCH, {
      codelabId: content.id,
      firstLaunch: isNil(content?.assignment?.version),
    });
    setIsCodelabLaunchLoading(true);
    launchCodelabRequest(content, () => {
      setIsCodelabLaunchLoading(false);
    });
  };

  return (
    <>
      {assignmentState === ASSIGNMENT_STATES.completed && (
        <>
          <Container>
            {currentRating > 0 && (
              <>
                <RatingContainer>
                  <Rating value={currentRating} readOnly />
                </RatingContainer>
                <ButtonLink
                  onClick={() => setRatingContent(true)}
                  variant="primary"
                  disabled={isLoading}
                >
                  Edit
                </ButtonLink>
              </>
            )}
            {currentRating <= 0 && (
              <RateButton
                size="medium"
                onClick={() => setRatingContent(true)}
                fullWidth
                disabled={isLoading}
              >
                Rate
              </RateButton>
            )}
          </Container>
          {isRatingContent && (
            <ContentRating
              content={content}
              contentType={LEARNING_TYPES.codelabs}
              onSubmitFunc={rate}
              handleClose={() => setRatingContent(false)}
            />
          )}
        </>
      )}
      <Container>
        <Button
          variant="contained"
          startIcon={<OpenInNewOutlinedIcon />}
          disabled={isCodelabLaunchLoading || isLoading}
          fullWidth
          onClick={handleCodelabLaunch}
        >
          {isCodelabLaunchLoading
            ? 'Launching...'
            : assignmentState === ASSIGNMENT_STATES.in_progress
            ? 'Continue'
            : assignmentState === ASSIGNMENT_STATES.completed
            ? 'Relaunch'
            : 'Launch'}
        </Button>
      </Container>
    </>
  );
};

export default PrimaryActionButton;
