import styled from 'styled-components';

import Text from 'app/shared/components/Text';
import BaseContainer from 'navigation/components/BaseContainer';
import colors from 'services/colors';
import BackgroundPanel from 'shared/components/BackgroundPanel';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import TrackItemCard from 'tracks/components/TrackItemCard';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { FullTrackSection, TrackItem, TrackSection } from 'tracks/interfaces';
import { map, size, toLower } from 'vendor/lodash';
import { Paper, Stack } from 'vendor/mui';

const ContentItemBackgroundPanel = styled(BackgroundPanel)`
  left: 0;
  top: -40px;
`;

interface TrackSectionDetailProps {
  section: TrackSection;
  currentTrackSectionIndex: number;
  trackSectionsCount: number;
  getTrackItemIndex: (trackId: string, contentItemId: string) => number;
  onItemClick?: (item: TrackItem | FullTrackSection, index: number) => void;
}

const TrackSectionDetailPage = ({
  section,
  currentTrackSectionIndex,
  trackSectionsCount,
  getTrackItemIndex,
  onItemClick,
}: TrackSectionDetailProps) => {
  const { description, items } = section;
  const stepsCount = size(items);

  const handleSectionItemClick = (item, itemPageIndex) => onItemClick?.(item, itemPageIndex);

  const { getStepLabels } = useComposableTrackContext();
  const { singular: stepLabelSingular, plural: stepLabelPlural } = getStepLabels();

  return (
    <BaseContainer>
      <ContentItemBackgroundPanel height="120px" />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          width: '100%',
          padding: '16px 38px 20px 30px',
          marginTop: 5,
          borderRadius: '8px',
        }}
      >
        <Stack flex={1} spacing={2}>
          <Stack flex={1} spacing={0.5}>
            <Text medium size="h2" color={colors.neutral900}>
              {section.name}
            </Text>
            <Text size="h5" color={colors.neutral700}>
              Section {currentTrackSectionIndex} of {trackSectionsCount} • {stepsCount}
              {stepsCount === 1 ? ` ${toLower(stepLabelSingular)}` : ` ${toLower(stepLabelPlural)}`}
            </Text>
          </Stack>

          {description && <ContentDescription noPadding description={description} />}

          {map(items, (item) => {
            const index = getTrackItemIndex(item.track_id!, item.content_item.public_id);

            return (
              <TrackItemCard
                item={item}
                onClick={() => handleSectionItemClick(item, index)}
                isSummary
              />
            );
          })}
        </Stack>
      </Paper>
    </BaseContainer>
  );
};

export default TrackSectionDetailPage;
