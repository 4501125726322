import { useEffect, useState } from 'react';

import { CONTENT_TYPES } from 'catalog/constants';
import colors from 'services/colors';
import { useIsPreviewQueryParam } from 'shared/hooks';
import TrackItemCard from 'tracks/components/TrackItemCard';
import { TrackSectionCircularProgress } from 'tracks/components/TrackSectionProgress';
import { FullTrackSection } from 'tracks/interfaces.js';
import { filter, includes, isEmpty, map } from 'vendor/lodash';
import { Box, Card, CardActionArea, CardHeader, IconButton, Tooltip, Typography } from 'vendor/mui';
import { KeyboardArrowDownIcon, KeyboardArrowRightIcon } from 'vendor/mui-icons';

interface TrackConsumptionSectionListProps {
  section: FullTrackSection;
  selectedIndex: number;
  onItemClick?: (index: number) => void;
  getTrackItemIndex: (trackId: string, contentItemId: string) => number;
  getTrackSectionIndex: (trackId: string, sectionId: string) => number;
  userIsAssignedToTrack: boolean;
  sectionCount: number;
  showAssignmentState?: boolean;
  refreshContent: CallableFunction;
}

const TrackConsumptionSectionList = (props: TrackConsumptionSectionListProps) => {
  const {
    section,
    selectedIndex,
    onItemClick,
    getTrackItemIndex,
    getTrackSectionIndex,
    userIsAssignedToTrack,
    sectionCount,
    showAssignmentState,
    refreshContent,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const sectionPageIndex = getTrackSectionIndex(section.track_id!, section.id);
  const isSectionSelected = selectedIndex === sectionPageIndex;
  const isSectionItemSelected = !isEmpty(
    filter(
      section?.items,
      (item) => selectedIndex === getTrackItemIndex(item.track_id!, item.content_item.public_id)
    )
  );

  useEffect(() => {
    if (isSectionSelected || isSectionItemSelected) {
      setIsOpen(true);
    }
  }, [isSectionSelected, isSectionItemSelected]);

  const { id, name, items } = section;

  const handleSectionClick = () => {
    onItemClick?.(sectionPageIndex);
    setIsOpen(true);
  };

  const handleSectionItemClick = (itemPageIndex) => onItemClick?.(itemPageIndex);
  const isPreviewMode = useIsPreviewQueryParam();

  if (isEmpty(items)) return null;

  return (
    <Box width="100%" bgcolor={isOpen ? colors.neutral100 : colors.neutral50}>
      <Card
        key={id}
        {...(!isOpen && { variant: 'outlined' })}
        sx={{
          width: '100%',
          borderColor: isSectionSelected ? colors.neutral600 : colors.neutral200,
          ...(isOpen
            ? { backgroundColor: colors.neutral100, boxShadow: 'none' }
            : { borderLeft: 'none', borderRight: 'none', borderRadius: 0 }),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          gap={0.5}
          padding={0.5}
          sx={{
            ...(isSectionSelected && { backgroundColor: colors.neutral200 }),
          }}
        >
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </IconButton>

          <CardActionArea onClick={handleSectionClick} sx={{ width: 'auto' }}>
            <CardHeader
              title={
                <Box display="flex" alignContent="center" gap={0.5}>
                  {/* Add a simple span tag to make the MUI Tooltip work with other components
                besides buttons, such as Typography */}
                  <Tooltip title={name} disableHoverListener={!name} arrow>
                    <span>
                      <Typography noWrap width="150px" variant="subtitle2">
                        {name}
                      </Typography>
                    </span>
                  </Tooltip>
                </Box>
              }
              sx={{ padding: 0 }}
            />
          </CardActionArea>

          <TrackSectionCircularProgress
            section={section}
            userIsAssignedToTrack={userIsAssignedToTrack}
          />
        </Box>
      </Card>

      {isOpen && (
        <Box display="flex" flexDirection="column" gap={1} padding={1}>
          {map(items, (item) => {
            const index = getTrackItemIndex(item.track_id!, item.content_item.public_id);
            return (
              <TrackItemCard
                key={item.content_item.public_id}
                item={item}
                itemIndex={index - sectionCount}
                selected={selectedIndex === index}
                onClick={() => handleSectionItemClick(index)}
                isCheckListItem={
                  !includes(
                    [
                      CONTENT_TYPES.question,
                      CONTENT_TYPES.text_question,
                      CONTENT_TYPES.multiple_choice_question,
                    ],
                    item.content_item.content_type
                  )
                }
                showAssignmentState={showAssignmentState}
                refreshContent={refreshContent}
                isPreviewMode={isPreviewMode}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default TrackConsumptionSectionList;
