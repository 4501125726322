import React, { createContext, useContext } from 'react';

import { ContentItem } from 'shared-content-item/interfaces';
import { STATUS_NOT_REQUESTED } from 'shared/constants';
import { noop } from 'vendor/lodash';

import { AssignmentsActions, useAssignmentsActions } from './hooks';

const Context = createContext<AssignmentsActions>({
  create: noop,
  createStatus: STATUS_NOT_REQUESTED,
  update: noop,
  updateStatus: STATUS_NOT_REQUESTED,
  updateError: '',
  drop: noop,
  dropStatus: STATUS_NOT_REQUESTED,
  dropError: '',
  exempt: noop,
  exemptStatus: STATUS_NOT_REQUESTED,
  undoExemption: noop,
  undoExemptionStatus: STATUS_NOT_REQUESTED,
  complete: noop,
  completeStatus: STATUS_NOT_REQUESTED,
  undoCompletion: noop,
  undoCompletionStatus: STATUS_NOT_REQUESTED,
  retrieve: noop,
  retrieveStatus: STATUS_NOT_REQUESTED,
  rate: noop,
  rateStatus: STATUS_NOT_REQUESTED,
  acknowledgeCompletion: noop,
  acknowledgeCompletionStatus: STATUS_NOT_REQUESTED,
  isLoading: false,
});

export const useAssignmentsActionsContext = () => useContext(Context);

interface AssignmentProviderProps {
  children: React.ReactNode;
  content: ContentItem;
  assignmentId?: number;
  trackAssignmentId?: number;
}

export const AssignmentsActionsProvider = ({
  children,
  content,
  assignmentId,
  trackAssignmentId,
}: AssignmentProviderProps) => {
  const { retrieve: trackRetrieve } = useAssignmentsActions(undefined, trackAssignmentId);
  const {
    create,
    createStatus,
    update,
    updateStatus,
    updateError,
    drop,
    dropStatus,
    dropError,
    exempt,
    exemptStatus,
    undoExemption,
    undoExemptionStatus,
    complete,
    completeStatus,
    undoCompletion,
    undoCompletionStatus,
    retrieve,
    retrieveStatus,
    rate,
    rateStatus,
    acknowledgeCompletion,
    acknowledgeCompletionStatus,
    isLoading,
  } = useAssignmentsActions(content, assignmentId, () =>
    trackAssignmentId ? trackRetrieve() : null
  );
  return (
    <Context.Provider
      value={{
        create,
        createStatus,
        update,
        updateStatus,
        updateError,
        drop,
        dropStatus,
        dropError,
        exempt,
        exemptStatus,
        undoExemption,
        undoExemptionStatus,
        complete,
        completeStatus,
        undoCompletion,
        undoCompletionStatus,
        retrieve,
        retrieveStatus,
        rate,
        rateStatus,
        acknowledgeCompletion,
        acknowledgeCompletionStatus,
        isLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
