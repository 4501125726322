import { ListAssignmentComponentProps } from 'assignments/interfaces';
import LiteHeader from 'content-items/components/LiteHeader';
import { RQLFiltersProvider } from 'rosters/RQLFiltersContext';
import colors from 'services/colors';
import { permissionDeniedRedirectAlert } from 'services/permission-denied-redirect-alert';
import { ContentItem } from 'shared-content-item/interfaces';
import PageTitle from 'shared/components/PageTitle';
import { CONTENT_ITEM_PERMISSIONS } from 'shared/permissions';
import { includes, get } from 'vendor/lodash';
import { Box } from 'vendor/mui';

interface ManageAssignmentsProps {
  contentItem: ContentItem;
  titleLabel?: string;
  ListComponent: React.ComponentType<ListAssignmentComponentProps>;
}

/* This component can be used to manage assignments and any model that inherits from the assignment model. */
const ManageAssignments = ({
  contentItem,
  titleLabel = 'Manage assignments',
  ListComponent,
}: ManageAssignmentsProps) => {
  if (!includes(get(contentItem, 'permissions'), CONTENT_ITEM_PERMISSIONS.manage)) {
    permissionDeniedRedirectAlert();
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(to bottom, ${colors.emphasis600} 0% 360px, transparent 360px 100%)`,
        pb: 2,
      }}
    >
      <PageTitle title={`${contentItem.name} - ${titleLabel}`} />
      <LiteHeader content={contentItem} />
      <RQLFiltersProvider>
        <ListComponent contentItem={contentItem} />
      </RQLFiltersProvider>
    </Box>
  );
};

export default ManageAssignments;
