import Exempted from 'assignments/components/Exempted/Exempted';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { AssignmentsActionsProvider } from 'assignments/Context';
import { CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING } from 'catalog/constants';
import DefaultPrimaryActionButton from 'shared-content-item/components/PrimaryActionButton';
import DefaultSecondaryActionButton from 'shared-content-item/components/SecondaryActionButton';
import { ContentItem } from 'shared-content-item/interfaces';
import CircularProgressWithLabel from 'shared/components/CircularProgressWithLabel/CircularProgressWithLabel';
import { getTrackProgress } from 'tracks/services';
import { get, isNil } from 'vendor/lodash';
import { Stack, Box } from 'vendor/mui';

import { ContentItemHeaderTimeline } from './ContentItemHeaderTimeline';

export interface ContentItemHeaderFooterProps {
  content: ContentItem;
  isArchived?: boolean;
  trackAssignmentId?: number;
  primaryActionButton?: React.ComponentType<{
    content: any;
    contentType?: string;
    refreshContent?: CallableFunction;
  }>;
  secondaryActionButton?: React.ComponentType<{ content: any; contentType?: string }>;
  refreshContent?: CallableFunction;
  isPreviewMode?: boolean;
}

// The use of the default value in the component signature caused the children to re-render
// and lose the reference, resulting in modals not opening. E.g.:
// primaryActionButton: PrimaryActionButton = (props) => (<DefaultPrimaryActionButton {...props} />)
export const ContentItemHeaderFooter = (props: ContentItemHeaderFooterProps) => {
  const {
    content,
    primaryActionButton: PrimaryActionButton,
    secondaryActionButton: SecondaryActionButton,
    isArchived,
    trackAssignmentId,
    refreshContent,
    isPreviewMode = false,
  } = props;

  const learningType = CONTENT_TYPE_TO_LEARNING_TYPE_MAPPING[content.content_type];
  const progress = getTrackProgress(content);

  const PrimaryActionButtonComponent = PrimaryActionButton || DefaultPrimaryActionButton;
  const SecondaryActionButtonComponent = SecondaryActionButton || DefaultSecondaryActionButton;

  // CA 2.0 uses 'user_assignment' rather than 'assignment'
  const assignment = content?.user_assignment || content?.assignment;

  return (
    <Stack
      direction="row"
      flexGrow={1}
      height="52px"
      justifyContent="space-between"
      paddingX="20px"
      paddingY={1}
    >
      <Box display="flex" gap={1} alignItems="center">
        {!isNil(assignment) && <ContentItemHeaderTimeline contentItem={content} />}
        {!isNil(assignment) && get(assignment, 'state') === ASSIGNMENT_STATES.exempted && (
          <Exempted assignment={assignment} />
        )}
      </Box>
      <Stack direction="row" gap="12px" alignItems="center">
        {get(assignment, 'state') !== ASSIGNMENT_STATES.completed && !!progress && (
          <CircularProgressWithLabel variant="determinate" size={30} value={progress} title="15" />
        )}
        <AssignmentsActionsProvider
          content={content}
          assignmentId={assignment?.id}
          trackAssignmentId={trackAssignmentId}
        >
          {!isArchived && SecondaryActionButtonComponent && (
            <SecondaryActionButtonComponent content={content} contentType={learningType} />
          )}
          {!isArchived && PrimaryActionButtonComponent && !isPreviewMode && (
            <PrimaryActionButtonComponent
              content={content}
              contentType={learningType}
              refreshContent={refreshContent}
            />
          )}
        </AssignmentsActionsProvider>
      </Stack>
    </Stack>
  );
};
