import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const RemoveDjangoApp = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      /* eslint-disable babel/no-unused-expressions */
      document.getElementById('plusplus-django-template')?.remove();
      document.getElementById('react-app')?.classList.add('hybrid-page-class');
      /* eslint-enable babel/no-unused-expressions */
    });

    return () => unlisten();
  }, [history]);

  return null;
};

export default RemoveDjangoApp;
