import * as React from 'react';

import { noop } from 'vendor/lodash';

export type TrackContentNavigationCtxType = {
  page: number;
  totalPages: number;
  isDisplayingBottomNavigation: boolean;
  setDisplayBottomNavigation: (isDisplaying: boolean) => void;
  advance: () => void;
  goBack: () => void;
};

const defaultTrackContentNavigationCtx: TrackContentNavigationCtxType = {
  page: 1,
  totalPages: 1,
  isDisplayingBottomNavigation: true,
  setDisplayBottomNavigation: noop,
  advance: noop,
  goBack: noop,
};

const TrackContentNavigationCtx = React.createContext<TrackContentNavigationCtxType>(
  defaultTrackContentNavigationCtx
);

type TrackContentNavigationProviderProps = {
  children: React.ReactNode;
} & TrackContentNavigationCtxType;

export function TrackContentNavigationProvider(props: TrackContentNavigationProviderProps) {
  const { children, ...restCtxValues } = props;

  return (
    <TrackContentNavigationCtx.Provider value={restCtxValues}>
      {children}
    </TrackContentNavigationCtx.Provider>
  );
}

export function useTrackContentNavigation() {
  const ctx = React.useContext(TrackContentNavigationCtx);

  if (!ctx) {
    throw new Error(
      'useTrackContentNavigation must be used within a TrackContentNavigationProvider'
    );
  }

  return ctx;
}
