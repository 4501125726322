import colors from 'services/colors';
import CardCarousel from 'shared-cards/components/CardCarousel';
import { useComposableTrackContext } from 'tracks/context-providers/ComposableTrackContext';
import { Track } from 'tracks/interfaces';
import { getTrackSectionsAndItemsCountText } from 'tracks/services';
import { isNil } from 'vendor/lodash';
import { Box, Typography } from 'vendor/mui';

import { SectionCard } from './SectionCard';

const BULLET_POINT = ' • ';

interface SectionTrackItemSummaryProps {
  track: Track;
}

export const SectionTrackItemSummary = ({ track }: SectionTrackItemSummaryProps) => {
  const { filterTrackSections, getTrackItemIndex } = useComposableTrackContext();

  const subTitle = getTrackSectionsAndItemsCountText(
    track.sections,
    track.track_items,
    BULLET_POINT
  );

  const sections = filterTrackSections(track);

  return (
    <Box marginY={2.5}>
      <Box marginBottom={2}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: colors.emphasis700 }}>
          Summary
        </Typography>
        <Typography sx={{ fontSize: 14, color: colors.neutral500 }}>{subTitle}</Typography>
      </Box>
      <CardCarousel
        cardWidth={315}
        list={sections}
        startIndex={0}
        renderItem={(section, cardsPerPage, cardsCount) => (
          <SectionCard
            key={section.id}
            section={section}
            cardsPerPage={cardsPerPage}
            cardsCount={cardsCount}
            getTrackItemIndex={getTrackItemIndex}
            userIsAssignedToTrack={!isNil(track?.assignment)}
          />
        )}
        renderMoreComponent={() => <span />}
        showArrowsIfRenderedAll={false}
      />
    </Box>
  );
};
