import { LEARNING_TYPES } from 'catalog/constants';
import { useContentFeedback, useContentPeople } from 'catalog/hooks';
import { useLatestContentItemAssignment } from 'common/hooks/useLatestContentItemAssignment';
import ContentItemContainer from 'content-items/components/ContentItemContainer';
import BasePeopleContainer from 'shared-cards/components/BasePeopleContainer';
import BreadCrumbs from 'shared-content-item/components/BreadCrumbs';
import ContentItemAdditional from 'shared-content-item/components/ContentItemAdditional';
import ContentItemBackgroundPanel from 'shared-content-item/components/ContentItemBackgroundPanel';
import ContentItemBody from 'shared-content-item/components/ContentItemBody';
import ContentItemHeader from 'shared-content-item/components/ContentItemHeader';
import ResourceContainer from 'shared-content-item/components/ResourceContainer';
import { ContentItem } from 'shared-content-item/interfaces';
import { PageContainer, PageBody } from 'shared/components/DefaultPage';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import Text from 'shared/components/Text';
import { STATUS_DONE } from 'shared/constants';
import { useResource, useIsPreviewQueryParam } from 'shared/hooks';
import BaseFeedbackContainer, {
  useBaseFeedbackContainerMode,
} from 'stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { Track } from 'tracks/interfaces';
import { get } from 'vendor/lodash';

import PrimaryActionButton from '../PrimaryActionButton';
import VideoContent from '../VideoContent';
import VideoContextMenu from '../VideoContextMenu';

interface VideoDetailPageProps {
  content: ContentItem;
  track?: Track;
  isRenderedWithinTrack?: boolean;
  isRequired?: boolean;
}

const VideoDetailPage = ({
  content: baseContent,
  track,
  isRenderedWithinTrack,
  isRequired = true,
}: VideoDetailPageProps) => {
  // This is temporary until we completely remove the redux sagas usage.
  // This is necessary because it is possible to have multiple assignments in the same state because of the save/drop behavior.
  const currentAssigment = useLatestContentItemAssignment(baseContent.id);
  const content = {
    ...baseContent,
    user_assignment:
      currentAssigment || get(baseContent, 'user_assignment', get(baseContent, 'assignment')),
  };

  const { showResource } = useResource(content, LEARNING_TYPES.videos);
  const { showPeople, organizers, maintainers, engagedPeople } = useContentPeople(content);
  const { engagedPeopleCount } = useContentPeople(content, ['active', 'terminated']);
  const { showFeedback, feedback, feedbackStatus } = useContentFeedback(
    content,
    LEARNING_TYPES.videos
  );

  const feedbackContainerMode = useBaseFeedbackContainerMode({
    showFeedback,
    showOthers: [showResource, showPeople],
  });

  const isLoadingFeedback = feedbackStatus !== STATUS_DONE;

  const { is_inline: isInline } = content;
  const clickableName = isInline ? false : isRenderedWithinTrack;
  const isPreviewMode = useIsPreviewQueryParam();

  return (
    <ContentItemContainer content={content} isRenderedWithinTrack={isRenderedWithinTrack}>
      {!isRenderedWithinTrack && <PageTitle title={content.name} />}
      <ContentItemBackgroundPanel height="120px" $isTrackItem={isRenderedWithinTrack} />
      {!isRenderedWithinTrack && <BreadCrumbs content={content} track={track} />}
      <PageContainer columnsWidth="1fr">
        <ContentItemHeader
          content={content}
          maintainers={maintainers}
          contextMenu={
            <VideoContextMenu
              content={content}
              contextMenuExtraProps={{ isTrackItem: isRenderedWithinTrack }}
            />
          }
          primaryActionButton={PrimaryActionButton}
          clickableName={clickableName}
          isRequired={isRequired}
          isPreviewMode={isPreviewMode}
        />
        <PageBody>
          <ContentItemBody>
            <VideoContent content={content} />
            <ContentItemAdditional>
              {showResource && <ResourceContainer content={content} />}
              {showPeople && (
                <BasePeopleContainer
                  contentType={LEARNING_TYPES.videos}
                  organizers={organizers}
                  maintainers={maintainers}
                  engagedPeople={engagedPeople}
                  engagedPeopleCount={engagedPeopleCount}
                  engagedPeopleHeading={
                    <Text>
                      {engagedPeopleCount} {engagedPeopleCount > 1 ? 'people' : 'person'} engaged
                    </Text>
                  }
                />
              )}
              {showFeedback && (
                <BaseFeedbackContainer
                  content={content}
                  feedbackList={feedback}
                  mode={feedbackContainerMode}
                  isLoading={isLoadingFeedback}
                />
              )}
            </ContentItemAdditional>
          </ContentItemBody>
        </PageBody>
      </PageContainer>
    </ContentItemContainer>
  );
};

export default VideoDetailPage;
