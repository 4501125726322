import { CONTENT_TYPES } from 'catalog/constants';
import { displayDatetime, formatDuration } from 'services/datetime';
import { ContentItem } from 'shared-content-item/interfaces';
import { FullTrackSection, TrackItem } from 'tracks/interfaces';
import { filter, isEmpty, isNil, map, size, get } from 'vendor/lodash';

export function getTrackProgress({ user_assignment }: ContentItem): number | null {
  if (isNil(user_assignment)) return null;

  const progress = user_assignment?.progress?.progress;
  return isNil(progress) ? 0 : progress * 100;
}

export function getTrackSectionProgress(section: FullTrackSection) {
  const { items } = section;
  if (isEmpty(items)) return null;

  const itemsWithAssignments = filter(items, (item) => !isNil(item?.content_item?.assignment));
  const itemsWithCompletedAssignments = filter(
    itemsWithAssignments,
    (item) => !isNil(item?.content_item?.assignment?.completed_datetime)
  );

  return (size(itemsWithCompletedAssignments) / size(itemsWithAssignments)) * 100;
}

export function getTrackItemsWithHumanizedDate(trackItems: TrackItem[], currentUser): TrackItem[] {
  const { timezone: currentUserTimezone } = currentUser;

  const transformedTrackItems: TrackItem[] = map(trackItems, (item) => {
    const { content_item: contentItem } = item;

    if (contentItem.content_type !== CONTENT_TYPES.event) {
      return item;
    }

    const humanizedDate = `${displayDatetime(
      contentItem.utc_datetime,
      contentItem.timezone,
      currentUserTimezone,
      contentItem.is_online
    )} - ${formatDuration(contentItem.duration)}`;

    return {
      ...item,
      content_item: { ...contentItem, humanizedDate },
    };
  });

  return transformedTrackItems;
}

export function getTrackSectionsAndItemsCountText(
  sections: FullTrackSection[] = [],
  items: TrackItem[] = [],
  separator = ', '
): string {
  const sectionsCount = size(sections);
  const sectionsCountText = sectionsCount
    ? sectionsCount === 1
      ? `${sectionsCount} section`
      : `${sectionsCount} sections`
    : '';
  const itemsCount = size(items);
  const itemsCountText = itemsCount === 1 ? `${itemsCount} item` : `${itemsCount} items`;

  if (isEmpty(sections)) return itemsCount === 1 ? `${itemsCount} item` : `${itemsCount} items`;

  return `${sectionsCountText}${separator}${itemsCountText}`;
}

interface TrackItemBorderStyle {
  borderStyle?: string;
  borderWidth?: string;
  padding?: number | string;
}

export const getTrackItemBorderStyle = (item: TrackItem): TrackItemBorderStyle => {
  const isRequired = get(item, 'is_required', true);
  return isRequired
    ? { borderStyle: 'solid', padding: '1px', borderWidth: '1px' }
    : {
        borderStyle: 'dashed',
        borderWidth: '2px',
        padding: '0px',
      };
};
