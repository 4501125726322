import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useAssignmentsActionsContext } from 'assignments/Context';
import { LEARNING_TYPES } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import RelaunchCourseModal from 'course/components/RelaunchCourseModal';
import { COURSE_STATUS_DRAFT } from 'course/constants';
import { Course } from 'course/interfaces';
import { launchCourse } from 'course/services';
import { METRICS_ACTIVITIES, useMetrics } from 'services/metrics';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import ButtonLink from 'shared/components/ButtonLink';
import RateButton from 'shared/components/RateButton';
import Rating from 'shared/components/Rating';
import SplitButton from 'shared/components/SplitButton';
import { useToggles } from 'shared/hooks';
import ContentRating from 'stand-alone-shared/components/ContentRating';
import { get, isEmpty, isNil } from 'vendor/lodash';
import { OpenInNewOutlinedIcon } from 'vendor/mui-icons';

import OldPrimaryActionButton from '../OldPrimaryActionButton';

const Container = styled.div`
  min-height: 40px; // Large Button height
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
`;

const RatingContainer = styled.div`
  flex: 1 0 auto;
  margin-right: 12px;

  font-size: 20px; // Star size

  display: flex;
  justify-content: flex-end;
`;

const DraftLink = styled(Link)`
  width: 100%;
`;

interface PrimaryActionButtonProps {
  content: Course;
  isRenderedWithinTrack?: boolean;
}

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  const { content, isRenderedWithinTrack = false } = props;

  const { dev_toggle_content_assignments_2_0: devToggleContentAssignment2 } = useToggles();
  const [isCourseLaunchLoading, setIsCourseLaunchLoading] = useState(false);
  const [showRelaunchCourseModal, setShowRelaunchCourseModal] = useState(false);
  const courseLabel = useLearningTypeLabels()[LEARNING_TYPES.courses];
  const [isRatingContent, setRatingContent] = useState(false);
  const { trackActivity } = useMetrics();
  const isUnpublished = content.status === COURSE_STATUS_DRAFT;
  const { assignment } = content;
  const assignmentState = get(assignment, 'state');
  const currentRating = get(assignment, 'feedback_rating') || get(assignment, 'rating.rating', 0);
  const { rate, isLoading } = useAssignmentsActionsContext();

  if (!devToggleContentAssignment2) {
    return (
      <OldPrimaryActionButton content={content} isRenderedWithinTrack={isRenderedWithinTrack} />
    );
  }

  const handlePreviewCourseClick = async () => {
    await launchCourse({
      courseId: content.public_id,
      showPreview: true,
      isLaunchedInsideTrack: isRenderedWithinTrack,
    });
  };

  const handleCourseLaunch = async () => {
    trackActivity(METRICS_ACTIVITIES.COURSE_LAUNCH, {
      courseId: content.id,
      firstLaunch: isNil(content?.assignment?.latest_sync),
    });

    setIsCourseLaunchLoading(true);

    await launchCourse({
      courseId: content.public_id,
      isLaunchedInsideTrack: isRenderedWithinTrack,
    });

    setIsCourseLaunchLoading(false);
  };

  if (isUnpublished) {
    return (
      <Container>
        <DraftLink to={mapRoute('courseEdit', { public_id_and_slug: content.public_id_and_slug })}>
          <Button>Edit and Publish</Button>
        </DraftLink>
      </Container>
    );
  }

  if (assignmentState === ASSIGNMENT_STATES.completed) {
    return (
      <>
        <Container>
          {currentRating > 0 && (
            <>
              <RatingContainer>
                <Rating value={currentRating} readOnly />
              </RatingContainer>
              <ButtonLink
                onClick={() => setRatingContent(true)}
                variant="primary"
                disabled={isLoading}
              >
                Edit
              </ButtonLink>
            </>
          )}
          {currentRating <= 0 && (
            <RateButton
              size="medium"
              onClick={() => setRatingContent(true)}
              fullWidth
              disabled={isLoading}
            >
              Rate
            </RateButton>
          )}
        </Container>
        <Container>
          <SplitButton
            variant="contained"
            label="Relaunch"
            onClick={() => setShowRelaunchCourseModal(true)}
            disabled={isLoading}
            secondaryActionOptionList={[
              {
                label: 'Review',
                onClick: handlePreviewCourseClick,
              },
            ]}
          />
        </Container>
        {isRatingContent && (
          <ContentRating
            content={content}
            contentType={LEARNING_TYPES.courses}
            onSubmitFunc={rate}
            handleClose={() => setRatingContent(false)}
          />
        )}
        {showRelaunchCourseModal && (
          <RelaunchCourseModal
            title={content.name}
            handleRelaunch={handleCourseLaunch}
            isCourseLaunchLoading={isCourseLaunchLoading}
            handleClose={() => setShowRelaunchCourseModal(false)}
          />
        )}
      </>
    );
  }

  const isLaunchButtonDisabled =
    isCourseLaunchLoading || isLoading || isEmpty(content?.scorm_cloud_id);

  return (
    <Container>
      <Button
        variant="contained"
        disabled={isLaunchButtonDisabled}
        startIcon={<OpenInNewOutlinedIcon />}
        fullWidth
        onClick={handleCourseLaunch}
      >
        {isCourseLaunchLoading
          ? 'Launching...'
          : assignmentState === ASSIGNMENT_STATES.in_progress
          ? `Continue ${courseLabel}`
          : `Launch ${courseLabel}`}
      </Button>
    </Container>
  );
};

export default PrimaryActionButton;
