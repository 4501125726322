import { Stack, Box } from '@mui/material';

import RQLFilterBar, { FilterDef } from 'app/filters/components/RQLFilterBar/RQLFilterBar';
import { useNewRqlFilters } from 'app/filters/hooks';
import { FiltersDefinitionProps, PillsDefinitionProps } from 'app/filters/types';
import HorizontalCardList from 'app/shared/components/HorizontalCardList';
import FilterPills from 'navigation/components/FilterPills';
import { OptimalContentItemCard, PersonCard } from 'shared/components/Card';
import Loading from 'shared/components/Loading';
import { get, isEmpty } from 'vendor/lodash';
import rql from 'vendor/rql';

import { useChannelHomeDiscoverData } from '../../hooks/useChannelHomeDiscoverData';
import { useChannelRoutes } from '../../hooks/useChannelRoutes';
import { Channel, ChannelHomeTab } from '../../types';

const upcomingEventsListRqlExpression = rql({
  start_time: {
    $gt: '-PT0H',
  },
  $ordering: 'start_time',
  status: 'published',
  tab: 'events',
});

const topContentsListRqlExpression = rql({
  $ordering: 'relevance',
  status: 'published',
  tab: 'content',
});

const topExpertsListRqlExpression = rql({
  $ordering: '-unique_people_impacted_count',
  tab: 'people',
});

type UseChannelHomeFiltersDefinitionOptions = Omit<
  FiltersDefinitionProps,
  'fixedFilters' | 'includeFilterChannels' | 'ordering' | 'setOrdering'
> & {
  currentTab?: ChannelHomeTab;
};

function useFiltersDefinition(options: UseChannelHomeFiltersDefinitionOptions) {
  const { filters, updateFilter } = options;

  const allFilters: FilterDef[] = [
    {
      type: 'search_bar',
      placeholder: 'Search Channel...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      width: '100%',
      gridProps: {
        xs: true,
      },
    },
  ];

  return {
    filters: allFilters,
    moreFilters: [],
  };
}

function usePillsDefinition(options: PillsDefinitionProps) {
  const { filters } = options;

  const searchValue = get(filters, 'q.$eq', '');

  return {
    pills: [
      ...(searchValue
        ? [
            {
              id: 'search',
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),
    ],
  };
}

type ChannelHomeDiscoverProps = {
  channel: Channel;
};

export function ChannelHomeDiscover(props: ChannelHomeDiscoverProps) {
  const { channel } = props;

  const channelSlug = get(channel, 'slug', '');

  const { filters, rqlExpression, updateFilter, resetFilters, removeValue } = useNewRqlFilters();
  const isFiltering = !isEmpty(filters);

  const filtersDef = useFiltersDefinition({ filters, updateFilter });
  const pillsDef = usePillsDefinition({ filters });

  const { isLoadingData, upcomingEvents, newestContents, topExperts } = useChannelHomeDiscoverData(
    channel.id,
    { rqlExpression }
  );
  const eventsCount = get(upcomingEvents, 'count', 0);
  const contentsCount = get(newestContents, 'count', 0);
  const expertsCount = get(topExperts, 'count', 0);
  const hasContent = eventsCount > 0 || contentsCount > 0 || expertsCount > 0;

  const { detail: detailRoute } = useChannelRoutes();
  const detailRouteUrl = detailRoute({ slug: channelSlug });

  const buildViewAllUrl = (filters: string) => {
    const parsedSearchQuery = rqlExpression ? `&${rqlExpression}` : '';
    return `${detailRouteUrl}?${filters}${parsedSearchQuery}`;
  };

  return (
    <Box pt={2}>
      {isFiltering && (
        <Box display="flex" flexDirection="column" gap={2}>
          <RQLFilterBar
            filters={filtersDef.filters}
            moreFilters={filtersDef.moreFilters}
            onClearAll={resetFilters}
          />

          <FilterPills
            pills={pillsDef.pills}
            onRemove={(item) => removeValue(get(item, 'filterName', ''), get(item, 'value', ''))}
          />
        </Box>
      )}

      {isLoadingData && <Loading />}

      {!isLoadingData && !hasContent && <Box>No results found</Box>}

      {!isLoadingData && hasContent && (
        <Stack direction="column" spacing={3} pb={3}>
          {eventsCount > 0 && (
            <HorizontalCardList
              sectionTitle="Upcoming Events"
              items={get(upcomingEvents, 'results', [])}
              renderItem={(item) => {
                return (
                  <OptimalContentItemCard
                    key={`${item.content_type}_${item.id}`}
                    contentItem={item}
                  />
                );
              }}
              totalItemsCount={eventsCount}
              viewAllLabel={`View All ${eventsCount > 1 && eventsCount}`}
              viewAllUrl={buildViewAllUrl(upcomingEventsListRqlExpression)}
            />
          )}

          {contentsCount > 0 && (
            <HorizontalCardList
              sectionTitle="Newest Content"
              items={get(newestContents, 'results', [])}
              renderItem={(item) => {
                return (
                  <OptimalContentItemCard
                    key={`${item.content_type}_${item.id}`}
                    contentItem={item}
                  />
                );
              }}
              totalItemsCount={contentsCount}
              viewAllLabel={`View All ${contentsCount > 1 && contentsCount}`}
              viewAllUrl={buildViewAllUrl(topContentsListRqlExpression)}
            />
          )}

          {expertsCount > 0 && (
            <HorizontalCardList
              sectionTitle="Top Experts"
              items={get(topExperts, 'results', [])}
              renderItem={(item) => {
                return <PersonCard key={item.id} person={item} />;
              }}
              totalItemsCount={expertsCount}
              viewAllLabel={`View All ${expertsCount > 1 && expertsCount}`}
              viewAllUrl={buildViewAllUrl(topExpertsListRqlExpression)}
            />
          )}
        </Stack>
      )}
    </Box>
  );
}
