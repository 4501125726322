import { Box, Typography, Link, Avatar, LinkProps as MUILinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useChannelRoutes } from 'features/channels/hooks/useChannelRoutes';
import { Channel } from 'features/channels/types';
import colors from 'services/colors';
import { get } from 'vendor/lodash';

import { useContentItemCardCtx } from './ContentItemCardContext';

type LinkProps = MUILinkProps & { component: ElementType; to: string };

const ChannelLink = styled(Link)<LinkProps>`
  color: ${colors.neutral900};
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: ${colors.neutral900};
  }
`;

type ChannelDetailProps = {
  channel: Channel;
};

function ChannelDetail(props: ChannelDetailProps) {
  const { channel } = props;

  const { detail: channelDetailRoute } = useChannelRoutes();
  const channelDetailRouteUrl = channelDetailRoute({
    slug: get(channel, 'slug'),
  });

  return (
    <ChannelLink component={RouterLink} to={channelDetailRouteUrl} underline="hover">
      <Avatar
        src={channel.cover}
        alt={channel.name}
        variant="square"
        sx={{ width: 24, height: 24 }}
      />
      <Typography
        variant="body2"
        component="span"
        sx={{
          fontSize: '0.75rem',
          lineHeight: '1',
        }}
      >
        by
      </Typography>
      <Typography
        variant="body2"
        component="span"
        noWrap
        sx={{
          fontSize: '0.75rem',
          lineHeight: '1.25',
          fontWeight: 500,
        }}
      >
        {channel.name}
      </Typography>
    </ChannelLink>
  );
}

const HeaderTitleLink = styled(Link)<LinkProps>`
  color: ${colors.neutral900};
  font-size: 1.125rem;
  line-height: 1.275;
  word-break: break-word;

  &:hover {
    color: ${colors.neutral900};
  }
`;

export function ContentItemCardHeader() {
  const { contentItem, route } = useContentItemCardCtx();
  const channel = get(contentItem, 'channel', null);

  return (
    <Box display="flex" flexDirection="column" gap="4px">
      <HeaderTitleLink component={RouterLink} to={route} underline="hover" variant="h6">
        {contentItem.name}
      </HeaderTitleLink>

      {channel && <ChannelDetail channel={channel} />}
    </Box>
  );
}
