import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { ASSIGNMENT_STATES } from 'assignments/constants';
import { CONTENT_TYPES } from 'catalog/constants';
import { PersistentlyDismissableInfoBox } from 'shared/components/PersistentlyDismissableInfoBox/index.tsx';
import { useCurrentUser, useLabels } from 'shared/hooks';
import { get, toLower, isNil } from 'vendor/lodash';

const TrackItemInfoBox = ({ item, track, completed }) => {
  const { label_track: labelTrack } = useLabels();
  const currentUser = useCurrentUser();

  const informationMapping = {
    [CONTENT_TYPES.article]: `Read the article and mark it as Complete to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.video]: `Watch the video and mark it as Complete to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.course]: `Launch and finish the course to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.eventtype]: `Enroll and check in to an event from this event type to conclude this step of the ${toLower(
      labelTrack
    )}.`,
    [CONTENT_TYPES.event]: `Attend this event to complete the ${toLower(labelTrack)} step.`,
  };

  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  const isTrackCompleted = get(track, 'user_assignment.state') === ASSIGNMENT_STATES.completed;
  const contentItem = get(item, 'content_item');
  const message = get(informationMapping, contentItem.content_type, null);

  if (completed || !track.user_assignment || isTrackCompleted || isNil(message)) return null;

  return (
    <PersistentlyDismissableInfoBox
      localStorageKey={`closedTrackInfoTip-${currentUser.id}-${track.id}-${contentItem.content_type}`}
      margin="0 0 20px 0"
      content={message}
      actionText="Dismiss"
      type="success"
    />
  );
};

TrackItemInfoBox.propTypes = {
  item: PropTypes.object,
  track: PropTypes.object,
  completed: PropTypes.bool,
};

export default TrackItemInfoBox;
