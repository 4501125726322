import * as React from 'react';

import { AssessmentQuestionSkipConfirmationModalContext } from 'assessments/components/AssessmentQuestionSkipConfirmationModal';
import { ASSESSMENT_FEEDBACK_POLICY } from 'assessments/constants';
import { useCompleteQuestion } from 'assessments/hooks';
import {
  Assessment,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
  MultipleChoiceQuestionResponse,
} from 'assessments/interfaces';
import { ASSIGNMENT_STATES } from 'assignments/constants';
import { useContentItemFetcher } from 'catalog/hooks';
import { getIsLastQuestionInAssessment } from 'features/assessments/utils/helpers';
import { useTrackContentNavigation } from 'features/tracks/contexts/TrackContentNavigationCtx';
import Button from 'shared/components/Button';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { Track } from 'tracks/interfaces';
import { get, isEmpty, map } from 'vendor/lodash';
import { Box, FormControl, List, RadioGroup, Typography } from 'vendor/mui';

import {
  MultipleChoiceOptionFeedbackState,
  MultipleChoiceOptionRadioButton,
} from './MultipleChoiceOptionRadioButton';
import { QuestionDetail } from './QuestionDetail';

interface MultipleChoiceQuestionDetailProps {
  question: MultipleChoiceQuestion;
  assessment: Assessment;
  track?: Track;
}

export const MultipleChoiceQuestionDetail = ({
  question,
  assessment,
  track,
}: MultipleChoiceQuestionDetailProps) => {
  const { user_assignment: questionUserAssignment } = question;
  const { user_assignment: assessmentUserAssignment } = assessment;

  const [initialOption, setInitialOption] = React.useState(get(questionUserAssignment, 'option'));
  const [selectedOption, setSelectedOption] = React.useState(
    initialOption ? initialOption.id : null
  );

  const { setDirtyQuestion } = React.useContext(AssessmentQuestionSkipConfirmationModalContext);
  const { goBack, advance } = useTrackContentNavigation();

  React.useEffect(() => {
    setDirtyQuestion((initialOption?.id ?? null) !== selectedOption);
  }, [initialOption, selectedOption, setDirtyQuestion]);

  const shouldDisplayFeedback =
    assessmentUserAssignment?.state === ASSIGNMENT_STATES.completed ||
    assessment.feedback_policy === ASSESSMENT_FEEDBACK_POLICY.after_question;

  const isCompleted = !isEmpty(initialOption);

  const isLastQuestion = getIsLastQuestionInAssessment(question.id, assessment);

  const { fetchItem: fetchAssessment } = useContentItemFetcher(
    assessment.public_id,
    assessment.content_type
  );
  const { fetchItem: fetchTrack } = useContentItemFetcher(
    track?.public_id || '',
    track?.content_type || ''
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(parseInt(e.target.value));
  };

  const handleRefetchData = () => {
    /* Fetch assessment to:
     - Update the track_items (questions) in redux state
     - Check if the assessment was completed after the question */
    fetchAssessment();
  };

  const handleRefetchTrackData = () => {
    // Fetch track when the assessment is within a track
    if (track) fetchTrack();
  };

  const handleSubmitSuccessful = (questionResponse: MultipleChoiceQuestionResponse) => {
    setInitialOption(questionResponse.option);
    handleRefetchData();
  };

  const { completeQuestion, isLoading } = useCompleteQuestion({
    contentType: question.content_type,
    onSuccess: handleSubmitSuccessful,
  });

  const handleSubmit = () => {
    completeQuestion(questionUserAssignment?.id, {
      content_type: question.content_type,
      option_id: selectedOption,
    });
  };

  const handleFinish = () => {
    advance();
    handleRefetchData();
    handleRefetchTrackData();
  };

  const handleGetOptionFeedbackState = (
    option: MultipleChoiceOption
  ): MultipleChoiceOptionFeedbackState => {
    if (!isCompleted) {
      return 'none';
    }

    const isUserSubmittedOption = initialOption?.id === option.id;

    if (isUserSubmittedOption) {
      if (!shouldDisplayFeedback) {
        return 'indeterminate';
      }

      return initialOption?.is_correct ? 'correct' : 'incorrect';
    }

    return option.is_correct && shouldDisplayFeedback ? 'correct' : 'none';
  };

  const showFeedbackText = isCompleted && shouldDisplayFeedback && initialOption?.feedback_text;
  const disableSubmitButton = isEmpty(questionUserAssignment) || !selectedOption || isLoading;

  return (
    <QuestionDetail question={question}>
      <FormControl>
        <RadioGroup
          name={question.public_id_and_slug}
          value={selectedOption}
          onChange={handleChange}
        >
          <List>
            {map(question.option_set, (option, index) => {
              return (
                <MultipleChoiceOptionRadioButton
                  key={`option-${option.id}`}
                  option={option}
                  index={index}
                  disabled={isCompleted}
                  feedbackState={handleGetOptionFeedbackState}
                />
              );
            })}
          </List>
        </RadioGroup>
      </FormControl>
      {showFeedbackText && (
        <Box padding={2.5}>
          <Typography variant="caption">Feedback</Typography>
          <ContentDescription description={initialOption.feedback_text} noPadding />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between">
        <Button onClick={goBack}>Previous</Button>

        {!isCompleted ? (
          <Button
            color="primary"
            variant="contained"
            disabled={disableSubmitButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : !isLastQuestion ? (
          <Button color="primary" variant="contained" onClick={advance}>
            Next
          </Button>
        ) : (
          <Button color="primary" variant="contained" onClick={handleFinish}>
            Finish
          </Button>
        )}
      </Box>
    </QuestionDetail>
  );
};
